import React from 'react';
import { LoadingPage } from 'components/core';
import SettingsContext from './SettingsContext';
import settingsApi from './settingsApi';

const SettingsStore = (props: any) => {
	const [settings, setSettings] = React.useState<WebSettings>({});
	const [isInitializing, setIsInitializing] = React.useState(true);

	React.useEffect(() => {
		const getData = async () => {
			// get settings
			const settings = await settingsApi.getSettings();
			setSettings(settings);
			setIsInitializing(false);
		};

		getData();
	}, []);

	const initialState = {
		settings,
		isInitializing
	};

	return (
		<SettingsContext.Provider value={initialState}>
			{initialState.isInitializing
				? (<LoadingPage />)
				: props.children
			}
		</SettingsContext.Provider>
	);
};
export default SettingsStore;
