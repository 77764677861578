import React from 'react';
import { Button, DataTable, LoadingPage, Page } from 'components/core';
import { FormProvider, useForm } from 'react-hook-form';
import TitleBar from 'screens/Components/TitleBar';
import { usePTD } from 'context/PTDContext';
import { Link } from 'react-router-dom';
// import { IconAdd } from 'assets/icons';
//simport AddPTDModal from 'screens/PremiumTradeDirectory/components/modals/AddPTDModal';

function PremiumTradeDirectoriesOverview() {
	const pageTitle = 'Vendors Lists Overview';
	//const [showAddPTDModal, setshowAddPTDModal] = React.useState<boolean>(false);

	const { getAllPTDs, isInitializing, getCompanyProfile, getPTDMyLists, getPTDComanagedLists } = usePTD();
	const companyProfile: OrganizationDTO | null = getCompanyProfile();
	const PTDs = getAllPTDs();
	const PTDMyLists: PremiumTradeDirectory[] = getPTDMyLists();
	const PTDComanagedLists: PremiumTradeDirectory[] = getPTDComanagedLists();

	const columns = (idPTD: string, listName: string) => [
		{
			Header: 'Name',
			accessor: 'premiumTradeDirectoryName',
			filterable: true,
		},
		{
			Header: 'Total Products',
			accessor: 'total_products',
			filterable: true,
			className: 'text-center',
		},
		{
			Header: 'Active Vendors',
			accessor: 'active_vendors',
			filterable: true,
			className: 'text-center',
		},
		{
			Header: '',
			accessor: 'id',
			filterable: false,
			Cell: (rowValue: any) => {
				return ( // turn into a component
					<Button
						className="btn-primary btn-sm fs-12"
						to={`/premium-trade-directories/${idPTD}/${listName}/${rowValue.value}`}
					>
					View Details
					</Button>
				);}
		}
	];

	const methods = useForm<any>({ reValidateMode: 'onChange' });

	// function openAddPTDModal() {
	// 	setshowAddPTDModal(true);
	// }

	// function closeAddPTDModal() {
	// 	setshowAddPTDModal(false);
	// }

	// function addNewPTD(fields: PTD) {
	// 	fields.id = PTDs ? PTDs.length.toString() : '0';
	// 	createNewPTD(fields);
	// }

	if (isInitializing === true) {
		return <LoadingPage />;
	}

	return (
		<FormProvider {...methods}>
			<Page title={pageTitle}>
				<div className="d-flex">
					<div className="d-flex flex-column w-100">
						<TitleBar
							pageTitle={pageTitle}
						/>
					</div>
				</div>
				<div className="container-fluid mb-2 px-5 py-3">
					{/* <Button
						onClick={openAddPTDModal}
						loading={false}
						className="btn-primary ms-auto mb-3"
					>
						<IconAdd /> Add PTD
					</Button> */}

					{PTDs.length > 0 && PTDs.map((directory, key) => (
						<div className="card shadow p-3 my-3 rounded" key={`PTD-${key}`}>
							<Link to={`/premium-trade-directories/${directory.id}`}>
								<div className="card-header d-flex align-items-center">
									<h4 className="mb-0">{directory.name}</h4>
								</div>
							</Link>
							<hr />
							<div className="card-body">
								<div className="container-fluid overflow-hidden">
									<div className="row my-4">
										<div className="col-12">
											<div className="bg-light border-bottom border-primary-200 px-3 py-2 d-flex align-items-center rounded-top custom-shadow">
												<h4 className="text-dark mb-0">{companyProfile?.organizationName} Lists</h4>
											</div>
											<DataTable
												data={PTDMyLists.filter(l => l.containsVendorType === directory.vendorType)}
												columns={columns(directory.id, 'myList')}
												filterable={true}
												sortable={true}
												resizable={true}
												noDataText="No data found."
											/>
										</div>
									</div>
									{
										PTDComanagedLists && <div className="row my-5">
											<div className="col-12">
												<div className="bg-light border-bottom border-primary-200 px-3 py-2 d-flex align-items-center rounded-top custom-shadow">
													<h4 className="text-dark mb-0">Comanaged Lists</h4>
												</div>
												<DataTable
													data={PTDComanagedLists.filter(l => l.containsVendorType === directory.vendorType)}
													columns={columns(directory.id, 'comanagedList')}
													filterable={true}
													sortable={true}
													resizable={true}
													noDataText="No data found."
												/>
											</div>
										</div>
									}
								</div>
							</div>
						</div>
					))}

				</div>

				{/* <AddPTDModal
					isOpen={showAddPTDModal}
					onRequestClose={closeAddPTDModal}
					addNewPTD={addNewPTD}
					organizationType={companyProfile?.organizationType}
				 /> */}

			</Page>
		</FormProvider>
	);
};

export default PremiumTradeDirectoriesOverview;