import React from 'react';
import classNames from 'classnames';

interface IProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
	error?: boolean;
}

export const TextArea = (props: IProps): JSX.Element => {
	const {  className, error, ...otherProps } = props;
	return (
		<textarea {...otherProps} id={props.name} className={classNames('form-control', error && 'is-invalid', className)} />
	);
};