/* eslint-disable max-len */
import React from 'react';
import { ReactComponent as IconArrowLeft } from 'assets/icons/icon-arrow-left.svg';
import LogoutButton from 'components/scopematch/LogoutButton';
import { useNavigate } from 'react-router-dom';

interface IProps {
	pageTitle?: string;
	backButton?: boolean;
	onClick?: () => void;
}

const TitleBar = (props: IProps) => {
	const navigate = useNavigate();

	const goBack = () => {
		if (props.onClick) {
			props.onClick();
		} else {
			navigate(-1);
		}
	};
	return (
		<div className="container-fluid py-3 shadow px-5 mb-3 titlebar">
			<div className="d-flex">
				<h2 className="mb-0 d-flex align-items-center">
					{props.backButton === true && (
						<button onClick={goBack} className="btn btn-icon d-flex p-0 me-2">
							<IconArrowLeft />
						</button>
					)}
					{props.pageTitle}</h2>
				<LogoutButton className="ms-auto" />
			</div>
		</div>

	);
};

export default TitleBar;