import React from 'react';
import classNames from 'classnames';

interface IProps {
	className?: string;
}

const Pill = (props: React.PropsWithChildren<IProps>) => {
	const { children } = props;

	return (
		<span className={classNames('border border-primary-200 rounded-pill px-2 py-1 align-self-start my-1 mx-1 fs-16 border-2', props.className)}>
			{children}
		</span>
	);
};

export default Pill;