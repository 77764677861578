import React from 'react';
interface IState {
	pickListTypes: PickListType[];
	pickListItems: PickListItem[];
	filterByPickListItem: (picklistType: string) => (filter: any, row: any) => boolean;
	setColumnValue: (pickListItemId: number) => string | undefined;
}
const initialState: IState = {
	pickListTypes: [] as PickListType[],
	pickListItems: [] as PickListItem[],
	filterByPickListItem: () => () => true,
	setColumnValue: () => '',
};

const PickListContext = React.createContext(initialState);
export default PickListContext;