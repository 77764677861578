import React from 'react';
import classNames from 'classnames';
import { ControllerFieldState } from 'react-hook-form';
import { ReactComponent as IconError } from 'assets/icons/icon-danger.svg';

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
	fieldState?: ControllerFieldState;
	errorMessage?: string;
	type?: 'warning' | 'error' | 'info' | 'success' | 'danger';
}

export const ErrorMessage = (props: IProps) => {
	const { fieldState, className, type, errorMessage, ...otherProps } = props;
	if (fieldState?.error?.message === undefined && errorMessage === undefined) {
		return <React.Fragment />;
	}
	return (
		<div className={classNames('alert text-dark d-flex align-items-center mb-4', type === undefined ? 'alert-danger' : `alert-${type}`, className)} {...otherProps}>
			<IconError className="me-2" />{fieldState?.error?.message ?? errorMessage}
		</div>
	);
};
