import React from 'react';
import { Button } from 'components/core';
import { ReactComponent as IconArrowRight } from 'assets/icons/icon-arrow-right.svg';
import { FormContext, LocationsType } from '../FormContext';
import { useFormContext } from 'react-hook-form';

interface IProps {
	beforeSkipping?: () => void;
	beforeContinuing?: () => void;
};

const DropdownInput = (props: IProps) => {
	const FormCxt = React.useContext(FormContext);
	const methods = useFormContext<LocationsType>();
	const organizationType = methods.getValues('organizationType');
	const pageName = location.pathname;
	const pageInfo = FormCxt.CurrentIndex(organizationType, pageName);
	return (
		<React.Fragment>
			{pageInfo?.hasBackButton && <Button onClick={onBackClick} className="btn-outline-primary me-3">
				Back
			</Button>}
			{pageInfo?.hasFinishButton && <Button onClick={methods.handleSubmit(finishForm)} className="btn-primary px-3">
				Finish<IconArrowRight className="ms-2" />
			</Button>}
			{pageInfo?.hasFinishButton || <Button onClick={methods.handleSubmit(submitForm)} className="btn-primary px-3">
				Continue<IconArrowRight className="ms-2" />
			</Button>}
			{pageInfo?.hasSkipButton && <Button onClick={handleSkip} className="btn-outline-primary ms-auto">Skip</Button>}

		</React.Fragment>
	);

	async function submitForm() {
		if (props.beforeContinuing) {
			props.beforeContinuing();
		}
		await FormCxt.NextPage(organizationType, location.pathname);
	}
	async function onBackClick() {
		await FormCxt.PreviousPage(organizationType, location.pathname);
	}

	function handleSkip() {
		if (props.beforeSkipping) {
			props.beforeSkipping();
		}
		FormCxt.NextPage(organizationType, location.pathname);
	};
	async function finishForm(fields: LocationsType) {

		await FormCxt.submitForm(organizationType, fields);
	}
};

export default DropdownInput;