import classNames from 'classnames';
import React from 'react';

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
}

const OnboardingContainer = (props: IProps) => {
	const { children, className, ...otherProps } = props;
	return (
		<div className={classNames('container-fluid onboarding-container', className)} {...otherProps}>
			<div className="row">
				<div className="offset-1 col-lg-6">
					{children}
				</div>
			</div>
		</div>
	);
};
export default OnboardingContainer;
