import React from 'react';
import { Page, PageHero } from 'components/core';

const FourOhFourPage =  () => {
	return (
		<Page title="404">
			<PageHero title="404" />
			<div className="container-fluid">
				<h2>Page not found</h2>
				<p className="mt-4">Sorry, I'm not sure how you got here but this place doesn't exist.</p>
			</div>
		</Page>
	);
};

export default FourOhFourPage;
