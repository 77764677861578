import React from 'react';
import classNames from 'classnames';
import SquarePill from '../pills/SquarePill';

interface IProps {
	group: ProductMatchTypeGroupDTO;
	resultsPage: boolean | undefined;
	showHighlight: boolean | undefined;
}

const AccordionBody = ({
	group,
	resultsPage,
	showHighlight,
}: IProps) => {

	return (
		<div className="accordion-body px-0 mb-2">
			{group.productGroups &&
				group.productGroups.map((group, idx) => (
					<div
						className="mb-n2 pb-4"
						key={`grp-${idx}-${group.productTypePickListItemId}`}
					>
						<div
							className={classNames(
								group.productSpecCodes?.some((products) => products.matched) === true &&
								!resultsPage &&
								'bg-success-100 rounded p-1'
							)}
						>
							{group.productSpecCodes &&
								group.productSpecCodes.map((products, i) => (
									products.matched === true && showHighlight === true ? (
										<span className="me-1" key={`speccode-${products.productSpecValue}-${idx}-${group.productTypePickListItemId}-${i}`}>
											<SquarePill>
												{products.productSpecValue === 'Any'
													? products.productSpecDescription + ' (' + products.productSpecValue + ')'
													: products.productSpecValue}
												{group.productSpecCodes &&
													i !== group.productSpecCodes.length - 1 && ', '}
											</SquarePill>
										</span>
									) : (
										<span className="text-gray-600 d-inline-block me-1" key={`speccode-${products.productSpecValue}-${idx}-${group.productTypePickListItemId}-${i}`}>
											{products.productSpecValue === 'Any'
												? products.productSpecDescription + ' (' + products.productSpecValue + ')'
												: products.productSpecValue}
											{group.productSpecCodes &&
												i !== group.productSpecCodes.length - 1 && ', '}
										</span>
									)
								))}
						</div>
					</div>
				))}
		</div>
	);
};

export default AccordionBody;
