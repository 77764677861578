import React, { useCallback } from 'react';
import { ActionIcon, DataTable, Page } from 'components/core';
import { usePTD } from 'context/PTDContext';
import { useParams } from 'react-router-dom';
import { Text } from 'screens/ProductCatalog/components/columns';
import TitleBar from 'screens/Components/TitleBar';
import ViewVendorProfile from 'screens/PremiumTradeDirectory/components/modals/ViewVendorProfile';

function PTDVendorsOverview() {
	const params = useParams();
	const idPTDList = Number(params.idPTDList);
	const listName = params.listName;
	//const id = params.id;

	// Get PTD List
	const {
		getOrganizationList,
		getSinglePTDList,
		getPTDVendors,
		fetchProductsForPTDList,
	} = usePTD();
	const PTDList = getSinglePTDList(idPTDList, listName);
	const PTDVendors = Object.values(getPTDVendors()).flat();

	// Get Organizations
	const organizationList = getOrganizationList();

	// General Page Info
	const pageTitle = PTDList ? `Vendors Overview for ${PTDList.premiumTradeDirectoryName}` : 'Vendors Overview';
	const [isLoading, setIsLoading] = React.useState<boolean>(true);

	// View Vendor Profile Modal
	const [showViewVendorProfileModal, setViewVendorProfileModal] = React.useState<{
		show: boolean,
		vendorName: string | undefined
	}>({show: false, vendorName: undefined});

	// Memoize fetchData using useCallback
	const fetchProductsForPTDListCallback = useCallback((signal) => {
		fetchProductsForPTDList(idPTDList, signal);
	}, [idPTDList, fetchProductsForPTDList]);

	React.useEffect(() => {
		const controller = new AbortController();
    	const signal = controller.signal;

		fetchProductsForPTDListCallback(signal);
		setTimeout(() => {
			setIsLoading(false);
		}, 0);

		return () => {
			// cancel the request before component unmounts
			controller.abort();
		};
	}, [fetchProductsForPTDListCallback]);

	const columns = [
		{
			Header: 'Vendor Name',
			accessor: 'vendorName',
			filterable: true,
			Cell: (row: any) => {
				return <Text>{row.value}</Text>;
			}
		},
		{
			Header: 'Location',
			accessor: 'operatingLocation',
			filterable: true,
			Cell: (row: any) => {
				return <Text>{row.value}</Text>;
			}
		},
		{
			Header: 'Listed by',
			accessor: 'listedBy',
			filterable: true,
			className: 'text-center',
			Cell: (row: any) => {
				return <Text>{row.value}</Text>;
			}
		},
		{
			Header: 'Listed by within the same Sector(s)',
			accessor: 'listedBySector',
			filterable: true,
			className: 'text-center',
			Cell: (row: any) => {
				return <Text>{row.value}</Text>;
			}
		},
		{
			Header: 'Listed by within the same Industy(ies)',
			accessor: 'listedByIndusty',
			filterable: true,
			className: 'text-center',
			Cell: (row: any) => {
				return <Text>{row.value}</Text>;
			}
		},
		{
			accessor: 'View',
			Cell: (row: any) => (
				<ActionIcon title="Details" type="open" onClick={() => openViewVendorProfileModal(row.original.vendorName)} />
			),
			Header: '',
			filterable: false,
			id: 'View',
		}
	];

	// View Vendor Profile Modal
	function openViewVendorProfileModal(vendorName: string | undefined) {
		setViewVendorProfileModal({show: true, vendorName: vendorName});
	}

	function closeViewVendorProfileModal() {
		setViewVendorProfileModal({show: false, vendorName: undefined});
	}

	return (
		<Page title={pageTitle}>
			<div className="d-flex">
				<div className="d-flex flex-column w-100">
					<TitleBar
						pageTitle={pageTitle}
					/>
				</div>
			</div>
			<div className="container-fluid mb-2 px-5 py-3">
				<div className="row">
					<div className="col-12">
						<DataTable
							data={PTDVendors}
							columns={columns}
							filterable={true}
							resizable={true}
							loading={isLoading}
							noDataText="No vendors found"
						/>
					</div>
				</div>
			</div>

			<ViewVendorProfile
				isOpen={showViewVendorProfileModal.show}
				vendorName={showViewVendorProfileModal.vendorName}
				organizationList={organizationList}
				onRequestClose={closeViewVendorProfileModal}
			/>
		</Page>
	);
}

export default PTDVendorsOverview;
