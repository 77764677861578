import React from 'react';
import PTDAPIs from 'api/PTDAPIs';
import DeletePTDListModal from 'screens/PremiumTradeDirectory/components/modals/DeletePTDListModal';
import { IconAdd } from 'assets/icons';
import { Button, DataTable, FormGroup, Input } from 'components/core';
import { usePTD } from 'context/PTDContext';
import { useForm, Controller, FormProvider, FieldValues } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Text } from 'screens/ProductCatalog/components/columns';
import { getEnumFromVendorType } from 'utils/utils';
import { toast } from 'react-toastify';
import ActionCell from 'screens/PremiumTradeDirectory/components/ActionCell';

interface IProps {
	listName: string,
	listKey: string,
	list:  PremiumTradeDirectory[],
	idPTD: string | undefined,
	vendorType: VendorType | undefined,
	onIsLoading: (isLoading: boolean) => void
}

function ListView({ listName, listKey, list, idPTD, vendorType, onIsLoading}: IProps) {
	const navigate = useNavigate();
	const {
		addPTDList,
		updatePTDList,
		deletePTDList,
		getCompanyProfile,
		getOrganizationList,
		getDefaultVendorType,
	} = usePTD();

	const productListFormMethods = useForm<any>({ reValidateMode: 'onChange' });
	const [editTable, setEditTable] = React.useState<{ rowId: number, newLine?: boolean, listID?: string, duplicateRow?: number } | null>(null);
	const [isSavingRow, setIsSavingRow] = React.useState<boolean>(false);
	const [showDeletePTDListModal, setDeletePTDListModal] = React.useState<{show: boolean, idPTDList: number | null}>({show: false, idPTDList: null});
	const companyProfile: OrganizationDTO | null = getCompanyProfile();
	const organizationList = getOrganizationList();
	const defaultVendorType: VendorType = getDefaultVendorType();

	const ptdList: PremiumTradeDirectory[] = [...list];

	const columns = [
		{
			Header: 'Name',
			accessor: 'premiumTradeDirectoryName',
			filterable: true,
			Cell: (row: any) => {
				if (editTable?.rowId === row.index) {
					return <Controller
						key={'premiumTradeDirectoryName'}
						control={productListFormMethods.control}
						defaultValue={row.value || ''}
						rules={{
							required: 'This is a required field.',
						}}
						name="specs.premiumTradeDirectoryName"
						render={({ field, fieldState }) =>
							<FormGroup field={field} fieldState={fieldState} className="mt-1 mb-1">
								<Input
									id="premiumTradeDirectoryName"
									name={field.name}
									value={field.value}
									onChange={field.onChange}
									error={!!fieldState.error?.message}
									className="p-1 fs-14"
								/>
							</FormGroup>
						}
					/>;
				}
				if (row.value === undefined || row.value.length === 0) {
					return <Text></Text>;
				}
				return <Text>{row.value}</Text>;
			},
		},
		{
			Header: 'Total Products',
			accessor: 'total_products',
			filterable: true,
			className: 'text-center',
			Cell: (row: any) => {
				if (editTable?.rowId === row.index) {
					return '';
				}
				if (row.value === undefined || row.value.length === 0) {
					return <Text>0</Text>;
				}
				return <Text>{row.value}</Text>;
			},
		},
		{
			Header: 'Active Vendors',
			accessor: 'active_vendors',
			filterable: true,
			className: 'text-center',
			Cell: (row: any) => {
				if (editTable?.rowId === row.index) {
					return '';
				}
				if (row.value === undefined || row.value.length === 0) {
					return <Text>0</Text>;
				}
				return <Text>{row.value}</Text>;
			},
		},
		{
			Header: listKey === 'myList' ? 'Shared With' : 'Owned by',
			accessor: listKey === 'myList' ? 'shared_with' : 'ownedByCompanyId',
			filterable: true,
			className: 'text-center',
			Cell: (row: any) => {
				if (editTable?.rowId === row.index) {
					return '';
				}
				if (row.value === undefined || row.value.length === 0) {
					return <Text>Nobody</Text>;
				}
				return <Text>
					{
						listKey === 'myList' ?
							row.value :
							organizationList.find(org => org.id === row.value)?.organizationName || <i className="text-muted">Can`t find name</i>
					}
				</Text>;
			},
		},
		{
			accessor: 'id',
			show: false,
		},
		{
			Header: '',
			accessor: 'details',
			filterable: false,
			Cell: (row: any) => {
				const rowIsDisabled = !!editTable && editTable.rowId !== row.index;
				const isEditingCurrentRow = !!editTable && editTable.rowId === row.index || !!editTable?.duplicateRow && row.index === 0;

				return (
					<React.Fragment>
						<ActionCell
							formMethods={productListFormMethods}
							idPTDList={row.original.id}
							isEditingCurrentRow={isEditingCurrentRow}
							rowIsDisabled={rowIsDisabled}
							submitForm={submitProductListForm}
							closeAddRow={closeProductListAddRow}
							onClickEditOrDuplicate={(action: 'edit' | 'duplicate') => onClickEditOrDuplicate(row, action)}
							openDeleteModal={() => openDeletePTDListModal(row.original.id)}
							onClickView={() => onClickView(row, listKey)}
							isSavingRow={isSavingRow}
						/>
					</React.Fragment>
				);
			},
		}
	];

	/// new row start
	const newRow: PremiumTradeDirectory = {
		id: 0,
		premiumTradeDirectoryName: '',
		total_products: 0,
		active_vendors: 0,
		shared_with: 0,
		createdBy: companyProfile?.organizationName ?? '',
		vendorListId: 0,
		vendorListDescription: '',
		ownedByCompanyId: 0,
		lastModifiedAt: '',
		createdAt: '',
		validUntil: '',
		containsVendorType: 'Manufacturer',
		creatorId: ''
	};

	if (editTable?.newLine === true) {
		ptdList.unshift(newRow);
	}
	/// new row end

	/// duplicated row start
	if (!!editTable?.duplicateRow) {
		const row = ptdList.find(x => {
			return x.id === editTable.duplicateRow;
		});
		const lineItem: any = {...row};

		lineItem['id'] = 'duplicate';

		ptdList.unshift(lineItem);
	}
	/// duplicated row end

	/// Add Product List start
	function onClickView(row: any, listKey: string) {
		navigate(`/premium-trade-directories/${idPTD}/${listKey}/${row.original.id}`);
	}

	function onClickEditOrDuplicate(row: any, type: 'edit' | 'duplicate') {
		const keys = Object.keys(row.original);

		keys.forEach((key) => {
			productListFormMethods.setValue('specs.' + key, row.original[key]);
		});

		if (type === 'edit') {
			setEditTable({
				rowId: row.index,
				listID: row.original.id
			});
		}  else {
			setEditTable({
				rowId: 0,
				listID: '0',
				duplicateRow: row.original.id
			});
		}
	}

	function closeProductListAddRow() {
		productListFormMethods.reset();
		setEditTable(null);
	}

	async function submitProductListForm(fields: FieldValues) {
		setIsSavingRow(true);
		const listData: PremiumTradeDirectory = {...fields.specs};
		if (editTable) {
			onIsLoading(true);
			if (editTable.newLine || editTable.duplicateRow) {
				listData.id = ptdList ? ptdList.length : 0;

				try {
					const response: PremiumTradeDirectory | null = await PTDAPIs.premiumTradeDirectories.AddPremiumTradeDirectory({
						PremiumTradeDirectoryName: listData.premiumTradeDirectoryName,
						ContainsVendorType: getEnumFromVendorType(vendorType) ?? getEnumFromVendorType(defaultVendorType),
						VendorListDescription: '',
						ValidUntil: '',
					});

					if (response) {
						addPTDList(response);
						toast.success('The PTD list was successfully added!');
						return true;
					} else {
						return false;
					}
				} finally {
					onIsLoading(false);
				}
			} else {
				try {
					const response: PremiumTradeDirectory | null = await PTDAPIs.premiumTradeDirectories.UpdatePremiumTradeDirectory({
						PremiumTradeDirectoryName: listData.premiumTradeDirectoryName,
						ContainsVendorType: getEnumFromVendorType(vendorType) ?? getEnumFromVendorType(defaultVendorType),
						VendorListDescription: '',
						ValidUntil: '',
					}, listData.id);

					if (response) {
						updatePTDList(response, listData.id);
						toast.success('The PTD list was successfully updated!');
						return true;
					} else {
						return false;
					}
				} finally {
					onIsLoading(false);
				}
			}
		}

		setIsSavingRow(false);
		setEditTable(null);
		productListFormMethods.reset({}, { keepDirty: false, keepValues: false });
	}
	/// Add Product List end

	// delete modal start
	function openDeletePTDListModal(idPTDList: number) {
		setDeletePTDListModal({show: true, idPTDList: idPTDList});
	}

	function closeDeletePTDListModal() {
		setDeletePTDListModal({show: false, idPTDList: null});
	}

	async function onDeletePTDList(idPTDList: number | null) {
		onIsLoading(true);
		try {
			const response: string | null = await PTDAPIs.premiumTradeDirectories.DeletePremiumTradeDirectory(idPTDList);

			if (response) {
				deletePTDList(idPTDList);
				toast.success(response);
				return true;
			} else {
				return false;
			}
		} finally {
			onIsLoading(false);
		}
	}
	// delete modal end

	return (
		<FormProvider {...productListFormMethods}>
			<React.Fragment>
				<div className="bg-light border-bottom border-primary-200 px-3 py-2 d-flex align-items-center rounded-top custom-shadow">
					<h4 className="text-dark mb-0">{listName}</h4>
					{listKey === 'myList' && <Button
						onClick={() => {
							productListFormMethods.reset({}, { keepValues: false, keepDirty: false });
							setEditTable({
								rowId: 0,
								newLine: true,
							});
						}}
						disabled={!!editTable}
						className="ms-auto text-primary py-0 fs-14 fw-normal"
					>
						<IconAdd className="me-1 svg-20" />Add List
					</Button>}
				</div>
				<DataTable
					data={ptdList}
					columns={columns}
					filterable={true}
					sortable={true}
					resizable={true}
					noDataText="No data found."
				/>
			</React.Fragment>

			<DeletePTDListModal
				isOpen={showDeletePTDListModal.show}
				idPTDList={showDeletePTDListModal.idPTDList}
				onRequestClose={closeDeletePTDListModal}
				deletePTDList={onDeletePTDList}
			/>
		</FormProvider>
	);
}

export default ListView;
