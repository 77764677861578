import React from 'react';
import { FormGroup, Page } from 'components/core';
import OnBoardingProgressBar from './components/ProgressBar';
import { Controller, useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { PickListSelect } from 'components/scopematch/PickListSelect';
import { OnboardingErrorMsg } from './utils';
import OnboardingFooter from './components/OnboardingFooter';
import OnboardingContainer from './components/OnboardingContainer';
import { FormContext } from './FormContext';
import BackForwardButtons from './components/BackForwardButtons';
const WhichIndustriesSectors = () => {
	const { control, getValues } = useFormContext<OrganizationRequest>();
	const FormCxt = React.useContext(FormContext);
	const location = useLocation();
	const organizationType = getValues('organizationType');
	return (
		<Page
			title="Industries and Sectors"
			className="onboarding-wrapper"
		>
			<OnboardingContainer>
				<OnBoardingProgressBar indexInfo={FormCxt.CurrentIndex(organizationType, location.pathname)} />
				<h2 className="py-4 mb-0">Select which industries and sectors your company is a part of</h2>
				<Controller
					control={control}
					name="sectors"
					rules={{ required: OnboardingErrorMsg.Sector }}
					render={({ field, fieldState }) => (
						<FormGroup label="Sector" required={true} field={field} fieldState={fieldState}>
							<span className="fw-normal fst-italic text-gray-700">
								(select all that apply)</span>
							<PickListSelect
								id="sector-select"
								isMulti={true}
								name={field.name}
								value={field.value}
								onChange={field.onChange}
								placeholder=""
								pickListTypeName="Sector"
								error={!!fieldState.error?.message}
							/>
						</FormGroup>
					)}
				/>
				<Controller
					control={control}
					name="industries"
					rules={{ required: OnboardingErrorMsg.Industries }}
					render={({ field, fieldState }) => (
						<FormGroup label="Industry" field={field} fieldState={fieldState} required={true}>
							<span className="fw-normal fst-italic text-gray-700">
								(select all that apply)</span>
							<PickListSelect
								id="industries-select"
								isMulti={true}
								name={field.name}
								value={field.value}
								pickListTypeName="Industry"
								onChange={field.onChange}
								error={!!fieldState.error?.message}
								placeholder=""
							/>
						</FormGroup>
					)}
				/>
			</OnboardingContainer>
			<OnboardingFooter>
				<BackForwardButtons />
			</OnboardingFooter>

		</Page>
	);

};

export default WhichIndustriesSectors;
