import { ModalOverlay } from 'components/core';
import { oppositeOrganizationName } from 'components/helperfunctions';
import React from 'react';
import ContactCard from 'screens/Requests/components/ContactCard';
import LinkedOrganizationCard from 'screens/Requests/components/LinkedOrganizationCard';
import OrgCertificationsCard from 'screens/Requests/components/OrgCertificationsCard';
import OrgCompanyValuesCard from 'screens/Requests/components/OrgCompanyValuesCard';
import OrgFacilitiesCard from 'screens/Requests/components/OrgFacilitiesCard';
import OrgInsuranceRange from 'screens/Requests/components/OrgInsuranceRange';
import OrgLocationCard from 'screens/Requests/components/OrgLocationCard';

import { ReactComponent as IconCompanyType } from 'assets/icons/icon-company-type.svg';
import { ReactComponent as IconLocation } from 'assets/icons/icon-location.svg';
import { ReactComponent as IconWebsite } from 'assets/icons/icon-website.svg';

interface IProps {
	isOpen: boolean;
	vendorName: string | undefined,
	organizationList: OrganizationDTO[] | undefined,
	onRequestClose: () => void;
}

function ViewVendorProfile(props: IProps) {
	const { isOpen, vendorName, organizationList, onRequestClose } = props;
	const [orgDetails, setOrgDetails] = React.useState<OrganizationDTO | null>();
	const [isLoading, setIsLoading] = React.useState<boolean>(false);

	const orgHeadOffice = [
		orgDetails?.headOfficeLocation,
		orgDetails?.headOfficeLocationCountry
	]
		.filter(part => part !== undefined && part !== null && part !== '')
		.join(', ');

	React.useEffect(() => {
		let isMounted = true; // track whether the component is mounted
		setIsLoading(true);

		const fetchOrgDetails = async () => {
			try {
				const orgDetailsResults = organizationList?.find(org => org.organizationName === vendorName);
				if (isMounted) {
					setOrgDetails(orgDetailsResults);
				}
			} catch (error) {
				console.error('Error fetching organization details:', error);
			} finally {
				if (isMounted) {
					setIsLoading(false);
				}
			}
		};

		fetchOrgDetails();

		return () => {
			isMounted = false; // set to false when the component unmounts
		};
	}, [organizationList, vendorName]);

	return (
		<div>
			<ModalOverlay
				headerChildren="Vendor Profile"
				isOpen={isOpen}
				modalSize="modal-dialog-centered"
				onRequestClose={onRequestClose}
				isLoading={isLoading}
				hideCloseButton={true}
				className="vendor-profile-modal"
			>
				<div className="mb-4">
					<h3 className="">{orgDetails?.organizationName}</h3>
					<div className="d-flex flex-wrap">
						<p className="d-flex bg-light py-2 px-3 mb-0 mt-2 text-capitalize align-items-center me-2 rounded">
							<IconCompanyType className="me-2" />{orgDetails?.organizationType}</p>
						<p className="d-flex bg-light py-2 px-3 mb-0 mt-2 text-capitalize align-items-center me-2 rounded">
							<IconLocation className="me-2" />{orgHeadOffice}</p>
						{orgDetails?.companyUrl &&
							<p className="d-flex bg-light py-2 px-3 mb-0 mt-2 align-items-center rounded">
								<IconWebsite className="me-2" />
								<a
									href={orgDetails.companyUrl.includes('https://') || orgDetails.companyUrl.includes('http://')
										? orgDetails.companyUrl
										: `https://${orgDetails.companyUrl}`}
									target="_blank"
									rel="noreferrer"
								>
									{orgDetails.companyUrl.replaceAll('https://', '').replaceAll('http://', '')}
								</a>
							</p>
						}
					</div>
				</div>
				<ContactCard
					orgContact={orgDetails?.organizationContacts}
				/>
				<OrgFacilitiesCard
					orgFacilities={orgDetails?.organizationFacilities}
				/>
				<OrgLocationCard
					orgHeadOffice={orgHeadOffice}
					orgShippingLocation={orgDetails?.shippingLocation}
					orgManufacturingLocation={orgDetails?.fullManufacturingLocations}
					orgType={orgDetails?.organizationType}
					companyProfile={true}
				/>
				<OrgCertificationsCard
					orgCertifications={orgDetails?.standardsAndCertification}
				/>
				<OrgCompanyValuesCard
					orgCompanyValues={orgDetails?.companyValues}
				/>
				<OrgInsuranceRange
					orgInsuranceRange={orgDetails?.insuranceRange}
				/>
				<LinkedOrganizationCard
					organizationList={orgDetails?.manufacturersWorkWith}
					orgType={orgDetails?.organizationType}
					title={oppositeOrganizationName(orgDetails?.organizationType) + 's Currently Working With'}
					orgName={orgDetails?.organizationName}
				/>
				{orgDetails?.organizationType !== 'manufacturer' &&
					<LinkedOrganizationCard
						organizationList={orgDetails?.ownersWorkWith}
						orgType={orgDetails?.organizationType}
						title={orgDetails?.organizationType === 'owner' ? 'Distributors Currently Working With' : 'Owners Currently Working With'}
						orgName={orgDetails?.organizationName}
					/>
				}
				<LinkedOrganizationCard
					organizationList={orgDetails?.approvedManufacturerList}
					orgType={orgDetails?.organizationType}
					title={`Approved Manufacturer${orgDetails?.organizationType === 'owner' ? 's' : ' Listings (AML)'}`}
					showExplanation={false}
					isAML={true}
					orgName={orgDetails?.organizationName}
				/>
			</ModalOverlay>
		</div>
	);
}

export default ViewVendorProfile;
