import React from 'react';
import { FormGroup, Input } from 'components/core';
import { Controller, useFormContext } from 'react-hook-form';

interface IProps {
	value?: string;
	code: ProductSpecDTO;
	className?: string;
}

export const ColumnInput = React.memo(({ code, value, className }: IProps) => {
	const methods = useFormContext();
	return (
		<Controller
			key={`${code.productSpecCode}`}
			control={methods.control}
			defaultValue={value}
			rules={{
				required: 'This is a required field.',
			}}
			name={`specs.${code.productSpecCode}`}
			render={({ field, fieldState }) => (
				<FormGroup field={field} fieldState={fieldState} className={className}>
					<Input
						id="select"
						name={field.name}
						value={field.value}
						onChange={field.onChange}
					/>
				</FormGroup>
			)}
		/>
	);
});
