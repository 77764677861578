import React from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { Buttons } from 'screens/ProductCatalog/components/columns';
import AuthContext from 'context/AuthContext';
import PTDAPIs from 'api/PTDAPIs';
import { ActionIcon } from 'components/core';

interface IProps {
	formMethods: UseFormReturn,
	idPTDList: number,
	isEditingCurrentRow: boolean,
	rowIsDisabled: boolean,
	submitForm: (fields: FieldValues) => Promise<boolean | undefined>,
	closeAddRow: () => void,
	onClickEditOrDuplicate: (type: 'edit' | 'duplicate') => void,
	openDeleteModal: (idPTDList: number) => void
	onClickView?: () => void,
	isSavingRow: boolean
}

function ActionCell({
	formMethods,
	idPTDList,
	isEditingCurrentRow,
	rowIsDisabled,
	submitForm,
	closeAddRow,
	onClickEditOrDuplicate,
	openDeleteModal,
	onClickView,
	isSavingRow,
}: IProps) {
	const { currentUser } = React.useContext(AuthContext);
	const [access, setAccess] = React.useState<PTDAccess[] | null>(null);

	React.useEffect(() => {
		let isMounted = true; // track whether the component is mounted
		const controller = new AbortController();
    	const signal = controller.signal;

		const fetchAccess = async () => {
			try {
				const result = await PTDAPIs.premiumTradeDirectories.GetUserAccessForList({ premiumTradeDirectoryId: idPTDList }, signal);
				if (isMounted) {
					setAccess(result);
				}
			} catch (error) {
				if (isMounted) {
					console.error('Error fetching data:', error);
				}
			}
		};

		fetchAccess();

		return () => {
			isMounted = false; // set to false when the component unmounts
			controller.abort(); // cancel the request before component unmounts
		};
	}, [idPTDList]);

	const accessForList = access ? access.find(acc => acc.email === currentUser.email) : null;
	return !accessForList || accessForList.permission === 'Edit' || accessForList.permission === 'Suggest' ? (
		<React.Fragment>
			<Buttons
				isEditing={isEditingCurrentRow}
				disabled={rowIsDisabled}
				onClickSave={formMethods.handleSubmit(submitForm)}
				onClickCancel={closeAddRow}
				onClickEdit={() => onClickEditOrDuplicate('edit')}
				onClickDelete={() => openDeleteModal(idPTDList)}
				onClickDuplicate={() => onClickEditOrDuplicate('duplicate')}
				onClickView={onClickView}
				isSaving={isSavingRow}
			/>
		</React.Fragment>
	) : onClickView ? (
		<React.Fragment>
			<ActionIcon title="View Details" type="open" onClick={onClickView} />
		</React.Fragment>
	) : null;
};

export default ActionCell;
