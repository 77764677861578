import UploadFilesComponentHook from 'components/customHooks/uploadFilesComponentHootk';
import React from 'react';
import UploadService from 'api/UploadFiles';

interface IUploadProps {
	disabled: boolean;
	setImportResults(arg0: OrganizationImportResultsDTO[] | null): void;
}

const UploadImportFile = (props: IUploadProps) => {
	return (
		<UploadFilesComponentHook disabled={props.disabled} upload={upload} />
	);
	async function upload(idx: number, file: any) {
		props.setImportResults(null);
		const result: OrganizationImportResultsDTO[] | null = await UploadService.upload(file);
		props.setImportResults(result);
	}
};

export default UploadImportFile;