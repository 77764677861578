import React from 'react';
import { ReactComponent as IconContactDetails } from 'assets/icons/icon-contact-details.svg';
import { ReactComponent as IconPhone } from 'assets/icons/icon-phone.svg';
import { ReactComponent as IconEmail } from 'assets/icons/icon-email.svg';
import { ReactComponent as IconContact } from 'assets/icons/icon-contact.svg';


interface IProps {
	orgContact?: OrganizationContactDTO[];
}

const ContactCard = (props: IProps) => {
	return (
		<React.Fragment>
			{props.orgContact?.map((contact) => (
				<div key={`contact-${contact.id}`} className="card shadow p-3 mb-2 rounded">
					<div className="d-flex align-items-center">
						<div className="rounded-circle bg-secondary-100 p-2 me-3">
							<IconContactDetails />
						</div>
						<h5 className="mb-0">Contact</h5>
					</div>
					<hr />
					{contact.contactName !== '' &&
						<div className="d-flex">
							<span
								className="me-4"
							>
								<IconContact className="me-2" />
								{contact.contactName}, {contact.contactRole}

							</span>
							<span
								className="me-4"
							>
								<IconEmail className="me-2" />
								{contact.contactEmail}

							</span>
							<span
								className=""
							>
								<IconPhone className="me-2" />
								{contact.contactPhone}
							</span>
						</div>
					}
				</div>
			))}
		</React.Fragment>
	);
};

export default ContactCard;