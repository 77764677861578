import React, { useState, useEffect } from 'react';
import { FormGroup, LoadingPage, ModalOverlay, Page, Select } from 'components/core';
import { useNavigate, useParams } from 'react-router-dom';
import coreAPI from './coreAPI';
import useEffectAsync from 'components/customHooks/useEffectAsync';
import { Controller, useForm } from 'react-hook-form';
import { Input, CheckboxGroup, Button } from 'components/core';
import { useToast } from 'components/customHooks/useToast';
import TitleBar from 'screens/Components/TitleBar';
import scopeMatchAPI from 'api/ScopeMatchAPI';


interface ITenantUserDetailsForm {
	fullName: string;
	preferredName: string;
	emailAddress: string;
	password: string;
	roleGroup: string[];
	organizationID: number;
}

const UserDetails =  () => {
	const tenantId = 1;
	const { userId } = useParams();
	const navigate = useNavigate();
	const defaultUser: UserDetailsDTO = {
		id: userId ? +userId : -1,
		fullName: '',
		preferredName: '',
		email: '',
		password: '',
		roleIds: [],
		tenantId: tenantId,
		organizationID: 0
	};

	const [user, setUser] = useState<UserDetailsDTO>(defaultUser);
	const [roles, setRoles] = useState<RoleDTO[]>([]);
	const [permissions, setPermissions] = useState<PermissionDTO[]>([]);
	const [assignedPermissions, setAssignedPermissions] = useState<PermissionDTO[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
	const [organizationList, setOrganizationList] = React.useState<{ value: number | undefined, label: string | undefined }[]>([]);
	const toast = useToast();
	const title = 'User Details';

	const { handleSubmit, control, setValue } = useForm<ITenantUserDetailsForm>({
		defaultValues: {
			fullName: '',
			preferredName: '',
			emailAddress: '',
			password: '',
			organizationID: 0,
		},
		mode: 'onSubmit',
		reValidateMode: 'onChange'
	});

	const roleOptions = roles.map(role => ({ label: role.name!, value: role.id!.toString() }));

	useEffectAsync(async (isMounted: any) => {
		setIsLoading(true);
		const roleData = await coreAPI.getRoles();
		const permissionData = await coreAPI.getPermissions();
		if (userId && +userId !== -1) {
			const tenantUser = await coreAPI.getTenantUser({ tenantId: +tenantId!, userId: +userId! });
			if (isMounted()) {
				setUser(tenantUser);
			}
		}
		if (isMounted()) {
			setRoles(roleData);
			setPermissions(permissionData);
			setIsLoading(false);
		}
	});

	useEffect(() => {
		if (user.roleIds) {
			let tempPermissionIds: number[] = [];
			user.roleIds.forEach(roleId => {
				const role = roles.find(r => r.id === roleId);
				if (role) {
					tempPermissionIds.push(...role.permissionIds!);
				}
			});
			tempPermissionIds = tempPermissionIds.filter(tp => tp > 0);
			tempPermissionIds = tempPermissionIds.filter(distinctFilter);
			const tempPermissions = permissions.filter(p => tempPermissionIds.some(tpi => tpi === p.id));
			setAssignedPermissions(tempPermissions);
		}

		async function getOrganizationList() {
			const results = await scopeMatchAPI.organization.GetOrganizationList();

			if (results !== null) {
				const options = results.map((item) => {
					return { value: item.id, label: item.organizationName };
				});


				options.sort((a, b) => {
					const labelA = a.label || '';
					const labelB = b.label || '';
					return labelA.localeCompare(labelB);
				});

				setOrganizationList(options);
			} else {
				toast.error('Unable to retrieve Organization list');
			}
			setIsLoading(false);
		}
		getOrganizationList();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		roles,
		permissions,
		user.roleIds
	]);

	useEffect(() => {
		if (user !== undefined) {
			setValue('fullName', user.fullName!);
			setValue('preferredName', user.preferredName!);
			setValue('emailAddress', user.email!);
			setValue('roleGroup', user.roleIds !== undefined ? user.roleIds.map(roleId => roleId.toString()) : []);
			setValue('organizationID', user.organizationID!);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	const generatePassword = (): string => {
		var longth = 8,
			allc = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ',
			passgen = '';
		for (var i = 0; i < longth; i++) {
			passgen += allc[Math.floor(Math.random() * allc.length)];
		}
		return passgen + '@1';
	};

	const onUserDetailsSave = (formData: ITenantUserDetailsForm) => {
		setIsLoading(true);
		const newUser = {
			...user,
			fullName: formData.fullName,
			preferredName: formData.preferredName,
			email: formData.emailAddress,
			password: formData.password,
			organizationID: formData.organizationID,
		};

		coreAPI.postTenantUser(newUser)
			.then(() => {
				navigate('/admin/manage-users');
				setIsLoading(false);
				toast.success('User successfully saved');
			});
	};

	const onUserDetailsDelete = () => {
		setIsLoading(true);
		coreAPI.deleteTenantUser({ userId: parseInt(userId!) || 0, tenantId: tenantId })
			.then(() => {
				navigate('/admin/manage-users');
				setIsLoading(false);
				toast.success('User successfully deleted');
			});
	};

	const onCancelClick = () => {
		navigate('/admin/manage-users');
	};

	const openModal = () => {
		if (!showDeleteModal) {
			setShowDeleteModal(true);
		}
	};

	const closeModal = () => {
		setShowDeleteModal(false);
	};

	const distinctFilter = (value: any, index: number, self: Array<any>) => {
		return self.indexOf(value) === index;
	};

	if (isLoading === true) {
		return <LoadingPage />;
	}

	return (
		<Page title={title}>
			<TitleBar
				pageTitle={title}
				backButton={true}
			/>
			<div className="container-fluid px-5 py-3">
				<div className="row">
					<div className="col-lg-7">
						<Controller
							control={control}
							name="fullName"
							rules={{ required: 'Enter full name' }}
							render={({ field, fieldState }) =>
								<FormGroup label="Full Name" field={field} fieldState={fieldState}>
									<Input
										{...field}
										error={!!fieldState.error?.message}
									/>
								</FormGroup>
							}
						/>
					</div>
					<div className="col-lg-7">
						<Controller
							control={control}
							name="preferredName"
							rules={{ required: 'Enter preferred name' }}
							render={({ field, fieldState }) =>
								<FormGroup label="Preferred Name" field={field} fieldState={fieldState}>
									<Input
										{...field}
										error={!!fieldState.error?.message}
									/>
								</FormGroup>
							}
						/>
					</div>
					<div className="col-lg-7">
						<Controller
							control={control}
							name="emailAddress"
							rules={{ required: 'Enter email address' }}
							render={({ field, fieldState }) =>
								<FormGroup label="Email address" field={field} fieldState={fieldState}>
									<Input
										{...field}
										error={!!fieldState.error?.message}
									/>
								</FormGroup>
							}
						/>
					</div>
					<div className="col-lg-7">
						<Controller
							control={control}
							name="organizationID"
							rules={{ required: 'Select an organisation name' }}
							render={({ field, fieldState }) => (
								<FormGroup label="Organization" field={field} fieldState={fieldState}>
									<Select
										isMulti={false}
										options={organizationList}
										value={field.value}
										onChange={(selectedOption) => {
											field.onChange(selectedOption);
										}}
									/>
								</FormGroup>
							)}
						/>
					</div>
					{userId && +userId !== -1 &&
						<div className="col-lg-7">
							<Controller
								control={control}
								name="password"
								render={({ field, fieldState }) =>
									<FormGroup label="Change Password" field={field} fieldState={fieldState}>
										<Input
											{...field}
											error={!!fieldState.error?.message}
										/>
										<Button
											className="btn-primary mt-2"
											disabled={isLoading}
											onClick={() => field.onChange(generatePassword())}
										>
											Generate password
										</Button>
									</FormGroup>
								}
							/>
						</div>
					}
				</div>
				<div className="row">
					<div className="col-lg-7">
						<Controller
							control={control}
							name="roleGroup"
							render={({ field, fieldState }) =>
								<FormGroup label="Role settings:" field={field} fieldState={fieldState}>
									<CheckboxGroup
										{...field}
										options={roleOptions}
									/>
								</FormGroup>
							}
						/>
					</div>
				</div>
				{assignedPermissions.length > 0 &&
					<div className="row">
						<div className="col-lg-7">
							<label>Permissions user will have:</label>
							<div className="row">
								{assignedPermissions.map(permission => {
									return (
										<div key={permission.code} className="col-lg-4">
											{permission.name}
										</div>
									);
								})}
							</div>
						</div>
					</div>
				}
				<div className="mt-3 mb-4">
					{userId !== '-1' && (
						<Button
							className="btn-danger me-2"
							disabled={isLoading}
							onClick={openModal}
						>
							Delete
						</Button>
					)}
					<Button
						className="btn-outline-primary me-2"
						onClick={onCancelClick}
					>
						Cancel
					</Button>
					<Button
						className="btn-primary"
						disabled={isLoading}
						onClick={handleSubmit(onUserDetailsSave)}
					>
						Save
					</Button>

				</div>
			</div>
			<ModalOverlay
				isOpen={showDeleteModal}
				modalSize="modal-lg"
				onRequestClose={closeModal}
				headerChildren="Confirm?"
				confirmButtonChildren="Confirm"
				cancelButtonChildren="Cancel"
				confirmButtonAction={() => {
					onUserDetailsDelete();
				}}
				cancelButtonAction={() => {
					closeModal();
				}}
			>
				Are you sure you want to delete this account?
			</ModalOverlay>
		</Page >
	);
};

export default UserDetails;