import React from 'react';
import { FormGroup, ModalOverlay } from 'components/core';
import { PickListSelect } from 'components/scopematch/PickListSelect';
import { Controller, useForm } from 'react-hook-form';
import { ErrorMessages } from 'utils/utils';

interface ProductTypeForm {
	productType: undefined | string[];
}

interface IProps {
	isOpen: boolean;
	onRequestClose: () => void;
	addProductType: (producttype: string[]) => void;
	productType: string[] | undefined;
}

const ProductTypeModal = (props: IProps) => {
	const { control, handleSubmit, reset } = useForm<ProductTypeForm>();
	const { isOpen, onRequestClose, addProductType, productType } = props;
	const [productAdded, setProductAdded] = React.useState<string[] | null>(null);
	if (productAdded !== null) {
		return (
			<ModalOverlay
				isOpen={true}
				modalSize="modal-dialog-centered"
				onRequestClose={close}
				headerChildren={`Success! You've added '${productAdded}' to your catalog`}
				confirmButtonChildren="Add"
				confirmButtonAction={restartForm}
				cancelButtonChildren="Done"
				cancelButtonAction={close}
				hideCloseButton={true}
			>
				Would you like to add another product type?
			</ModalOverlay>
		);
	}

	return (
		<ModalOverlay
			isOpen={isOpen}
			modalSize="modal-dialog-centered"
			onRequestClose={close}
			headerChildren="Add Product Type"
			confirmButtonChildren="Submit"
			cancelButtonChildren="Cancel"
			confirmButtonAction={handleSubmit(submitForm)}
			cancelButtonAction={close}
			hideCloseButton={true}
		>
			<Controller
				control={control}
				name="productType"
				rules={{
					required: ErrorMessages.ProductType
				}}
				render={({ field, fieldState }) =>
					<FormGroup label="Product Type" field={field} fieldState={fieldState}>
						<PickListSelect
							id="product-type-select"
							name={field.name}
							value={field.value}
							pickListTypeName="Product Type"
							onChange={field.onChange}
							placeholder=""
							filter={productType}
							closeMenuOnSelect={false}
							isMulti={true}
						/>
					</FormGroup>
				}
			/>
		</ModalOverlay>

	);
	function submitForm(fields: ProductTypeForm) {
		if (fields.productType) {
			addProductType(fields.productType);
			//setProductAdded(fields.productType);
			reset();
			onRequestClose();
		}
	}
	function restartForm() {
		setProductAdded(null);
	}
	function close() {
		reset();
		onRequestClose();
		restartForm();
	}
};

export default ProductTypeModal;