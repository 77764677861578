import React from 'react';
import { Button } from 'components/core';
import { ReactComponent as IconLogout } from 'assets/icons/icon-logout.svg';
import { useMsal } from '@azure/msal-react';
import classNames from 'classnames';
import { RequestContext } from 'screens/Requests/RequestContext';
interface IProps {
	className?: string;
}

const LogoutButton = (props: IProps) => {
	const { instance } = useMsal();
	const requestCxt = React.useContext(RequestContext);
	const handleNavigate = () => {
		if (requestCxt.isRequestDirty) {
			const confirmed = window.confirm('Your changes to the request are not saved. Are you sure you want to navigate away?');
			if (confirmed) {
				logout();
			}
		} else {
			logout();
		}
	};

	const logout = React.useCallback(() => {
		instance.logoutRedirect();
	}, [instance]);

	return (
		<Button className={classNames('btn-logout d-flex align-items-baseline', props.className)} onClick={handleNavigate}>
			<span>Logout</span>
			<IconLogout className="ms-2" />
		</Button>
	);
};
export default LogoutButton;
