import React from 'react';
import { IconAdd } from 'assets/icons';
import { Button, LoadingPage, Page } from 'components/core';
import { FormProvider, useForm } from 'react-hook-form';
import TitleBar from 'screens/Components/TitleBar';
import { Text, Buttons } from 'screens/ProductCatalog/components/columns';
import { toast } from 'react-toastify';
import scopeMatchAPI from 'api/ScopeMatchAPI';
import { ColumnInput } from 'screens/ProductCatalog/components/columns/ColumnInput';
import SpecificationTable from 'components/scopematch/SpecificationTable';
import { useNavigate } from 'react-router-dom';
import ProductTypeAddModal from 'screens/ProductCatalog/components/Forms/ProductTypeAddModal';

const PickListProductTypes = () => {

	const productFormMethods = useForm<any>({ reValidateMode: 'onChange' });
	const [editTable, setEditTable] = React.useState<{ productType: string, rowId: number, newLine?: boolean, specCodeId?: number, duplicateRow?: number } | null>(null);
	const [isLoading, setIsLoading] = React.useState<boolean>(true);
	const [isSaving, setIsSaving] = React.useState<boolean>(false);
	const [productTypeTitle, setProductTypeTitle] = React.useState<string | undefined>('');
	const [showProductTypeModal, setShowProductTypeModal] = React.useState<boolean>(false);
	const [productSpecsList, setProductSpecsList] = React.useState<ProductTypeDTO[]>([]);
	const methods = useForm<any>({ reValidateMode: 'onChange' });

	const navigate = useNavigate();
	const newRow: { [key: string]: string | JSX.Element } = {
		id: 'add-id',
		productTypeName: 'add-productTypeName',
	};
	const pageTitle = 'Product Types';
	React.useEffect(() => {
		async function fetchSpecs() {
			try {
				const productTypeListResult = await scopeMatchAPI.product.GetProductTypeList();

				if (productTypeListResult !== null) {
					const sortedProductTypeList = productTypeListResult.sort((a, b) => {
						if (!a.productTypeCode) { return 1; }
						if (!b.productTypeCode) { return -1; }
						return a.productTypeCode.localeCompare(b.productTypeCode);
					});
					setProductSpecsList(sortedProductTypeList);
					setIsLoading(false);
				}
			} catch (error) {
				toast.error('Could not load the product catalog');
			}
		}
		fetchSpecs();
	}, []);


	function handleNavigate() {
		navigate('/');
	}
	if (isLoading === true) {
		return <LoadingPage />;
	}
	return (
		<FormProvider {...methods}>
			<Page title={pageTitle}>
				<div className="d-flex flex-column w-100">
					<TitleBar
						pageTitle={pageTitle}
						backButton={true}
						onClick={handleNavigate}
					/>
				</div>
				<div className="container-fluid mb-5 px-5">
					<div className="mt-4">
						<Button
							className="btn btn-primary"
							onClick={openProductTypeModal}>
							<IconAdd />Add Product Type
						</Button>
					</div>
					<div className="mt-4">
						{productSpecsList?.map((item, index) => {
							const columns = [
								{
									accessor: 'id',
									Header: 'id',
									Cell: (row: any) => {
										return row.value;
									},
									show: false,
								},
								{
									Header: 'Spec Category',
									accessor: 'code',
									Cell: (row: any) => {
										if (editTable?.productType === item.productTypeCode && editTable?.rowId === row.index) {
											return <ColumnInput code={{ productSpecCode: row.value }} value={row.value || ''} />;
										}
										return <Text>{row.value}</Text>;
									},
									show: true
								},
								{
									Header: '',
									accessor: 'delete',
									Cell: (row: any) => {
										const isCurrentTable = !!editTable && editTable.productType === item.productTypeCode;
										const isEditingCurrentRow = isCurrentTable && !!editTable && editTable.rowId === row.index || isCurrentTable && !!editTable &&
											editTable?.productType === item.productTypeCode &&
											!!editTable?.duplicateRow &&
											row.index === 0;
										const rowIsDisabled = !!editTable && ((isCurrentTable && editTable.rowId !== row.index) || !isCurrentTable);
										return (
											<Buttons
												isEditing={isEditingCurrentRow}
												disabled={rowIsDisabled}
												onClickSave={methods.handleSubmit(submitProductForm)}
												onClickCancel={closeProductAddRow}
												onClickEdit={() => onClickEditOrDuplicate(row, item.productTypeCode ?? '', 'edit')}
												isSaving={isSaving}
											/>
										);
									},
									show: true
								}
							];

							const specs = item?.productSpecCodes;
							const data = specs?.map((v) => {
								if (v.productSpecCode) {
									//if (isAny?.includes(false) ?? false) {
									const lineItem: any = {};
									lineItem['id'] = v.productSpecPickListItemId;
									lineItem['code'] = v.productSpecDescription;
									return lineItem;
									//}
								}
								return undefined;
							}).filter(Boolean);
							data?.reverse();
							const tableData = data || [];
							if (editTable?.productType === item.productTypeCode && editTable?.newLine === true) {
								tableData?.unshift(newRow);
							}

							return (
								<SpecificationTable
									key={`table-${index}`}
									productTypeName={item.productTypeCode}
									columns={columns}
									data={tableData}
									disabled={!!editTable}
									maxEntries={5}
									emptyTableTitle={'Please add specifications you would like associated with this product type.'}
									addSpecTitle={'Add Spec Category'}
									onClickAddSpecCategory={() => {
										productFormMethods.reset({}, { keepValues: false, keepDirty: false });
										setProductTypeTitle(item.productTypeCode);
										setEditTable({
											productType: item.productTypeCode ?? '',
											rowId: 0,
											newLine: true,
										});
									}}
									onClickEditSpec={() => {
										navigate(`/admin/PickListProductSpecs/${item.id}`);
									}}
								/>
							);
						})}
					</div>
				</div>

				<ProductTypeAddModal
					isOpen={showProductTypeModal}
					onRequestClose={closeProductTypeModal}
					addProductType={addProductType}
				/>
			</Page>
		</FormProvider >
	);

	function onClickEditOrDuplicate(row: any, productTypeName: string, type: 'edit' | 'duplicate') {
		const keys = Object.keys(row.original);
		const nonProductVals = ['id', 'name', 'productTypeName', 'delete'];
		nonProductVals.forEach((val) => {
			const index = keys.indexOf(val);
			if (index > -1) {
				keys.splice(index, 1);
			}
		});
		keys.forEach((key) => {
			productFormMethods.setValue('specs.' + key, row.original[key]);
		});
		if (type === 'edit') {
			setProductTypeTitle(productTypeName);
			setEditTable({
				productType: productTypeName,
				rowId: row.index,
				specCodeId: row.original.id
			});
		}
	}

	function closeProductAddRow() {
		methods.reset();
		setEditTable(null);
	}

	function openProductTypeModal() {
		setShowProductTypeModal(true);
	}
	function closeProductTypeModal() {
		setShowProductTypeModal(false);
	}
	async function addProductType(productType: string) {
		const newProduct: SpecificationUpsertDTO = {
			pickListTypeCode: productType,
		};
		var result = await scopeMatchAPI.pickListItems.AddProductTable(newProduct) as SpecificationDTO;

		const newProductType: ProductTypeDTO = {
			id: result.id,
			productTypeCode: productType,
			productTypeDescription: productType,
			productSpecCodes: []
		};

		setProductSpecsList(v => [newProductType, ...v]);
		if (productType) {
			setProductTypeTitle(productType);
		}
	}

	async function submitProductForm(fields: { specs: { [x: string]: string } }) {
		setIsSaving(true);
		const keys = Object.keys(fields.specs);
		const isAny = keys.map(v => fields.specs[v].toLowerCase() === '');

		if (!isAny.includes(false)) {
			toast.error('A specific Spec Category is required');
			return;
		}

		if (productTypeTitle === undefined) {
			toast.error('Product type title is missing');
			return;
		}
		const result = await addSpecificationToTable(editTable?.specCodeId, productTypeTitle, fields);
		if (result === 409) {
			toast.error('This Product already exists');
			setIsSaving(false);
			return;
		}
		if (result === true) {
			setIsSaving(false);
			setEditTable(null);
			methods.reset({}, { keepDirty: false });
			return;
		}
		if (result === false) {
			toast.error('Something went wrong!');
		}
	}


	async function addSpecificationToTable(id: number | undefined, productTypeCode: string, fields: { specs: { [x: string]: string } }): Promise<boolean | number> {
		const prod: ProductTypeDTO = productSpecsList?.find(v => v.productTypeCode === productTypeCode) as ProductTypeDTO;
		for (const key in fields.specs) {
			const specCode = fields.specs[key];
			if (prod) {
				if (prod.productSpecCodes && prod.productSpecCodes
					.filter(v => v.productSpecDescription?.toLowerCase() === specCode.toLowerCase()
						&& (key === 'undefined' || v.productSpecCode !== key + ' - ' + prod.productTypeCode)).length > 0) {
					toast.error('This specification already exists for this product type');
					return true;
				}
				const updatedProductSpecCodes = prod.productSpecCodes?.map((productSpec) =>
					productSpec.productSpecDescription === key
						? { ...productSpec, ...{ productSpecDescription: specCode } }
						: productSpec
				);
				if (key === 'undefined') {
					updatedProductSpecCodes?.push({ productSpecCode: specCode, productSpecDescription: specCode });
				}
				const updatedProductTypes = productSpecsList?.map((productType) => {
					if (productType.productTypeCode === productTypeCode) {
						return { ...productType, ...{ productSpecCodes: updatedProductSpecCodes } };
					}
					return productType;
				});
				setProductSpecsList(updatedProductTypes as ProductTypeDTO[]);
			}
			const spec: SpecificationUpsertDTO = {
				pickListTypeCode: productTypeCode,
				previousCode: key,
				newCode: specCode,
			};
			scopeMatchAPI.pickListItems.AddProductSpec(spec);
		}

		return true;
	}

};

export default PickListProductTypes;