import React from 'react';
import { ReactComponent as IconLocationSecondary } from 'assets/icons/icon-location-secondary.svg';
import Pill from 'screens/Requests/components/pills/Pill';


interface IProps {
	orgFacilities?: OrganizationFacilityDTO[];
}

const OrgFacilitiesCard = (props: IProps) => {
	if (props.orgFacilities == undefined || props.orgFacilities.length == 0) {
		return <React.Fragment />;
	}

	return (
		<div className="card shadow p-3 mb-2 rounded">
			<div className="d-flex align-items-center">
				<div className="rounded-circle bg-secondary-100 p-2 me-3">
					<IconLocationSecondary />
				</div>
				<h5 className="mb-0">Facilities / Plants</h5>
			</div>
			<hr />
			<div className="d-flex flex-wrap">
				{props?.orgFacilities && props.orgFacilities.length > 0 ? (
					props.orgFacilities.map((facility, sckey) => (
						<Pill
							className="text-primary fw-bold"
							key={`facility-${sckey}`}
						>
							{facility.facilityLocationCity + ', ' + facility.facilityLocationCountry}
						</Pill>
					))
				) : (
					<p className="text-gray-600 fst-italic">No certifications and standards specified</p>
				)}
			</div>
		</div>

	);
};

export default OrgFacilitiesCard;