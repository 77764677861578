import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;
const settingsAPI = {
	getSettings: () => {
		return axios.get(`${apiUrl}/anon/web/settings`)
			.then(response => response.data as WebSettings);
	},
};

export default settingsAPI;