import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';
import Pill from './Pill';
import { matchSpecClass } from 'utils/utils';

interface IProps {
	specMatchedCount: number | undefined;
	specTotalCount: number | undefined;
	className?: string;
	wording?: string;
}

const SpecCountPill: React.FC<PropsWithChildren<IProps>> = (props: IProps) => {
	if (props.specMatchedCount === 0) {
		return null;
	}

	const percentage = (props.specMatchedCount && props.specTotalCount)
		? (props.specMatchedCount / props.specTotalCount) * 100
		: 0;

	return (
		<Pill className={classNames('text-nowrap', props.className, matchSpecClass(percentage))}>
			{props.specMatchedCount}
			&nbsp;/&nbsp;
			{props.specTotalCount} {props.wording ? props.wording : 'Specs'}
		</Pill>
	);
};

export default SpecCountPill;
