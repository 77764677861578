import React from 'react';
import { FormGroup, Input, Page } from 'components/core';
import OnBoardingProgressBar from './components/ProgressBar';
import { Controller, useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { OnboardingErrorMsg } from './utils';
import OnboardingFooter from './components/OnboardingFooter';
import OnboardingContainer from './components/OnboardingContainer';
import { emailRegex } from 'utils';
import { FormContext } from './FormContext';
import BackForwardButtons from './components/BackForwardButtons';

const ContactInfo = () => {
	const { control, getValues } = useFormContext();
	const FormCxt = React.useContext(FormContext);
	const location = useLocation();
	const organizationType = getValues('organizationType');
	return (
		<Page
			title="Contact Information"
			className="onboarding-wrapper"
		>
			<OnboardingContainer>
				<OnBoardingProgressBar indexInfo={FormCxt.CurrentIndex(organizationType, location.pathname)} />
				<h2 className="pt-4 mb-2">Set a main contact for ScopeMatch</h2>
				<p className="mb-4">This will appear on your company profile and is how you will be contacted in ScopeMatch.</p>
				<Controller
					control={control}
					name="organizationContacts[0].contactRole"
					rules={{ required: OnboardingErrorMsg.Role }}
					render={({ field, fieldState }) =>
						<FormGroup id="roleInput" label="Role" required={true} field={field} fieldState={fieldState}>
							<p className="fst-italic text-gray-800 mb-2">Examples: Sales, Manager, Procurement Officer</p>
							<Input
								{...field} error={!!fieldState.error?.message}
							/>
						</FormGroup>
					}
				/>
				<Controller
					control={control}
					name="organizationContacts[0].contactName"
					rules={{ required: OnboardingErrorMsg.Name }}
					render={({ field, fieldState }) =>
						<FormGroup id="nameInput" label="Name" required={true} field={field} fieldState={fieldState}>
							<Input
								{...field} error={!!fieldState.error?.message}
							/>
						</FormGroup>
					}
				/>
				<Controller
					control={control}
					name="organizationContacts[0].contactEmail"
					rules={{
						required: OnboardingErrorMsg.Email,
						pattern: {
							value: emailRegex,
							message: OnboardingErrorMsg.Email,
						},
					}}
					render={({ field, fieldState }) => (
						<FormGroup id="emailInput" required={true} label="Email" field={field} fieldState={fieldState}>
							<Input {...field} error={!!fieldState.error?.message} />
						</FormGroup>
					)}
				/>
				<Controller
					control={control}
					name="organizationContacts[0].contactPhone"
					rules={{
						required: OnboardingErrorMsg.PhoneNumber
					}}
					render={({ field, fieldState }) => (
						<FormGroup id="phoneInput" required={true} label="Phone" field={field} fieldState={fieldState}>
							<Input {...field} error={!!fieldState.error?.message} />
						</FormGroup>
					)}
				/>
			</OnboardingContainer>
			<OnboardingFooter>
				<BackForwardButtons />
			</OnboardingFooter>

		</Page>
	);
};

export default ContactInfo;
