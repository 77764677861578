import React from 'react';
import { ReactComponent as Logo } from 'assets/icons/logo.svg';

const ScopeMatchLogo =  () => {
	return (
		<nav className="container-fluid">
			<div className="row">
				<div className="offset-lg-1 py-3">
					<Logo className="onboarding-logo" />
				</div>
			</div>
		</nav>
	);
};

export default ScopeMatchLogo;
