import classNames from 'classnames';
import React from 'react';
import { ReactComponent as IconSaved } from 'assets/icons/icon-saved.svg';
import { matchColorClass, matchBGColorClass } from '../../../utils/utils';
import { Button } from 'components/core';
import { IconArrowRight } from 'assets/icons';
import { ReactComponent as IconCircleTick } from 'assets/icons/icon-circle-tick.svg';
import SpecCountPill from './pills/SpecCountPill';


interface IProps {
	matchDetails: MatchResultDTO | null;
	requestId: number;
	requestDetails: MatchRequestDetailDTO | null;
}

const MatchCard = (props: IProps) => {

	const [active, setActive] = React.useState<boolean>(false);
	const [active2, setActive2] = React.useState<boolean>(false);
	const shouldShowBusCriteria = props.matchDetails?.matchResultStatValuesDTOs?.filter(item => item?.isProduct === false)?.length ?? 0 > 0;

	const shouldShowProductCriteria = props.matchDetails?.matchResultStatValuesDTOs?.filter(item => item?.isProduct === true)?.length ?? 0 > 0;

	return (
		<React.Fragment>
			<div key={`match-${props.matchDetails?.id}`} className="card shadow rounded mt-3 p-3">
				<div className="card-body d-flex">
					<div className="flex-fill me-3">
						<div>
							<h3 className="mb-3">{props.matchDetails?.organizationName}</h3>
						</div>
						<p className="fst-italic text-gray-600 fs-16">Criteria Matched On</p>

						<div className="d-flex align-items-baseline">
							<div className="accordion accordion-flush border-top border-dark border-bottom w-100" id="accordionMatchProducts">
								{!!shouldShowProductCriteria &&
									<div className="accordion-item border-bottom-0">
										<h2 className="accordion-header  border-dark"
											id="match-details-products">
											<button
												className={classNames('accordion-button px-0', active === false && 'collapsed')}
												type="button"
												data-bs-toggle="collapse" data-bs-target="#match-details-products-content" aria-expanded="false" aria-controls="match-details-products-content"
												onClick={() => setActive(active => !active)}>
												<h6 className="mb-0">PRODUCT TYPES</h6>
												<SpecCountPill className="ms-auto me-n2"
													specMatchedCount={props.matchDetails?.productTypeMatchCount}
													specTotalCount={props.matchDetails?.productTypeMatchTotal}
													wording="Product Types"
												/>
											</button>
										</h2>
										<div id="match-details-products-content"
											className={classNames('accordion-collapse collapse', active === true && 'show')}
											aria-labelledby="match-details-products" data-bs-parent="#accordionMatchProducts">
											<div className="accordion-body p-0 border-top border-dark">
												{props.matchDetails?.matchResultStatValuesDTOs?.filter(item => item.isProduct === true)
													.map((requestProducts, productKey) => (
														<div className="border-bottom border-gray-200 py-3" key={productKey}>
															<p className={classNames('mb-0 d-flex align-items-center',
																requestProducts.matchCount && requestProducts.matchCount > 0 ? 'text-success-700' : 'text-gray-600')}>
																{requestProducts.matchCount && requestProducts.matchCount > 0 ?
																	(<IconCircleTick className="me-2" />)
																	:
																	('')
																}
																{requestProducts.typeName}
																{!requestProducts.noProductSpecsSpecified &&
																	<SpecCountPill className="ms-auto me-5"
																		specMatchedCount={requestProducts.matchCount}
																		specTotalCount={requestProducts.totalCount}
																	/>
																}
															</p>
														</div>
													))}
											</div>
										</div>
									</div>
								}
								{!!shouldShowBusCriteria &&
									<div className="accordion-item">
										<h2 className="accordion-header border-bottom border-top border-dark"
											id="match-details-business">
											<button
												className={classNames('accordion-button px-0', active2 === false && 'collapsed')}
												type="button"
												data-bs-toggle="collapse" data-bs-target="#match-details-business-content" aria-expanded="false" aria-controls="match-details-business-content"
												onClick={() => setActive2(active2 => !active2)}>
												<h6 className="mb-0">BUSINESS SCOPE CRITERIA</h6>
												<SpecCountPill className="ms-auto me-n2"
													specMatchedCount={props.matchDetails?.businessCriteriaCount}
													specTotalCount={props.matchDetails?.businessCriteriaTotalCount}
													wording="Criteria"
												/>
											</button>
										</h2>
										<div id="match-details-business-content"
											className={classNames('accordion-collapse collapse', active2 === true && 'show')}
											aria-labelledby="match-details-business" data-bs-parent="#accordionMatchProducts">
											<div className="accordion-body p-0">
												{props.matchDetails?.matchResultStatValuesDTOs?.filter(item => item.isProduct === false)
													.map((requestBusCriteria, busCriteriaKey) => (
														<div className="border-bottom border-gray-200 py-3" key={busCriteriaKey}>
															<p
																className={classNames('mb-0 d-flex align-items-center',
																	requestBusCriteria.matchCount && requestBusCriteria.matchCount > 0 ? 'text-success-700' : 'text-gray-600')}>
																{requestBusCriteria.matchCount && requestBusCriteria.matchCount > 0 ?
																	(<IconCircleTick className="me-2" />)
																	:
																	('')
																}
																{requestBusCriteria.typeName}
																<SpecCountPill className="ms-auto me-5"
																	specMatchedCount={requestBusCriteria.matchCount}
																	specTotalCount={requestBusCriteria.totalCount}
																/>
															</p>
														</div>
													))}
											</div>
										</div>
									</div>
								}
							</div>
						</div>
					</div>

					<div className="ms-auto d-flex flex-column">
						{props.matchDetails?.isApprovedMatch === true && (
							<span
								className="bg-primary px-2 py-1 rounded text-white mb-3 fs-14"
							>
								SAVED MATCH
								<IconSaved className="icon-saved ms-2" />
							</span>
						)
						}
						<Button
							to={`/requests/searchresults/${props.requestId}/matchdetails/${props.matchDetails?.organizationID}`}
							className="btn-outline-primary mb-3">View Details <IconArrowRight />
						</Button>
						<div className={classNames('py-3 px-4 d-flex flex-column justify-content-center align-items-center flex-grow-1 rounded', matchBGColorClass(props.matchDetails?.percentMatch))}>
							<h3 className={classNames('mb-1', matchColorClass(props.matchDetails?.percentMatch))}>{props.matchDetails?.percentMatch}%</h3>
							<h6 className={classNames('mb-0', matchColorClass(props.matchDetails?.percentMatch))}>
								MATCH
							</h6>
						</div>

					</div>
				</div>
			</div>
		</React.Fragment >
	);
};

export default MatchCard;