import React from 'react';
import { useNavigate } from 'react-router-dom';

interface RedirectProps {
	to: string;
}

const Redirect: React.FC<RedirectProps> = ({ to }) => {
	const navigate = useNavigate();

	React.useEffect(() => {
		navigate(to, { replace: true });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return null;
};

export default Redirect;
