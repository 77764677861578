import { IconArrowRight } from 'assets/icons';
import GaugeChart from 'components/core/GaugeChart';
import React from 'react';
// import Pill from 'screens/Requests/components/pills/Pill';

interface NewsCardProps {
	dateTime: string;
	location: string | null;
	source: {
		dataType: string;
		title: string;
		uri: string;
	};
	url: string;
	relevance: number;
	sentiment: number;
	title: string;
	body: string;
}

// relevance
const RELEVANCE_MAX_VALUE = 150;
// sentiment
const SENTIMENT_MIN_VALUE = -1;
const SENTIMENT_MAX_VALUE = 1;
// gauge
const GAUGE_CHART_SIZE = 100;
// body length
const BODY_LENGTH = 350;

function findSentimentText(sentiment: number | undefined) {
	if (!sentiment) {
		return undefined;
	}

	if (
		parseFloat(sentiment.toFixed(2)) > 0.25 &&
        parseFloat(sentiment.toFixed(2)) < 1) {
		return 'Positive';
	} else if (
		parseFloat(sentiment.toFixed(2)) > -1 &&
        parseFloat(sentiment.toFixed(2)) < -0.25
	) {
		return 'Negative';
	} else if  (
		parseFloat(sentiment.toFixed(2)) > -0.25 &&
        parseFloat(sentiment.toFixed(2)) < 0.25
	) {
		return 'Neutral';
	} else {
		return undefined;
	}
}

function findRelevanceText(relevance: number | undefined) {
	if (!relevance) {
		return undefined;
	}

	if (relevance < 30) {
		return 'Low';
	} else if (
		relevance > 30 && relevance < 60
	) {
		return 'Medium';
	} else if (
		relevance > 60 &&
        relevance < 100
	) {
		return 'High';
	} else if (relevance > 100) {
		return 'Very High';
	} else {
		return undefined;
	}
}

const NewsCard = ({
	dateTime,
	location,
	source,
	url,
	relevance,
	sentiment,
	title,
	body,
}: NewsCardProps) => {
	// Function to truncate the text to a specific length
	const truncateText = (text: string, maxLength: number) => {
		if (text.length > maxLength) {
			return text.slice(0, maxLength) + '...';
		}
		return text;
	};

	return (
		<div className="card mb-4 p-3 shadow-sm news-card">
			<div className="card-body">
				{/* Title */}
				<div className="row mt-2">
					<div className="col-12">
						<div className="h3">{title}</div>
					</div>
				</div>

				<div className="row mt-0 gx-2">
					{/* Date Posted */}
					<div className="col-4">
						<div className="date-location">
							{new Date(dateTime).toLocaleDateString()}{' '}
							{location ? `| ${location}` : ''}
						</div>
					</div>
				</div>

				<div className="row mt-0">
					{/* Relevance */}
					<div className="col-6">
						<GaugeChart
							title="Relevance"
							text={findRelevanceText(relevance)}
							value={relevance}
							valueMax={RELEVANCE_MAX_VALUE}
							width={GAUGE_CHART_SIZE}
							height={GAUGE_CHART_SIZE}
						/>
					</div>

					{/* Sentiment Gauge */}
					<div className="col-6">
						<GaugeChart
							title="Sentiment"
							text={findSentimentText(sentiment)}
							value={parseFloat(sentiment ? sentiment.toFixed(2) : '0')}
							valueMin={SENTIMENT_MIN_VALUE}
							valueMax={SENTIMENT_MAX_VALUE}
							width={GAUGE_CHART_SIZE}
							height={GAUGE_CHART_SIZE}
						/>
					</div>
				</div>

				{/* <div className="row mt-2">
					Tags
					<div className="col-12">
						<div className="d-flex flex-wrap">
							<Pill className="text-primary fw-bold">Tag1</Pill>
							{props?.orgCertifications && props.orgCertifications.length > 0 ? (
                                props.orgCertifications.map((standards, sckey) => (
                                    <Pill
                                        className="text-primary fw-bold"
                                        key={`cert-standard-${sckey}`}
                                    >
                                        {standards}
                                    </Pill>
                                ))
                            ) : (
                                <p className="text-gray-600 fst-italic">No certifications and standards specified</p>
                            )}
						</div>
					</div>
				</div> */}

				<div className="row mt-2">
					{/* Body */}
					<div className="col-12">
						{/* box bg-white  */}
						<div className="body">{truncateText(body, BODY_LENGTH)}</div>
					</div>
				</div>

				{/* Source and Open Button aligned at the bottom */}
				<div className="d-flex justify-content-between align-items-end mt-3">
					{/* Source */}
					<div className="source">
						{source ? `${source.title}` : 'Source Unknown'}
					</div>

					{/* Open Button */}
					<div>
						<a href={url} target="_blank" rel="noopener noreferrer">
							<span>Open</span> <IconArrowRight className="ms-2 align-top" />
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};

export default NewsCard;
