import React from 'react';
import { Button, LoadingPage, Page } from 'components/core';
import TitleBar from 'screens/Components/TitleBar';
import RequestDetails from './components/requestDetails/RequestDetails';
import { ReactComponent as IconCompanyType } from 'assets/icons/icon-company-type.svg';
import { ReactComponent as IconLocation } from 'assets/icons/icon-location.svg';
import { ReactComponent as IconSave } from 'assets/icons/icon-save.svg';
import { ReactComponent as IconSaved } from 'assets/icons/icon-saved.svg';
import { ReactComponent as IconWebsite } from 'assets/icons/icon-website.svg';
import classNames from 'classnames';
import { matchColorClass, matchBGColorClass } from '../../utils/utils';
import { RequestContext } from './RequestContext';
import ContactCard from './components/ContactCard';
import OrgLocationCard from './components/OrgLocationCard';
import OrgCertificationsCard from './components/OrgCertificationsCard';
import OrgCompanyValuesCard from './components/OrgCompanyValuesCard';
import OrgInsuranceRange from './components/OrgInsuranceRange';
import OrgProductCatalogCard from './components/OrgProductCatalogCard';
import ScopeMatchAPI from 'api/ScopeMatchAPI';
import { useParams } from 'react-router-dom';
import LinkedOrganizationCard from './components/LinkedOrganizationCard';
import { oppositeOrganizationName } from 'components/helperfunctions';
import SendEmailModal from './SendEmailModal';
import { useToast } from 'components/customHooks/useToast';

const MatchDetails = () => {
	const { statData, searchResults, resultPanel, toggleSaveMatch } = React.useContext(RequestContext);
	const percentMatch = statData?.percentMatch;
	const [isSaving, setIsSaving] = React.useState<boolean>(false);
	const [orgData, setOrgData] = React.useState<OrganizationDTO | null>(null);
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [showEmailModal, setShowEmailModal] = React.useState<boolean>(false);
	const params = useParams();
	const toast = useToast();
	const requestId = Number(params.requestID);
	const orgId = Number(params.orgID);

	const pageTitle = 'Match Details';

	React.useEffect(() => {
		async function getData() {
			if (orgId !== undefined && requestId !== undefined) {
				if (requestId && orgId) {
					setIsLoading(true);
					const orgResult = await ScopeMatchAPI.organization.GetSearchMatchOrganization(orgId, requestId);
					setIsLoading(false);
					setOrgData(orgResult);
				}
			}
		}
		getData();
	}, [orgId, requestId]);

	const handleSendEmailConfirmation = async () => {
		setShowEmailModal(true);
	};
	const cancelSendEmailConfirmation = async () => {
		setShowEmailModal(false);
		await handleSaveMatchToggle();
	};
	const sendEmailNotification = async () => {
		setShowEmailModal(false);
		ScopeMatchAPI.search.SendMatchNotification(requestId, orgId).then(response => {
			if (response !== undefined) {
				toast.success('Email notification sent');
				setOrgData(orgData => {
					return {
						...orgData,
						sendMatchNotificationDate: response ? response : undefined
					};
				});
			} else {
				toast.error('An error occured.  No email notification sent');
			}
		});
	};
	const handleSaveMatchToggle = async () => {
		setIsSaving(true);
		await toggleSaveMatch(requestId!, orgId!);
		setIsSaving(false);
	};
	const searchResultData = searchResults.find(y => y.organizationID === orgId);

	if (isLoading === true) {
		return <LoadingPage />;
	}
	return (
		<Page title={pageTitle}>
			<div className="d-flex matches-scrollable-container">
				<div className="d-flex flex-column w-100">
					<TitleBar
						pageTitle={pageTitle}
						backButton={true}
					/>
					<div className="container-fluid gx-0 d-flex ">

						<div className="matches-left-panel pb-7 mt-n3 border-end">
							<RequestDetails
								requestDetails={resultPanel}
								showHighlight={true}
								resultsPage={false}
								matchedResultData={orgData}
							/>
						</div>
						<div className="matches-right-panel px-4 mt-n3 pt-4 mb-7 pb-7">

							<div className="d-flex">
								<div className={classNames('py-3 px-4 me-3 mb-4 d-flex flex-column justify-content-center align-items-center rounded',
									matchBGColorClass(percentMatch))}>
									<h3 className={classNames('mb-0', matchColorClass(percentMatch))}>
										{percentMatch != null ? `${percentMatch}%` : ''}
									</h3>
									<h6 className={classNames('mb-0', matchColorClass(percentMatch))}>
										MATCH
									</h6>
								</div>
								<div className="mb-4 flex-fill">
									<div className="d-flex">
										<h2 className="mb-1">{orgData?.organizationName}</h2>
										<div className={classNames(orgData?.companyUrl ? 'ms-auto' : 'ms-3') + ' ms-auto'}>
											{searchResultData?.isApprovedMatch === true ? (
												<Button
													className="btn-primary"
													onClick={handleSaveMatchToggle}
													loading={isSaving}
												>
													Match Saved
													<IconSaved className="icon-saved ms-2" />
												</Button>
											) : (
												<Button
													onClick={handleSendEmailConfirmation}
													className="btn-outline-primary"
													loading={isSaving}
												>
													Save Match
													<IconSave className="icon-save ms-2" />
												</Button>)
											}
										</div>
									</div>
									<div className="d-flex flex-wrap">
										<p className="d-flex bg-light py-2 px-3 mb-0 text-capitalize align-items-center me-2 mt-2 rounded">
											<IconCompanyType className="me-2" />{orgData?.organizationType}</p>
										<p className="d-flex bg-light py-2 px-3 mb-0 text-capitalize align-items-center me-2 mt-2 rounded">
											<IconLocation className="me-2" />{orgData?.headOfficeLocation}</p>
										{orgData?.companyUrl &&
											<p className="d-flex bg-light py-2 px-3 mb-0 align-items-center mt-2 rounded">
												<IconWebsite className="me-2" />
												<a
													href={orgData.companyUrl.includes('https://') || orgData.companyUrl.includes('http://')
														? orgData.companyUrl
														: `https://${orgData.companyUrl}`}
													target="_blank"
													rel="noreferrer"
												>
													{orgData.companyUrl.replaceAll('https://', '').replaceAll('http://', '')}
												</a>
											</p>
										}
									</div>
								</div>

							</div>
							{searchResultData?.isApprovedMatch === true && orgData?.organizationContacts && orgData?.organizationContacts?.length > 0 && (
								<ContactCard
									orgContact={orgData.organizationContacts}
								/>
							)}

							<OrgLocationCard
								orgHeadOffice={orgData?.headOfficeLocation}
								orgShippingLocation={orgData?.shippingLocation}
								orgManufacturingLocation={orgData?.fullManufacturingLocations}
								orgType={orgData?.organizationType}
							/>
							<OrgProductCatalogCard
								orgProducts={orgData?.productSummaryList}
							/>
							<OrgCertificationsCard
								orgCertifications={orgData?.standardsAndCertification}
							/>
							<OrgCompanyValuesCard
								orgCompanyValues={orgData?.companyValues}
							/>
							<OrgInsuranceRange
								orgInsuranceRange={orgData?.insuranceRange}
							/>
							<LinkedOrganizationCard
								organizationList={orgData?.manufacturersWorkWith}
								orgType={orgData?.organizationType}
								title={oppositeOrganizationName(orgData?.organizationType) + 's Currently Working With'}
								showExplanation={true}
								orgName={orgData?.organizationName}
							/>
							{orgData?.organizationType !== 'manufacturer' &&
								<LinkedOrganizationCard
									organizationList={orgData?.ownersWorkWith}
									orgType={orgData?.organizationType}
									title={orgData?.organizationType === 'owner' ? 'Distributors Currently Working With' : 'Owners Currently Working With'}
									orgName={orgData?.organizationName}
								/>
							}
							<LinkedOrganizationCard
								organizationList={orgData?.approvedManufacturerList}
								orgType={orgData?.organizationType}
								title={`Approved Manufacturer${orgData?.organizationType === 'owner' ? 's' : ' Listings'}`}
								showExplanation={false}
								orgName={orgData?.organizationName}
								isAML={true}
							/>
						</div>
					</div>
				</div>
			</div>
			{showEmailModal && <SendEmailModal
				receiveOrganizationName={orgData?.organizationName}
				onRequestClose={cancelSendEmailConfirmation}
				sendEmail={sendEmailNotification}
			/>}
		</Page >

	);
};

export default MatchDetails;

