import React from 'react';
import { FormGroup, Page } from 'components/core';
import OnBoardingProgressBar from './components/ProgressBar';
import { Controller, useFormContext } from 'react-hook-form';
import { FormContext, LocationsType } from './FormContext';
import OnboardingFooter from './components/OnboardingFooter';
import { capitalizeFirstLetter, oppositeOrganizationType } from 'components/helperfunctions';
import { OrganizationSelect } from 'components/scopematch/OrganizationSelect';
import { useLocation } from 'react-router-dom';
import BackForwardButtons from './components/BackForwardButtons';

const WhoDoYouWorkWith =  () => {

	const { control, handleSubmit, getValues, setValue } = useFormContext<LocationsType>();
	const organizationType = getValues('organizationType');
	const location = useLocation();
	const FormCxt = React.useContext(FormContext);
	return (
		<Page title="Who Do You Work With?"
			className="onboarding-wrapper"
		>
			<div className="container-fluid scroll-container">
				<div className="row">
					<div className="col-lg-5 offset-lg-1">
						<OnBoardingProgressBar indexInfo={FormCxt.CurrentIndex(organizationType, location.pathname)} />
						<h2 className="pt-4 mb-2">What {oppositeOrganizationType(organizationType)}s do you work with?</h2>
						<p className="mb-2">Selecting companies you work with will help validate your company and provide credibility for potential matches </p>
						<p className="text-gray-700 fst-italic">You can skip this step and complete this later in your dashboard</p>
					</div>
					<div className="offset-1 col-lg-4 pt-5 mt-5">
						<Controller
							control={control}
							name="manufacturersWorkWith"
							render={({ field, fieldState }) =>
								<FormGroup
									label={
										`${capitalizeFirstLetter(oppositeOrganizationType(organizationType))}s`
									}
									field={field}
									fieldState={fieldState}
								>
									<OrganizationSelect
										id="standards-select"
										isMulti={true}
										organizationType={organizationType}
										showAddInstitution={true}
										name={field.name}
										setValue={setValue}
										value={field.value}
										onChange={field.onChange}
										placeholder=""
										buttonText={`Add ${oppositeOrganizationType(organizationType)}`}
									/>
								</FormGroup>
							}
						/>
					</div>
				</div>
			</div>

			<OnboardingFooter>
				<BackForwardButtons beforeContinuing={handleSubmit(beforeContinuing)} />
			</OnboardingFooter>
		</Page>
	);
	async function beforeContinuing(fields: LocationsType) {
		const { manufacturersWorkWith, ...otherFields } = fields;
		const newFields = { ...otherFields } as LocationsType;
		newFields.manufacturersWorkWith = manufacturersWorkWith?.map(x => { return { id: x as number }; });
		return true;
	}
};

export default WhoDoYouWorkWith;