import React, { useState, useEffect, FormEvent } from 'react';
import Autosuggest, { SuggestionsFetchRequestedParams, ChangeEvent as AutosuggestChangeEvent, SuggestionSelectedEventData, } from 'react-autosuggest';
import FlexSearch from 'flexsearch';
import { Input } from 'components/core';
import PickListContext from 'context/PickListContext';

interface MultiSelectAutocompleteProps extends React.InputHTMLAttributes<HTMLInputElement> {
	suggestions: SuggestionsType,
	error?: boolean;
	onChangeValue: (values: {
		productItems: {id: string; label: string; }[],
		speckListItems: string[],
		pickListItems: { id: string; label: string; }[]
	}) => void,
	onChangeInputValue: (value: string) => void
}

const MultiSelectAutocomplete: React.FC<MultiSelectAutocompleteProps> = ({ suggestions, id, name, onChangeValue, onChangeInputValue, error, disabled }: MultiSelectAutocompleteProps) => {
	const [category, setCategory] = useState<{id: string, label: string} | null>(null);
	const [value, setValue] = useState<string>('');
	const [selectedItems, setSelectedItems] = useState<{ itemId: string; label: string; typeId: string }[]>([]);
	const [autosuggestItems, setAutosuggestItems] = useState<string[]>([]);
	const [indexes, setIndexes] = useState<{ [key: string]: { index: FlexSearch.Index, documents: string[][], idMap: { [id: number]: [number, number] } } }>({});
	const [alwaysRenderSuggestions, setAlwaysRenderSuggestions] = useState(false);

	const PickListCxt = React.useContext(PickListContext);
	const categories = Object.keys(suggestions);

	const disabledWhenEnd = category !== null && suggestions[category.label].length === selectedItems.length;

	useEffect(() => {
		const indexes: { [key: string]: { index: FlexSearch.Index, documents: string[][], idMap: { [id: number]: [number, number] } } } = {};

		for (const category in suggestions) {
			const group = suggestions[category];
			const index = new FlexSearch.Index({ tokenize: 'forward' });
			const documents: string[][] = [];
			const idMap: { [id: number]: [number, number] } = {}; // To map ID to [level, position]
			let uniqueId = 0;

			group.forEach((items, level) => {
				documents[level] = [];
				items.forEach((item, j) => {
					const id = uniqueId++;  // Generate a unique numeric ID
					index.add(id, item);
					documents[level].push(item); // Store document for later retrieval
					idMap[id] = [level, j]; // Map the ID to its level and position
				});
			});

			indexes[category] = { index, documents, idMap };
		}

		setIndexes(indexes);
	}, [suggestions]);

	const fetchSuggestions = (inputValue: string) => {
		if (category === null) {
			const results = categories.filter(cat => cat.toLowerCase().includes(inputValue.toLowerCase()));
			setAutosuggestItems(results);
		} else {
			// Search within the selected category only
			const currentCategory = category.label; // Assuming `category` is an object with `label` as the category name

			if (indexes[currentCategory]) {
				const { index, documents, idMap } = indexes[currentCategory];
				const selectedCount = selectedItems.length;

				if (index) {
					if (inputValue !== '') {
						// Perform search in the specific category's index
						const searchResults = index.search(inputValue);

						// Filter the search results to ensure they only come from the selected category
						const results = searchResults
							.filter(id => idMap[id as number] && idMap[id as number][0] === selectedCount)  // Ensure correct level
							.map(id => {
								const [level, position] = idMap[id as number]; // Get level and position using idMap
								return documents[level][position]; // Retrieve the correct item
							});

						setAutosuggestItems(results);
					} else {
						// If input is empty, suggest all documents at the current level
						setAutosuggestItems(documents[selectedCount] || []);
					}
				} else {
					// If no index found, return empty results
					setAutosuggestItems([]);
				}
			} else {
				// If category does not exist in indexes
				setAutosuggestItems([]);
			}
		}
	  };

	const onSuggestionsFetchRequested = ({ value }: SuggestionsFetchRequestedParams) => {
		fetchSuggestions(value);
	};

	const onSuggestionsClearRequested = () => {
		setAutosuggestItems([]);
	};

	const getSuggestionValue = (suggestion: string) => suggestion;

	const renderSuggestion = (suggestion: string) => {
		return <div>
			{suggestion}
		</div>;
	};

	const onChange = (event: FormEvent<HTMLElement>, { newValue }: AutosuggestChangeEvent) => {
		setValue(newValue);
		onChangeInputValue(newValue);
	};

	const onSuggestionSelected = (event: React.FormEvent<any>, { suggestion }: SuggestionSelectedEventData<string>) => {
		const element = PickListCxt.pickListItems.find(x => x.code === suggestion);
		const elementType = PickListCxt.pickListTypes.find(x => x.id === element?.pickListTypeId);

		const idSuggestion = element?.id?.toString() ?? '';
		const idTypeSuggestion = elementType?.id?.toString() ?? '';

		if (category === null) {
			setCategory({id: idSuggestion, label: suggestion});
			setValue('');
			setAutosuggestItems([]);

			onChangeValue({
				productItems: [{ id: idSuggestion, label: suggestion }],
				speckListItems: [],
				pickListItems: []
			});
		} else if (!selectedItems.includes({itemId: idSuggestion, label: suggestion, typeId: idTypeSuggestion}) && selectedItems.length < suggestions[category.label].length) {
			setSelectedItems((selectedItems) => [...selectedItems, {itemId: idSuggestion, label: suggestion, typeId: idTypeSuggestion}]);
			setValue('');
			setAutosuggestItems([]);

			if (category !== null) {
				onChangeValue({
					productItems: [category],
					speckListItems: [...selectedItems.map(i => (i.typeId)), idTypeSuggestion],
					pickListItems: [...selectedItems.map(i => ({id: i.itemId, label: i.label})), {id: idSuggestion, label: suggestion}],
				});
			}
		}

		onChangeInputValue('');
		setAlwaysRenderSuggestions(false);
	};

	const renderInputComponent = (inputProps: any) => {
		return (
			<div>
		  		<Input key={id} {...inputProps} id={id} name={name} error={error} />
			</div>
		);};

	const onInputFocus = () => {
		if (value?.trim() === '') {
			setAlwaysRenderSuggestions(true);
			fetchSuggestions('');
		}
	};

	const onInputBlur = () => {
		setAlwaysRenderSuggestions(false);
	};

	const removeCategory = () => {
		setCategory(null);
		setSelectedItems([]);
		onChangeValue({
			productItems: [],
			speckListItems: [],
			pickListItems: [],
		});
	};

	const removeSpec = (item: string) => {
		const itemIndex: number = selectedItems.findIndex((i) => i.itemId === item);
		const filteredArray = selectedItems.filter((_, index) => index < itemIndex);
		setSelectedItems(filteredArray);
		if (category !== null) {
			onChangeValue({
				productItems: [category],
				speckListItems: filteredArray.map(i => i.typeId),
				pickListItems: filteredArray.map(i => ({id: i.itemId, label: i.label})),
			});
		}
	};

	let lastCategory: string[] = [];
	let lastCategoryName;
	if (category !== null) {
		lastCategory = indexes[category.label].documents[selectedItems.length];

		if (lastCategory) {
			const element = PickListCxt.pickListItems.find(x => x.code === lastCategory[lastCategory.length - 1]);
			const elementType = PickListCxt.pickListTypes.find(x => x.id === element?.pickListTypeId);
			lastCategoryName = elementType?.description;
		}
	}

	return (
		<div>
			<Autosuggest
				suggestions={autosuggestItems}
				onSuggestionsFetchRequested={onSuggestionsFetchRequested}
				onSuggestionsClearRequested={onSuggestionsClearRequested}
				getSuggestionValue={getSuggestionValue}
				renderSuggestion={renderSuggestion}
				inputProps={{
					placeholder: disabled ? 'Only one field required' : category === null ? 'Select a category...' : disabledWhenEnd ?
						'No more specs to choose' : 'Type to choose "' + lastCategoryName + '"',
					value: value,
					disabled: disabled || disabledWhenEnd,
					onChange: onChange,
					onFocus: onInputFocus,
          			onBlur: onInputBlur,
				}}
				onSuggestionSelected={onSuggestionSelected}
				alwaysRenderSuggestions={alwaysRenderSuggestions}
				focusInputOnSuggestionClick={false}
				renderInputComponent={renderInputComponent}
			/>
			{
				<div className="selected-items">
					{category && (<span className="selected-item">
						{category.label} <button onClick={removeCategory}>x</button>
					</span>)}
					{selectedItems.map(item => (
						<span key={item.itemId} className="selected-item">
							{item.label} <button onClick={() => removeSpec(item.itemId)}>x</button>
						</span>
					))}
				</div>
			}
		</div>
	);
};

export default MultiSelectAutocomplete;
