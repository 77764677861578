/* eslint-disable max-len */
import React from 'react';
import { ReactComponent as Logo } from 'assets/icons/scopematch-logo.svg';
import { ReactComponent as LogoCollapsed } from 'assets/icons/scopematch-collapsed-logo.svg';
import { ReactComponent as IconDashboard } from 'assets/icons/icon-dashboard.svg';
import { ReactComponent as IconCompanyProfile } from 'assets/icons/icon-company-profile.svg';
import { ReactComponent as IconMatches } from 'assets/icons/icon-matches.svg';
import { ReactComponent as IconRequests } from 'assets/icons/icon-requests.svg';
import { ReactComponent as IconSettings } from 'assets/icons/icon-settings.svg';
import { ReactComponent as IconDownload } from 'assets/icons/icon-download.svg';
import { ReactComponent as IconBookmark } from 'assets/icons/icon-bookmark.svg';
import { ReactComponent as IconBook } from 'assets/icons/icon-book.svg';
import { ReactComponent as IconFolderFill } from 'assets/icons/icon-folder-fill.svg';
import { ReactComponent as IconFolderSymlink } from 'assets/icons/icon-folder-symlink.svg';
import { ReactComponent as IconNews } from 'assets/icons/icon-news.svg';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { RequestContext } from 'screens/Requests/RequestContext';
import { useNavigate } from 'react-router-dom';
import { Button } from 'components/core';
import { Permissions, UserRole } from 'auth/Permissions';
import RequiresPermissions from 'context/RequiresPermissions';
import { usePTD } from 'context/PTDContext';
import { capitalize } from 'lodash';
import { IconArrowLeft, IconCardHeading, IconDistributer, IconPersonFillGear } from 'assets/icons';
import AuthContext from 'context/AuthContext';

interface IProps {
	sidebarExpanded: boolean;
}

const SideBar = (props: IProps) => {
	const location = useLocation();
	const { getAllPTDs, getSinglePTDList } = usePTD();
	const navigate = useNavigate();
	const { sidebarExpanded } = props;
	const pathname = location.pathname;
	const requestCxt = React.useContext(RequestContext);
	const { currentUser, currentUniqueId } = React.useContext(AuthContext);

	const url = pathname.split('/');
	const listNameIndex = url.findIndex((item) => item === 'myList' || item === 'comanagedList');
	let listName: string = '', idPTD: string = '', idPTDList: string = '';
	if (listNameIndex !== -1) {
		idPTD = url[listNameIndex - 1];
		listName = url[listNameIndex];
		idPTDList = url[listNameIndex + 1];
	}
	const PTDs = getAllPTDs();
	const ptdList = getSinglePTDList(Number(idPTDList), listName);

	const handleNavigate = (path: string) => {
		if (requestCxt.isRequestDirty) {
			const confirmed = window.confirm('Your changes to the request are not saved. Are you sure you want to navigate away?');
			if (confirmed) {
				navigate(path);
				requestCxt.setIsRequestDirty(false);
			}
		} else {
			navigate(path);
		}
	};

	const activeLinkSearch = () => {
		const topnav = [
			'company-profile',
			'requests',
			'match-history',
			'settings',
			'importdata',
			'manage-users',
			'organizationlist',
			'picklistproducttypes',
			'datavisualization',
			'premium-trade-directories',
			'news',
		];
		return topnav.find(value => url.includes(value)) || '';
	};
	const activeLink = activeLinkSearch();

	const submenuList = [
		{name: 'products', icon: <IconCardHeading />},
		{name: 'vendors', icon: <IconDistributer />},
	];

	if (currentUniqueId === ptdList?.creatorId) {
		submenuList.push({name: 'access', icon: <IconPersonFillGear />});
	}

	return (
		<React.Fragment>
			<div className={classNames('px-3 py-2 bg-light shadow-inset', sidebarExpanded === true && 'side-bar-collapsed' || sidebarExpanded === false && 'px-3 side-bar')}>
				<Link to="/" className={classNames('navbar-brand pt-3 pb-4 mt-2', sidebarExpanded === false && 'align-self-start')}>
					{sidebarExpanded ? <LogoCollapsed className="p-1" /> : <Logo className="p-3 logo-width-safari-only" />}
				</Link>
				<ul className={classNames('nav nav-pills flex-column mb-auto d-flex overflow-x-hidden')}>
					{
						(currentUser.roles?.indexOf(UserRole.ProfileWithPTDFeature) !== -1 ||
						currentUser.roles?.indexOf(UserRole.ProfileWithPTDAndRequestsFeatures) !== -1) && listName !== '' ?
							<React.Fragment>
								<SideBarButton
									title="Back"
									Icon={<IconArrowLeft />}
									onClick={() => handleNavigate(`/premium-trade-directories/${idPTD}`)}
									sidebarExpanded={sidebarExpanded}
									isActive={false}
								/>

								<SideBarWithSubmenu
									title={ptdList?.premiumTradeDirectoryName ?? 'List'}
									Icon={<IconFolderFill />}
									onClick={() => handleNavigate(`/premium-trade-directories/${idPTD}/${listName}/${idPTDList}`)}
									childrenLinks={submenuList.map(tab => ({
										name: capitalize(tab.name),
										to: `/premium-trade-directories/${tab.name}/${idPTD}/${listName}/${idPTDList}`,
										isActive: pathname === `/premium-trade-directories/${tab.name}/${idPTD}/${listName}/${idPTDList}`,
										Icon: tab.icon
									}))
									}
									onHandleNavigate={(to) => handleNavigate(to)}
									sidebarExpanded={sidebarExpanded}
									isActive={activeLink === 'premium-trade-directories'}
								/>
							</React.Fragment>
							 :
							 <React.Fragment>
								{
									(currentUser.roles?.indexOf(UserRole.ProfileOnly) !== -1 ||
									currentUser.roles?.indexOf(UserRole.ProfileWithRequestFeature) !== -1 ||
									currentUser.roles?.indexOf(UserRole.ProfileWithPTDFeature) !== -1 ||
									currentUser.roles?.indexOf(UserRole.ProfileWithPTDAndRequestsFeatures) !== -1) &&
										<React.Fragment>
											<SideBarButton
												title="Dashboard"
												Icon={<IconDashboard />}
												onClick={() => handleNavigate('/')}
												sidebarExpanded={sidebarExpanded}
												isActive={activeLink === ''}
											/>
											<SideBarButton
												title="Company Profile"
												Icon={<IconCompanyProfile />}
												onClick={() => handleNavigate('/company-profile')}
												sidebarExpanded={sidebarExpanded}
												isActive={activeLink === 'company-profile'}
											/>
										</React.Fragment>
								}
								<hr className={classNames('d-block my-2 text-primary p-0', sidebarExpanded && 'me-3')}/>
								<SideBarButton
									title="News"
									Icon={<IconNews />}
									onClick={() => handleNavigate('/news')}
									sidebarExpanded={sidebarExpanded}
									isActive={activeLink === 'news'}
								/>
								{
									(currentUser.roles?.indexOf(UserRole.ProfileWithRequestFeature) !== -1  ||
									currentUser.roles?.indexOf(UserRole.ProfileWithPTDAndRequestsFeatures) !== -1)  &&
									<React.Fragment>
										<hr className={classNames('d-block my-2 text-primary p-0', sidebarExpanded && 'me-3')}/>
										<SideBarButton
											title="Requests"
											Icon={<IconRequests />}
											onClick={() => handleNavigate('/requests')}
											sidebarExpanded={sidebarExpanded}
											isActive={activeLink === 'requests'}
										/>
										<SideBarButton
											title="Match History"
											Icon={<IconMatches />}
											onClick={() => handleNavigate('/match-history')}
											sidebarExpanded={sidebarExpanded}
											isActive={activeLink === 'match-history'}
										/>
									</React.Fragment>
								}
								{
									(currentUser.roles?.indexOf(UserRole.ProfileWithPTDFeature) !== -1 ||
									currentUser.roles?.indexOf(UserRole.ProfileWithPTDAndRequestsFeatures) !== -1) &&
									<React.Fragment>
										<hr className={classNames('d-block my-2 text-primary p-0', sidebarExpanded && 'me-3')}/>
										<SideBarWithSubmenu
											title="Vendors Lists"
											Icon={<IconFolderFill />}
											onClick={() => handleNavigate('/premium-trade-directories')}
											childrenLinks={PTDs.map(ptd => ({
												name: ptd.name,
												to: `/premium-trade-directories/${ptd.id}`,
												isActive: pathname === `/premium-trade-directories/${ptd.id}`,
												Icon: <IconFolderSymlink />
											}))}
											onHandleNavigate={(to) => handleNavigate(to)}
											sidebarExpanded={sidebarExpanded}
											isActive={activeLink === 'premium-trade-directories'}
										/>
									</React.Fragment>
								}
								<React.Fragment>
									<RequiresPermissions behaviour="hide" requiredPermissions={[Permissions.ReadTenant, Permissions.ReadPickList, Permissions.ReadRole]}>
										<hr className={classNames('d-block my-2 text-primary p-0', sidebarExpanded && 'me-3')}/>
										<SideBarButton
											title="Product Types"
											Icon={<IconBookmark />}
											onClick={() => handleNavigate('/admin/picklistproducttypes')}
											sidebarExpanded={sidebarExpanded}
											isActive={activeLink === 'picklistproducttypes'}
										/>
										<SideBarButton
											title="Import Data"
											Icon={<IconDownload />}
											onClick={() => handleNavigate('/admin/importdata')}
											sidebarExpanded={sidebarExpanded}
											isActive={activeLink === 'importdata'}
										/>
										<SideBarButton
											title="Manage Users"
											Icon={<IconSettings />}
											onClick={() => handleNavigate('/admin/manage-users')}
											sidebarExpanded={sidebarExpanded}
											isActive={activeLink === 'manage-users'}
										/>
										<SideBarButton
											title="Organization List"
											Icon={<IconBook />}
											onClick={() => handleNavigate('/admin/organizationlist')}
											sidebarExpanded={sidebarExpanded}
											isActive={activeLink === 'organizationlist'}
										/>
										<SideBarButton
											title="Data Visualization"
											Icon={<IconDashboard />}
											onClick={() => handleNavigate('/admin/datavisualization')}
											sidebarExpanded={sidebarExpanded}
											isActive={activeLink === 'datavisualization'}
										/>
									</RequiresPermissions>
								</React.Fragment>
							</React.Fragment>
					}
				</ul>
			</div>
		</React.Fragment>
	);
};

interface SideBarButtonProps {
	title: string;
	Icon: React.ReactElement;
	onClick: () => void;
	sidebarExpanded: boolean;
	isActive: boolean;
}

interface SideBarDropdownProps {
	title: string;
	Icon: React.ReactElement;
	onClick: () => void;
	sidebarExpanded: boolean;
	isActive: boolean;
	childrenLinks: {name: string, to: string, isActive: boolean, Icon: React.ReactElement}[];
	onHandleNavigate: (to: string) => void;
}

function SideBarButton(props: SideBarButtonProps) {
	const { title, Icon, onClick, isActive, sidebarExpanded } = props;
	return (
		<li className="flex-fill d-flex">
			<Button
				onClick={onClick}
				className={classNames('nav-link d-flex flex-fill', isActive && 'active')}>
				{React.cloneElement(Icon, { className: classNames('', sidebarExpanded === false && 'me-2', 'text-primary'), style: { width: 25 } })}
				<span>{!sidebarExpanded && title}</span>
			</Button>
		</li>
	);
}

function SideBarWithSubmenu(props: SideBarDropdownProps) {
	const { title, Icon, onClick, isActive, sidebarExpanded, childrenLinks, onHandleNavigate } = props;

	return (
		<li className="flex-fill d-flex flex-column submenu">
			<Button
				onClick={onClick}
				className={classNames('nav-link d-flex flex-fill align-self-stretch', isActive && 'active')}
				id="navbarDropdownMenuLink">
				{React.cloneElement(Icon, { className: classNames('', sidebarExpanded === false && 'me-2', 'text-primary'), style: { width: 25 } })}
				<span>{!sidebarExpanded && title}</span>
			</Button>

			<div className="submenu-sidebar mt-1" aria-labelledby="navbarDropdownMenuLink">
				{childrenLinks.map((child, key) => (
					<Button
						className={classNames('submenu-item my-1', child.isActive && 'active')}
						key={`sub-sidebar-item-${key}`}
						onClick={() => onHandleNavigate(child.to)}>
						{React.cloneElement(child.Icon, { className: classNames('', sidebarExpanded === false && 'me-2', 'text-primary'), style: { width: 25 } })}
						<span>{!sidebarExpanded && child.name}</span>
					</Button>
				))}
			</div>

		</li>
	);
}

export default SideBar;