
import React from 'react';
import ForceGraph from './ForceGraph';


// interface IstandardsCertifications {
// 	[index: number]: string;
// }


// interface IproductTypes {
// 	[index: number]: string;
// }

// interface ImanufacturingLocations {
// 	[index: number]: string;
// }

// // interface ImanufacturersWorkWith {
// // 	[index: number]: { connectionConfirmed: boolean; headOfficeLocation: string; productTypes: IproductTypes; name: string; manufacturingLocations: ImanufacturingLocations; type: string }
// // }


// // interface IownersFacilities {
// // 	[index: number]: { name: string, productTypes: IproductTypes }
// // }

// // interface IownersWorkWith {
// // 	[index: number]: { name: string; ownersFacilities: IownersFacilities }
// // }

// // interface IdistributionLocations {
// // 	[index: number]: string;
// // }

// interface Icompany {
// 	companyName: string;
// 	productTypes: IproductTypes;
// 	standardsCertifications: IstandardsCertifications;
// 	manufacturersWorkWith: ImanufacturersWorkWith;
// 	ownersWorkWith: IownersWorkWith;
// 	distributionLocations: IdistributionLocations;
// 	type: string;
// }

let chartMargin: any = { left: .10, right: .10, top: .10, bottom: .10 };

function setKeysOrder(companyType: string) {
	if (companyType === 'Distributor') {
		return {
			'manufacturersWorkWith': [
				{ 'productTypes': [] },
				{ 'name': [] },
				{ 'manufacturingLocations': [] }
			],
			'company': [
				{ 'companyName': [] },
				{ 'distributionLocations': [] }
			]
		};
	} else if (companyType === 'Manufacturer') {
		return {
			'company': [
				{ 'productTypes': [] },
				{ 'name': [] },
				{ 'exportLocations': [] }
			],
			'distributorsWorkWith': [ // distributorsWorkWith
				{ 'headOfficeLocation': [] },
				{ 'name': [] },
				{ 'distributionLocations': [] }, // distributionLocations
				{ 'productTypes': [] }
			]
		};
	} else if (companyType === 'Owner') {
		return {
			'manufacturersWorkWith': [
				{ 'productTypes': [] },
				{ 'name': [] },
				{ 'manufacturingLocations': [] }
			],
			'company': [
				{ 'companyName': [] },
				{ 'facilitiesLocation': [] }, // Plants locations
				{ 'companyName': [] }
			],
			'distributorsWorkWith': [
				{ 'name': [] },
				{ 'distributionLocations': [] },
				{ 'productTypes': [] }
			]
		};
	}
}

type Props = {
	data: any;
	chartheight?: number;
	chartwidth?: number;
}
// eslint-disable-next-line react/prop-types
function ForceGraphInit({ data, chartheight = 0, chartwidth = 0 }: Props) {
	const [nodes, setNodes] = React.useState<any>([]);
	const [links, setLinks] = React.useState<any>([]);
	const forcedata = data;
	const keysorder = setKeysOrder(data.company.type) as any;

	React.useEffect(() => {
		// assigning the response data `toDoItem` directly to `myNewToDo` variable which is
		// of Todo type
		interface Isub {
			[index: number]: {}
		}

		interface Inode {
			id: string;
			fx: number;
			fy: number;
			main: string;
			sub: Isub;
			ix: number;
			iy: number;
			radius: number;
		}
		const node: Array<Inode> = [];
		interface Ilink {
			source: string;
			target: string;
		}
		const link: Array<Ilink> = [];
		let counter = 0;
		Object.keys(forcedata).forEach((keydata: any) => {
			Object.keys(forcedata[keydata]).forEach((subkeydata: any) => {
				let keys = Object.keys(keysorder).join(',') + ',';
				if (keys.indexOf(subkeydata) > -1) {
					forcedata[keydata][subkeydata].forEach((maindata: any) => {
						let keyfound: boolean = false;
						Object.keys(maindata).forEach((mainkeydata: any) => {
							keysorder[subkeydata].forEach((itemkey: any, index: number) => {
								if (mainkeydata === Object.keys(itemkey)[0]) {
									keyfound = true;
									if (typeof maindata[mainkeydata] === 'object') {
										maindata[mainkeydata].forEach((item: any) => {
											let duplicate: boolean = false;
											keysorder[subkeydata][index][Object.keys(itemkey)[0]].forEach((values: any) => {
												if (values.value === item) {
													duplicate = true;
												}
											});
											if (!duplicate) {
												keysorder[subkeydata][index][Object.keys(itemkey)[0]].push({ id: `n_${counter}`, value: item, main: subkeydata, sub: itemkey });
												counter++;
											}
										});
									} else {
										if (typeof maindata[mainkeydata] === 'boolean') {
											if (maindata[mainkeydata]) {
												let itemvalue: string = 'Yes';
												let duplicate: boolean = false;
												keysorder[subkeydata][index][Object.keys(itemkey)[0]].forEach((values: any) => {
													if (values.value === itemvalue) {
														duplicate = true;
													}
												});
												if (!duplicate) {
													keysorder[subkeydata][index][Object.keys(itemkey)[0]].push({ id: `n_${counter}`, value: itemvalue, main: subkeydata, sub: itemkey });
													counter++;
												}
											} else {
												let itemvalue: string = 'No';
												let duplicate: boolean = false;
												keysorder[subkeydata][index][Object.keys(itemkey)[0]].forEach((values: any) => {
													if (values.value === itemvalue) {
														duplicate = true;
													}
												});
												if (!duplicate) {
													keysorder[subkeydata][index][Object.keys(itemkey)[0]].push({ id: `n_${counter}`, value: itemvalue, main: subkeydata, sub: itemkey });
													counter++;
												}
											}
										} else {
											let duplicate: boolean = false;
											let itemvalue: any = maindata[mainkeydata];
											keysorder[subkeydata][index][Object.keys(itemkey)[0]].forEach((values: any) => {
												if (values.value === itemvalue) {
													duplicate = true;
												}
											});
											if (!duplicate) {
												keysorder[subkeydata][index][Object.keys(itemkey)[0]].push({ id: `n_${counter}`, value: itemvalue, main: subkeydata, sub: itemkey });
												counter++;
											}
										}
									}
								}
							});
						});
						if (!keyfound) {

						}
					});
				}
			});
		});
		Object.keys(keysorder).forEach((mainkey: any) => {
			keysorder[mainkey].forEach((subkey: any, index: number) => {
				let mkey = Object.keys(keysorder[mainkey][index])[0];
				let repdata: any = null;
				let sourceid = '';
				keysorder[mainkey][index][Object.keys(keysorder[mainkey][index])[0]].forEach((ikey: any, icounter: any) => {
					let value = keysorder[mainkey][index][Object.keys(keysorder[mainkey][index])[0]][icounter].value;
					sourceid = keysorder[mainkey][index][Object.keys(keysorder[mainkey][index])[0]][icounter].id;
					Object.keys(forcedata).forEach((keydata: any) => {
						Object.keys(forcedata[keydata]).forEach((subkeydata: any) => {
							let keys = Object.keys(keysorder).join(',') + ',';
							if (keys.indexOf(subkeydata + ',') > -1) {
								forcedata[keydata][subkeydata].forEach((skey: any) => {
									if (skey[mkey]) {
										if (typeof skey[mkey] === 'object') {
											skey[mkey].forEach((checkkey: any) => {
												if (value === checkkey) {
													repdata = skey;
													let nindex = index + 1;
													if (nindex < keysorder[mainkey].length && repdata) {
														let keyval = Object.keys(keysorder[mainkey][nindex])[0];
														let ndata = keysorder[mainkey][nindex][keyval];
														ndata.forEach((newdata: any) => {
															Object.keys(newdata.sub).forEach((ikey: any) => {
																let finaldata = repdata[ikey];
																newdata.sub[ikey].forEach((zval: any) => {
																	let lvalue = zval.value;
																	if (typeof finaldata === 'object') {
																		finaldata.forEach((fdata: any) => {
																			if (fdata === lvalue) {
																				let duplicate = false;
																				link.forEach((lnk: any) => {
																					if (lnk.source === sourceid && lnk.target === zval.id) {
																						duplicate = true;
																					}
																				});
																				if (!duplicate) {
																					link.push({ source: sourceid, target: zval.id });
																				}
																				// group.push(zval.id);
																			}
																		});
																	} else {
																		if (typeof finaldata === 'boolean') {
																			let fidata = 'Yes';
																			if (!finaldata) {
																				fidata = 'No';
																			}
																			if (fidata === lvalue) {
																				let duplicate = false;
																				link.forEach((lnk: any) => {
																					if (lnk.source === sourceid && lnk.target === zval.id) {
																						duplicate = true;
																					}
																				});
																				if (!duplicate) {
																					link.push({ source: sourceid, target: zval.id });
																				}
																				//group.push(zval.id);
																			}
																		} else {
																			if (finaldata === lvalue) {
																				let duplicate = false;
																				link.forEach((lnk: any) => {
																					if (lnk.source === sourceid && lnk.target === zval.id) {
																						duplicate = true;
																					}
																				});
																				if (!duplicate) {
																					link.push({ source: sourceid, target: zval.id });
																				}
																				//group.push(zval.id);
																			}
																		}
																	}
																});
															});
														});
													}
												}
											});
										} else {
											if (typeof skey[mkey] === 'boolean') {
												let value1 = 'Yes';
												if (!skey[mkey]) {
													value1 = 'No';
												}
												if (value1 === value) {
													repdata = skey;
													let nindex = index + 1;
													if (nindex < keysorder[mainkey].length && repdata) {
														let keyval = Object.keys(keysorder[mainkey][nindex])[0];
														let ndata = keysorder[mainkey][nindex][keyval];
														ndata.forEach((newdata: any) => {
															Object.keys(newdata.sub).forEach((ikey: any) => {
																let finaldata = repdata[ikey];
																newdata.sub[ikey].forEach((zval: any) => {
																	let lvalue = zval.value;
																	if (typeof finaldata === 'object') {
																		finaldata.forEach((fdata: any) => {
																			if (fdata === lvalue) {
																				let duplicate = false;
																				link.forEach((lnk: any) => {
																					if (lnk.source === sourceid && lnk.target === zval.id) {
																						duplicate = true;
																					}
																				});
																				if (!duplicate) {
																					link.push({ source: sourceid, target: zval.id });
																				}
																				// group.push(zval.id);
																			}
																		});
																	} else {
																		if (typeof finaldata === 'boolean') {
																			let fidata = 'Yes';
																			if (!finaldata) {
																				fidata = 'No';
																			}
																			if (fidata === lvalue) {
																				let duplicate = false;
																				link.forEach((lnk: any) => {
																					if (lnk.source === sourceid && lnk.target === zval.id) {
																						duplicate = true;
																					}
																				});
																				if (!duplicate) {
																					link.push({ source: sourceid, target: zval.id });
																				}
																				//group.push(zval.id);
																			}
																		} else {
																			if (finaldata === lvalue) {
																				let duplicate = false;
																				link.forEach((lnk: any) => {
																					if (lnk.source === sourceid && lnk.target === zval.id) {
																						duplicate = true;
																					}
																				});
																				if (!duplicate) {
																					link.push({ source: sourceid, target: zval.id });
																				}
																				//group.push(zval.id);
																			}
																		}
																	}
																});
															});
														});
													}
												}
											} else {
												if (skey[mkey] === value) {
													repdata = skey;
													let nindex = index + 1;
													if (nindex < keysorder[mainkey].length && repdata) {
														let keyval = Object.keys(keysorder[mainkey][nindex])[0];
														let ndata = keysorder[mainkey][nindex][keyval];
														ndata.forEach((newdata: any) => {
															Object.keys(newdata.sub).forEach((ikey: any) => {
																let finaldata = repdata[ikey];
																newdata.sub[ikey].forEach((zval: any) => {
																	let lvalue = zval.value;
																	if (typeof finaldata === 'object') {
																		finaldata.forEach((fdata: any) => {
																			if (fdata === lvalue) {
																				let duplicate = false;
																				link.forEach((lnk: any) => {
																					if (lnk.source === sourceid && lnk.target === zval.id) {
																						duplicate = true;
																					}
																				});
																				if (!duplicate) {
																					link.push({ source: sourceid, target: zval.id });
																				}
																				// group.push(zval.id);
																			}
																		});
																	} else {
																		if (typeof finaldata === 'boolean') {
																			let fidata = 'Yes';
																			if (!finaldata) {
																				fidata = 'No';
																			}
																			if (fidata === lvalue) {
																				let duplicate = false;
																				link.forEach((lnk: any) => {
																					if (lnk.source === sourceid && lnk.target === zval.id) {
																						duplicate = true;
																					}
																				});
																				if (!duplicate) {
																					link.push({ source: sourceid, target: zval.id });
																				}
																				//group.push(zval.id);
																			}
																		} else {
																			if (finaldata === lvalue) {
																				let duplicate = false;
																				link.forEach((lnk: any) => {
																					if (lnk.source === sourceid && lnk.target === zval.id) {
																						duplicate = true;
																					}
																				});
																				if (!duplicate) {
																					link.push({ source: sourceid, target: zval.id });
																				}
																				//group.push(zval.id);
																			}
																		}
																	}
																});
															});
														});
													}

												}
											}
										}
									}
								});
							}
						});
					});
				});
			});
		});

		let maxitems: number = 0;
		let totalkeys: number = 0;
		Object.keys(keysorder).forEach((mainkey: any) => {
			keysorder[mainkey].forEach((subkey: any) => {
				totalkeys++;
				Object.keys(subkey).forEach((key: any) => {
					if (maxitems < subkey[key].length) {
						maxitems = subkey[key].length;
					}
				});
			});
		});
		let rad: number = 5;  // /  (chartheight > chartwidth?chartheight:chartwidth)

		let radii = rad;
		let perwidth: number = chartwidth / totalkeys;
		let startX: number = chartMargin.left;

		Object.keys(keysorder).forEach((mainkey: any) => {
			keysorder[mainkey].forEach((subkey: any) => {
				Object.keys(subkey).forEach((key: any) => {
					let gap: number = 0;
					let centerpoint: number = 0;
					let cpoint: number = (chartheight / 2);    //+ (chartheight * chartMargin.top);
					let above: number = cpoint;
					let below: number = cpoint;
					let ix = chartMargin.left;
					let iy = cpoint;
					if (subkey[key].length === 1) {
						//                 y = chartMargin.top+(chartheight/2) - (radii/2);
						centerpoint = 0;
						gap = 0;
					}
					if (subkey[key].length === 2) {
						//                 y = chartMargin.top + (chartheight/4);
						gap = (chartheight / 6);
						centerpoint = .5;
					}
					if (subkey[key].length > 2) {
						gap = (chartheight / (subkey[key].length * 2)) + rad;
						centerpoint = (subkey[key].length - 1) / 2;
					}
					keysorder[mainkey].forEach((item: any, index: number) => {
						if (keysorder[mainkey][index][key]) {
							keysorder[mainkey][index][key].forEach((_subitem: AnalyserNode, subindex: number) => {
								if (subindex < centerpoint) {
									above -= gap;
									keysorder[mainkey][index][key][subindex].fx = startX;
									keysorder[mainkey][index][key][subindex].fy = above;
									node.push({
										id: keysorder[mainkey][index][key][subindex].id,
										fx: keysorder[mainkey][index][key][subindex].fx,
										fy: keysorder[mainkey][index][key][subindex].fy,
										main: keysorder[mainkey][index][key][subindex].main,
										sub: keysorder[mainkey][index][key][subindex].sub,
										radius: radii,
										ix: ix,
										iy: iy
									});
								}
								if (subindex > centerpoint) {
									below += gap;
									keysorder[mainkey][index][key][subindex].fx = startX;
									keysorder[mainkey][index][key][subindex].fy = below;
									node.push({
										id: keysorder[mainkey][index][key][subindex].id,
										fx: keysorder[mainkey][index][key][subindex].fx,
										fy: keysorder[mainkey][index][key][subindex].fy,
										main: keysorder[mainkey][index][key][subindex].main,
										sub: keysorder[mainkey][index][key][subindex].sub,
										radius: radii,
										ix: ix,
										iy: iy
									});
								}
								if (subindex === centerpoint) {
									keysorder[mainkey][index][key][subindex].fx = startX;
									keysorder[mainkey][index][key][subindex].fy = cpoint;
									node.push({
										id: keysorder[mainkey][index][key][subindex].id,
										fx: keysorder[mainkey][index][key][subindex].fx,
										fy: keysorder[mainkey][index][key][subindex].fy,
										main: keysorder[mainkey][index][key][subindex].main,
										sub: keysorder[mainkey][index][key][subindex].sub, radius: radii, ix: ix, iy: iy
									});
								}
							});
						}
					});
				});
				startX += perwidth;
			});
		});
		setNodes(node);
		setLinks(link);
	},
	// eslint-disable-next-line react-hooks/exhaustive-deps
	[forcedata]);

	return (
		<React.Fragment>
			<div id="forcechart" className="d-flex w-100 h-100">
				<ForceGraph initZoom={0} zoomTo={0} nodes={nodes} links={links} chartwidth={chartwidth} chartheight={chartheight} />
			</div>
		</React.Fragment>
	);
};
export default ForceGraphInit;