import React from 'react';
import { IconButton, Button } from 'components/core';
import { IconChevronRight, IconEdit, IconDuplicate } from 'assets/icons';
import { ReactComponent as IconDelete } from 'assets/icons/icon-delete.svg';
import { ReactComponent as IconDownload } from 'assets/icons/icon-download.svg';

type actionButtonType = 'delete' | 'open' | 'edit' | 'duplicate' | 'download';

interface IActionIconProps {
	title: string;
	onClick?: any;
	type: actionButtonType;
	disabled?: boolean;
	className?: string;
}

export const ClearFilterButton = ({ onClick }: { onClick: any }) => (
	<Button className="btn btn-outline-primary" onClick={onClick}>Clear</Button>
);

export const ActionIcon = ({ title, onClick, type, disabled, className }: IActionIconProps) => {
	if (disabled === undefined) {
		disabled = false;
	}
	if (type === 'delete') {
		return (
			<IconButton
				className={`${className} btn-link fs-12`}
				title={title}
				onClick={onClick}
				disabled={disabled}
			>
				<IconDelete className="svg-20" />
			</IconButton>
		);
	} else if (type === 'open') {
		return (
			<IconButton
				className={`${className} px-0 pr-2 btn-link fs-12`}
				title={title}
				onClick={onClick}
				disabled={disabled}
			>
				<IconChevronRight className="svg-20" />
			</IconButton>
		);
	} else if (type === 'download') {
		return (
			<IconButton
				className={`${className} btn-link fs-12`}
				title={title}
				onClick={onClick}
				disabled={disabled}
			>
				<IconDownload className="svg-20" />
			</IconButton>
		);
	} else if (type === 'edit') {
		return (
			<IconButton
				className={`${className} btn-link fs-12`}
				title={title}
				onClick={onClick}
				disabled={disabled}
			>
				<IconEdit className="svg-20" />
			</IconButton>
		);
	} else if (type === 'duplicate') {
		return (
			<IconButton
				className={`${className} btn-link fs-12`}
				title={title}
				onClick={onClick}
				disabled={disabled}
			>
				<IconDuplicate className="svg-20" />
			</IconButton>
		);
	} else {
		return null;
	}
};
