/* eslint-disable react/prop-types */
import React from 'react';

const ToolTip = ({hoveredBubbleData, tipLeft,tipTop}) => {
	const styles = {left:`${tipLeft}px`,top:`${tipTop}px`};
	console.log('hoveredBubbleData',hoveredBubbleData);
	return (
		<React.Fragment>
			<div className="Tooltip" style={styles}>
				<table>
					<tbody>
						{Object.keys(hoveredBubbleData).map((hkey)=>{
							console.log('hkey',hoveredBubbleData[hkey]);
							return (
								<tr key = {`m_${hoveredBubbleData[hkey]}`}>
									<td style={{width:'25%'}} key = {`m_${hoveredBubbleData[hkey]}_1`} colSpan={1}>{hkey}</td>
									<td style = {{width:'75%',fontWeight:'bolder'}} key = {`m_${hoveredBubbleData[hkey]}_2`} colSpan={1}>
										<label style = {{marginLeft:'15px'}}>{hoveredBubbleData[hkey]}</label>
									</td>
								</tr>
							);})
						}
					</tbody>
				</table>
			</div>
		</React.Fragment>
	);
};

export default ToolTip;