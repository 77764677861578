import classNames from 'classnames';
import React from 'react';
import { ReactComponent as IconEdit } from 'assets/icons/icon-edit.svg';
import { Button } from 'components/core';
import SquarePill from '../pills/SquarePill';
import { capitalizeFirstLetter, oppositeOrganizationType } from 'components/helperfunctions';
import BusinessScopeLocations from './BusinessScopeLocations';
import BusinessScopeValues from './BusinessScopeValues';
import ProductCriteriaAccordion from './ProductCriteriaAccordion';

interface IProps {
	requestDetails: MatchRequestDetailDTO | null;
	showEdit?: boolean;
	showHighlight?: boolean;
	resultsPage?: boolean;
	matchedResultData?: OrganizationDTO | null;
}

const RequestDetails = (props: IProps) => {

	const { requestDetails } = props;
	const hasReceivingLocation = Boolean(requestDetails?.receivingLocation?.length);
	const hasManufacturingLocation = Boolean(requestDetails?.manufacturingLocation?.length);
	const hasInsuranceRange = requestDetails?.insuranceRange !== undefined;
	const hasCorporateValues = Boolean(requestDetails?.corporateValues?.length);
	const hasStandardsAndCertification = Boolean(requestDetails?.standardsAndCertification?.length);
	const shouldDisplayBusinessScopeCriteria = hasReceivingLocation || hasManufacturingLocation || hasInsuranceRange || hasCorporateValues || hasStandardsAndCertification;

	const checkMatchInsurance = (insurance: any) => {
		return props.matchedResultData?.insuranceRange?.includes(insurance);
	};

	return (
		<React.Fragment>
			{props.requestDetails !== null && (
				<React.Fragment>
					<div className="bg-light px-3 pt-4 pb-3 border-bottom border-primary-200 sticky-top z-index-1">
						<div className="d-flex">
							<div className="mx-2">
								<h2 className="mb-0">Request Details</h2>
							</div>
							{props.showEdit === true &&
								<Button className="btn-outline-primary ms-auto flex-start"
									to={`/requests/${props.requestDetails?.id}`}>
									Edit<IconEdit className="ms-2" />
								</Button>}
						</div>
						<div className="mx-2">
							<h5 className="mb-0">
								{props?.requestDetails?.requestName ? props.requestDetails.requestName : 'Untitled'}
							</h5>
							{props?.requestDetails?.requestDescription !== '' && (
								<p className="mb-2">{props.requestDetails?.requestDescription}</p>
							)}
							<p className="text-primary mb-0 fs-16">Date Created: {props?.requestDetails?.requestDate && new Date(props?.requestDetails?.requestDate)
								.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}</p>
						</div>
					</div>
					<div className="p-3 my-2">
						<h4 className="mb-2">Company Type</h4>
						<p className="mb-4">{capitalizeFirstLetter(oppositeOrganizationType(props?.requestDetails?.organizationType))}s</p>

						{props?.requestDetails?.matchRequestProducts && props.requestDetails.matchRequestProducts.length > 0 ? (
							<ProductCriteriaAccordion
								requestProducts={props?.requestDetails?.matchRequestProducts}
								resultsPage={props.resultsPage}
								showHighlight={props.showHighlight}
							/>
						) : (
							<div>
								<h4 className="mb-2">Product Criteria</h4>
								<p className="text-gray-700 fst-italic mb-0">No product criteria specified</p>
							</div>
						)
						}

						<h4 className="mt-2 pt-3">Business Scope Criteria</h4>
						{shouldDisplayBusinessScopeCriteria === false && (
							<p className="text-gray-700 fst-italic mb-0 pb-5">No business scope criteria specified</p>
						)}
						{hasReceivingLocation && (
							<BusinessScopeLocations
								id={requestDetails?.id}
								resultLocations={props.matchedResultData?.shippingLocation}
								requestLocations={props?.requestDetails?.receivingLocation}
								resultsPage={props.resultsPage}
							/>
						)}

						{hasManufacturingLocation && (
							<BusinessScopeLocations
								id={requestDetails?.id}
								resultLocations={props.matchedResultData?.manufacturingLocation}
								requestLocations={props?.requestDetails?.manufacturingLocation}
								orgType={props?.requestDetails.organizationType?.toString()}
								resultsPage={props.resultsPage}
							/>
						)}

						{hasInsuranceRange && (
							<div>
								<p className={classNames('mb-1 fw-bold', !checkMatchInsurance(props?.requestDetails?.insuranceRange) && props.resultsPage === false && 'text-gray-600')}
								>Insurance Range</p>
								{checkMatchInsurance(props?.requestDetails?.insuranceRange) ? (
									<SquarePill>
										{props?.requestDetails?.insuranceRange}
									</SquarePill>

								) : (
									<p className={classNames('mb-3', props.resultsPage === false && 'text-gray-600')}>
										{props?.requestDetails?.insuranceRange}
									</p>
								)}
								<hr />
							</div>
						)}

						{hasCorporateValues && (
							<BusinessScopeValues
								id={props?.requestDetails?.id}
								title="Company Values"
								resultDetails={props.matchedResultData?.companyValues}
								requestDetails={props.requestDetails.corporateValues}
								resultsPage={props.resultsPage}
							/>
						)}

						{hasStandardsAndCertification && (
							<BusinessScopeValues
								id={props?.requestDetails?.id}
								title="Business Standards and Certifications"
								resultDetails={props.matchedResultData?.standardsAndCertification}
								requestDetails={props.requestDetails.standardsAndCertification}
								resultsPage={props.resultsPage}
							/>
						)}
					</div>
				</React.Fragment>
			)
			}
		</React.Fragment >
	);
};

export default RequestDetails;


