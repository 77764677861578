import React from 'react';
import { ReactComponent as IconCertification } from 'assets/icons/icon-certification.svg';
import Pill from './pills/Pill';


interface IProps {
	orgCertifications?: string[];
}

const OrgCertificationsCard = (props: IProps) => {
	if (props.orgCertifications == undefined || props.orgCertifications.length == 0) {
		return <React.Fragment />;
	}

	return (
		<div className="card shadow p-3 mb-2 rounded">
			<div className="d-flex align-items-center">
				<div className="rounded-circle bg-secondary-100 p-2 me-3">
					<IconCertification />
				</div>
				<h5 className="mb-0">Certifications and Standards</h5>
			</div>
			<hr />
			<div className="d-flex flex-wrap">
				{props?.orgCertifications && props.orgCertifications.length > 0 ? (
					props.orgCertifications.map((standards, sckey) => (
						<Pill
							className="text-primary fw-bold"
							key={`cert-standard-${sckey}`}
						>
							{standards}
						</Pill>
					))
				) : (
					<p className="text-gray-600 fst-italic">No certifications and standards specified</p>
				)}
			</div>
		</div>

	);
};

export default OrgCertificationsCard;