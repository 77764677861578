import React from 'react';
import { ErrorMessage, Page } from 'components/core';
import OnBoardingProgressBar from './components/ProgressBar';
import CompanyType from './components/CompanyType';
import { Controller, useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { OnboardingErrorMsg, preventWindowClose } from './utils';
import OnboardingFooter from './components/OnboardingFooter';
import OnboardingContainer from './components/OnboardingContainer';
import { FormContext } from './FormContext';
import BackForwardButtons from './components/BackForwardButtons';

const WhatCompanyAreYou = () => {
	const FormCxt = React.useContext(FormContext);
	const location = useLocation();
	const { control, formState, setValue, watch } = useFormContext<OrganizationRequest>();
	const organizationType = watch('organizationType');
	React.useEffect(() => {
		window.addEventListener('beforeunload', preventWindowClose);
	}, []);

	return (
		<Page
			title="What Type Of Company Are You?"
			className="onboarding-wrapper"
		>
			<OnboardingContainer>
				<OnBoardingProgressBar indexInfo={FormCxt.CurrentIndex(organizationType, location.pathname)} />
				<div className="pt-4 mb-2">
					<h2>What type of company are you?</h2>
					<p className="fst-italic text-gray-700">This cannot be changed later</p>
					<ErrorMessage errorMessage={formState.errors.organizationType?.message} />
				</div>
				<Controller
					control={control}
					name="organizationType"
					rules={{ required: OnboardingErrorMsg.CompanyType }}
					render={({ field }) =>
						<CompanyType value={field.value}
							onChange={(event) => {
								setValue('organizationType', field.value);
								field.onChange(event);
							}}
						/>
					}
				/>
			</OnboardingContainer>
			<OnboardingFooter>
				<BackForwardButtons />
			</OnboardingFooter>
		</Page>
	);
};



export default WhatCompanyAreYou;
