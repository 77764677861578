import React from 'react';
import classNames from 'classnames';
import { ReactComponent as IconAdd } from 'assets/icons/icon-add.svg';
import { ReactComponent as IconEdit } from 'assets/icons/icon-edit.svg';
import { DataTable, Button } from 'components/core';


interface ProductTypeTableProps {
	data: any;
	columns: any[];
	disabled: boolean;
	onClickAddSpecCategory?: () => void;
	addSpecTitle: string;
	onClickEditSpec?: () => void;
	productTypeName?: string;
	emptyTableTitle?: string;
	maxEntries?: number;
}

const SpecificationTable: React.FC<ProductTypeTableProps> = ({
	data,
	columns,
	disabled,
	onClickAddSpecCategory,
	addSpecTitle,
	onClickEditSpec,
	productTypeName,
	emptyTableTitle,
	maxEntries,
}) => {
	return (
		<div className={classNames('mb-4')}>
			<div className="bg-light border-bottom border-primary-200 px-3 py-1 d-flex align-items-center rounded-top custom-shadow">
				<h4 className="text-dark mb-0">{productTypeName}</h4>
				{onClickAddSpecCategory &&
					<Button
						onClick={onClickAddSpecCategory}
						disabled={disabled || (maxEntries !== undefined && data.length >= maxEntries)}
						className="ms-auto text-primary"
					>
						<IconAdd className="me-2" />{addSpecTitle}
					</Button>
				}

				{onClickEditSpec && <Button
					onClick={onClickEditSpec}
					disabled={disabled || (data && data.length === 0)}
					className=" text-primary"
				>
					<IconEdit className="me-2" />Edit Specs
				</Button>
				}
			</div>

			<DataTable
				columns={columns}
				data={data}
				sortable={false}
				noDataText={emptyTableTitle}
				showPagination={false}
				resizable={true}
			/>
		</div>
	);
};

export default SpecificationTable;