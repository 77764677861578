import { ModalOverlay } from 'components/core';
import React from 'react';

interface IProps {
	isOpen: boolean;
	isLoading: boolean;
	idPTDProduct: number | undefined;
	idPTDVendor: number | undefined;
	onRequestClose: () => void;
	deletePTDVendor: (idPTDProduct: number, idPTDVendor: number) => Promise<boolean>;
}

function DeletePTDVendorModal(props: IProps) {
	const { isOpen, isLoading, idPTDVendor, idPTDProduct, onRequestClose, deletePTDVendor } = props;

	async function onDeletePTDVendor() {
		if (idPTDVendor !== undefined && idPTDProduct !== undefined) {
			await deletePTDVendor(idPTDProduct, idPTDVendor);
			onRequestClose();
		}
	}

	return (
		<div>
			<ModalOverlay
				isOpen={isOpen}
				modalSize="modal-dialog-centered"
				onRequestClose={onRequestClose}
				confirmButtonChildren="Delete"
				cancelButtonChildren="Cancel"
				confirmButtonAction={onDeletePTDVendor}
				cancelButtonAction={onRequestClose}
				isLoading={isLoading}
				hideCloseButton={true}
			>
				<h3>Are you sure you want to delete this vendor?</h3>
				<p>This action cannot be undone.</p>
			</ModalOverlay>
		</div>
	);
}

export default DeletePTDVendorModal;
