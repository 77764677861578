import React from 'react';
interface IProps {

}
export const Text = (props: React.PropsWithChildren<IProps>) => {
	return (
		<span>
			{props.children}
		</span>
	);
};