import { Page } from 'components/core';
import React from 'react';
import { Link } from 'react-router-dom';
import TitleBar from 'screens/Components/TitleBar';



const ExportData = () => {
	const pageTitle = 'Export Company Profile';

	return (
		<Page title={pageTitle}>
			<div className="d-flex">
				<div className="d-flex flex-column w-100">
					<TitleBar
						pageTitle={pageTitle}
					/>
				</div>
			</div>
			<div className="container-fluid mb-5 px-5 py-3">
				<div className="row">
					<div className="col-10">
						<p>
							Use this form to export company profile information, such as name, contacts, institutions, etc.
						</p>
						<p>You can make changes to the JSON data file and import the file to update the database</p>
						<Link className="btn btn-primary" to="/organization.json" target="_blank" download={true}>Download Company Profile</Link>
					</div>
				</div>
			</div>
		</Page >
	);

};

export default ExportData;