import { ModalOverlay } from 'components/core';
import React from 'react';

interface IProps {
	isOpen: boolean;
	idAccessUser: number | undefined,
	onRequestClose: () => void;
	deleteAccessUser: (idAccessUser: number) => Promise<boolean>;
}

function DeleteAccessUserModal(props: IProps) {
	const { isOpen, idAccessUser, onRequestClose, deleteAccessUser } = props;
	const [isLoading, setIsLoading] = React.useState<boolean>(false);

	async function onDeleteAccessUser() {
		if (idAccessUser !== undefined) {
			setIsLoading(true);
			await deleteAccessUser(idAccessUser);
			setIsLoading(false);
			onRequestClose();
		}
	}

	return (
		<div>
			<ModalOverlay
				isOpen={isOpen}
				modalSize="modal-dialog-centered"
				onRequestClose={onRequestClose}
				confirmButtonChildren="Delete"
				cancelButtonChildren="Cancel"
				confirmButtonAction={onDeleteAccessUser}
				cancelButtonAction={onRequestClose}
				isLoading={isLoading}
				hideCloseButton={true}
			>
				<h3>Are you sure you want to delete this access?</h3>
				<p>This action cannot be undone.</p>
			</ModalOverlay>
		</div>
	);
}

export default DeleteAccessUserModal;
