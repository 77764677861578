import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';
import AccordionButton from './AccordionButton';
import AccordionBody from './AccordionBody';

interface IProps {
	requestProducts: ProductMatchTypeGroupDTO[] | undefined;
	resultsPage: boolean | undefined;
	showHighlight: boolean | undefined;
}

const ProductCriteriaAccordion: React.FC<PropsWithChildren<IProps>> = (
	props
) => {
	const [activeIndex, setActiveIndex] = React.useState<number>(-1);
	const [activeIDs, setActiveIDs] = React.useState([] as number[]);
	const toggleAccordion = (index: number, id: number) => {
		setActiveIndex(index);

		if (activeIDs.includes(id)) {
			setActiveIDs((x) => x.filter((activeID) => activeID !== id));
		} else {
			setActiveIDs((y) => [...y, id]);
		}
	};



	return (
		<div>
			<h4 className="mb-2 pb-3">Product Criteria</h4>
			<div
				className="accordion accordion-flush border-top border-bottom z-index-0 position-relative"
				id="accordionProductCriteria"
			>
				{props.requestProducts &&
					props.requestProducts.map((requestProducts, productkey) => (
						<div className="accordion-item" key={`prodtype-${productkey}`}>
							<div
								className="accordion-header"
								id={`flush-heading-${productkey}`}
							>
								<AccordionButton
									activeIndex={activeIndex}
									productkey={productkey}
									activeIDs={activeIDs}
									requestProducts={requestProducts}
									resultsPage={props.resultsPage}
									onClick={toggleAccordion}
								/>
							</div>
							<div
								id={`flush-collapse-${productkey}`}
								className={classNames(
									'accordion-collapse collapse',
									activeIDs.includes(productkey) && 'show'
								)}
								aria-labelledby={`flush-heading-${productkey}`}
								data-bs-parent="#accordionProductCriteria"
							>
								<AccordionBody
									group={requestProducts}
									resultsPage={props.resultsPage}
									showHighlight={props.showHighlight}
								/>
							</div>
						</div>
					))}
			</div>
		</div>
	);
};

export default ProductCriteriaAccordion;
