import React from 'react';
import { FormGroup, Page } from 'components/core';
import OnBoardingProgressBar from './components/ProgressBar';
import { Controller, useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import OnboardingFooter from './components/OnboardingFooter';
import OnboardingContainer from './components/OnboardingContainer';
import { FormContext } from './FormContext';
import BackForwardButtons from './components/BackForwardButtons';
import AsyncSelect from 'react-select/async';
import scopeMatchAPI from 'api/ScopeMatchAPI';

const LocationOfOffice = () => {
	const FormCxt = React.useContext(FormContext);
	const location = useLocation();
	const { control, getValues } = useFormContext<OrganizationRequest>();
	const organizationType = getValues('organizationType');
	const fetchLocationSuggestions = async (inputValue: string) => {

		if (inputValue.length <= 2) {
			return [];
		}

		try {
			const response = await scopeMatchAPI.locationSuggestions.GetLocationSuggestions(inputValue);
			return response.map((suggestion: { location: string; }) => ({
				label: suggestion.location,
				value: suggestion.location
			}));
		} catch (error) {
			console.error(error);
			return [];
		}
	};

	return (
		<Page
			title="Location of Head Office"
			className="onboarding-wrapper"
		>
			<OnboardingContainer>
				<OnBoardingProgressBar indexInfo={FormCxt.CurrentIndex(organizationType, location.pathname)} />
				<h2 className="pt-4 mb-2">Location of head office</h2>
				<p className="">This information will appear in your company profile</p>
				<Controller
					control={control}
					name="headOfficeLocation"
					rules={{ required: 'Head office city is required' }}
					render={({ field, fieldState }) => (
						<FormGroup
							label="Head office location"
							field={field}
							fieldState={fieldState}
						>
							<span className="fw-normal fst-italic text-gray-700">
								(Enter city or closest city, add all that apply)</span>
							<AsyncSelect
								id="office-select"
								name={field.name}
								value={{ label: field.value, value: field.value }}
								loadOptions={fetchLocationSuggestions}
								onChange={(selectedOption) => {
									field.onChange(selectedOption ? selectedOption.value : '');
								}}
								placeholder="Please start typing your location..."
								isClearable={true}
							/>
						</FormGroup>
					)}
				/>
			</OnboardingContainer>
			<OnboardingFooter>
				<BackForwardButtons />
			</OnboardingFooter>
		</Page>
	);
};

export default LocationOfOffice;
