import { ModalOverlay } from 'components/core';
import React, { useEffect, useRef } from 'react';
import { toast } from 'react-toastify';

interface IProps {
	isOpen: boolean;
	idPTDProduct: number | undefined;
	onRequestClose: () => void;
	deleteProduct: (idPTDProduct: number) => Promise<boolean>;
}

function DeleteProductModal(props: IProps) {
	const isMounted = useRef(true);
	const { isOpen, idPTDProduct, onRequestClose, deleteProduct } = props;
	const [isLoading, setIsLoading] = React.useState<boolean>(false);

	async function onDeleteProduct() {
		if (idPTDProduct !== undefined) {
			setIsLoading(true);
			await deleteProduct(idPTDProduct);

			if (isMounted.current) {
				setIsLoading(false);
				onRequestClose();
			}
		} else {
			toast.error('Somthing went wrong. Product id not found.');
		}
	}

	useEffect(() => {
		isMounted.current = true;
		return () => { isMounted.current = false; };
	}, []);

	return (
		<div>
			<ModalOverlay
				isOpen={isOpen}
				modalSize="modal-dialog-centered"
				onRequestClose={onRequestClose}
				confirmButtonChildren="Delete"
				cancelButtonChildren="Cancel"
				confirmButtonAction={onDeleteProduct}
				cancelButtonAction={onRequestClose}
				isLoading={isLoading}
				hideCloseButton={true}
			>
				<h3>Are you sure you want to delete this product?</h3>
				<p>This action cannot be undone.</p>
			</ModalOverlay>
		</div>
	);
}

export default DeleteProductModal;
