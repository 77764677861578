
import axios, { AxiosError } from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;


const UploadFilesService = {
	async upload(file: any) {
		let formData = new FormData();
		formData.append('file', file);
		var response = axios.post(`${apiUrl}/importdata`, formData, {})
			.then((data: { data: OrganizationImportResultsDTO[]; }) => {
				return data.data;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return null;
			});
		return response;
	},
};
export default UploadFilesService;