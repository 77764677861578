import classNames from 'classnames';
import React from 'react';

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
}

const OnboardingFooter = (props: IProps) => {
	const { className, children, ...otherProps } = props;
	return (
		<div className={classNames('container-fluid py-3 shadow bg-white', className)} {...otherProps}>
			<div className="row">
				<div className="col-lg-10 offset-lg-1 d-flex">
					{children}
				</div>
			</div>
		</div>
	);
};
export default OnboardingFooter;
