import React from 'react';
import AuthContext from 'context/AuthContext';
import { hasAnyRequiredPermission } from 'auth/Permissions';
import FourOhFourPage from 'screens/404Page';

interface IProps {
	requiredRoles: string[];
	behaviour?: '404' | 'hide';
}

const RequiresRole = (props: React.PropsWithChildren<IProps>) => {
	const AuthCxt = React.useContext(AuthContext);
	const { currentUser } = AuthCxt;
	if (hasAnyRequiredPermission(currentUser.roles!, props.requiredRoles)) {
		return <React.Fragment>{props.children}</React.Fragment>;
	}
	else if (props.behaviour === 'hide') {
		return <React.Fragment />;
	}

	return <FourOhFourPage />;
};

export default RequiresRole;
