import React from 'react';
import { Button, LoadingPage, Page } from 'components/core';
import TitleBar from 'screens/Components/TitleBar';
import scopeMatchAPI from 'api/ScopeMatchAPI';
import { ReactComponent as IconEdit } from 'assets/icons/icon-edit.svg';
import { ReactComponent as IconCompanyType } from 'assets/icons/icon-company-type.svg';
import { ReactComponent as IconLocation } from 'assets/icons/icon-location.svg';
import { ReactComponent as IconWebsite } from 'assets/icons/icon-website.svg';

import ContactCard from 'screens/Requests/components/ContactCard';
import OrgLocationCard from 'screens/Requests/components/OrgLocationCard';
import OrgCertificationsCard from 'screens/Requests/components/OrgCertificationsCard';
import OrgCompanyValuesCard from 'screens/Requests/components/OrgCompanyValuesCard';
import OrgInsuranceRange from 'screens/Requests/components/OrgInsuranceRange';
import OrgProductCatalogCard from 'screens/Requests/components/OrgProductCatalogCard';
import LinkedOrganizationCard from 'screens/Requests/components/LinkedOrganizationCard';
import { oppositeOrganizationName } from 'components/helperfunctions';
import OrgFacilitiesCard from 'screens/Requests/components/OrgFacilitiesCard';


const CompanyProfile = () => {
	const [orgDetails, setOrgDetails] = React.useState<OrganizationDTO | null>();
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const pageTitle = 'Company Profile';

	const orgHeadOffice = [
		orgDetails?.headOfficeLocation,
		orgDetails?.headOfficeLocationCountry
	]
		.filter(part => part !== undefined && part !== null && part !== '')
		.join(', ');


	React.useEffect(() => {
		setIsLoading(true);
		const fetchOrgDetails = async () => {
			const orgDetailsResults = await scopeMatchAPI.organization.GetCompanyProfile();
			setOrgDetails(orgDetailsResults);
			setIsLoading(false);
		};
		fetchOrgDetails();
	}, []);

	if (isLoading === true) {
		return <LoadingPage />;
	}

	return (
		<Page title={pageTitle}>
			<div className="d-flex">
				<div className="d-flex flex-column w-100">
					<TitleBar
						pageTitle={pageTitle}
					/>
				</div>
			</div>
			<div className="container-fluid mb-5 px-5 py-3">
				<div className="d-flex">
					<div className="mb-4">
						<h3 className="">{orgDetails?.organizationName}</h3>
						<div className="d-flex flex-wrap">
							<p className="d-flex bg-light py-2 px-3 mb-0 mt-2 text-capitalize align-items-center me-2 rounded">
								<IconCompanyType className="me-2" />{orgDetails?.organizationType}</p>
							<p className="d-flex bg-light py-2 px-3 mb-0 mt-2 text-capitalize align-items-center me-2 rounded">
								<IconLocation className="me-2" />{orgHeadOffice}</p>
							{orgDetails?.companyUrl &&
								<p className="d-flex bg-light py-2 px-3 mb-0 mt-2 align-items-center rounded">
									<IconWebsite className="me-2" />
									<a
										href={orgDetails.companyUrl.includes('https://') || orgDetails.companyUrl.includes('http://')
											? orgDetails.companyUrl
											: `https://${orgDetails.companyUrl}`}
										target="_blank"
										rel="noreferrer"
									>
										{orgDetails.companyUrl.replaceAll('https://', '').replaceAll('http://', '')}
									</a>
								</p>
							}
						</div>
					</div>
					<div className="ms-auto">
						<Button to="/company-profile/update-profile" className="btn-primary">Update Profile <IconEdit className="ms-2" /></Button>
					</div>
				</div>
				<ContactCard
					orgContact={orgDetails?.organizationContacts}
				/>
				<OrgFacilitiesCard
					orgFacilities={orgDetails?.organizationFacilities}
				/>
				<OrgLocationCard
					orgHeadOffice={orgHeadOffice}
					orgShippingLocation={orgDetails?.shippingLocation}
					orgManufacturingLocation={orgDetails?.fullManufacturingLocations}
					orgType={orgDetails?.organizationType}
					companyProfile={true}
				/>
				{orgDetails?.organizationType !== 'owner' &&
					<OrgProductCatalogCard
						orgProducts={orgDetails?.productSummaryList}
						showButton={true}
					/>
				}
				<OrgCertificationsCard
					orgCertifications={orgDetails?.standardsAndCertification}
				/>
				<OrgCompanyValuesCard
					orgCompanyValues={orgDetails?.companyValues}
				/>
				<OrgInsuranceRange
					orgInsuranceRange={orgDetails?.insuranceRange}
				/>
				<LinkedOrganizationCard
					organizationList={orgDetails?.manufacturersWorkWith}
					orgType={orgDetails?.organizationType}
					title={oppositeOrganizationName(orgDetails?.organizationType) + 's Currently Working With'}
					orgName={orgDetails?.organizationName}
				/>
				{orgDetails?.organizationType !== 'manufacturer' &&
					<LinkedOrganizationCard
						organizationList={orgDetails?.ownersWorkWith}
						orgType={orgDetails?.organizationType}
						title={orgDetails?.organizationType === 'owner' ? 'Distributors Currently Working With' : 'Owners Currently Working With'}
						orgName={orgDetails?.organizationName}
					/>
				}
				<LinkedOrganizationCard
					organizationList={orgDetails?.approvedManufacturerList}
					orgType={orgDetails?.organizationType}
					title={`Approved Manufacturer${orgDetails?.organizationType === 'owner' ? 's' : ' Listings (AML)'}`}
					showExplanation={false}
					isAML={true}
					orgName={orgDetails?.organizationName}
				/>
			</div>
		</Page >
	);
};

export default CompanyProfile;

