import React from 'react';
import { FormGroup, Page } from 'components/core';
import OnBoardingProgressBar from './components/ProgressBar';
import { Controller, useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { PickListSelect } from 'components/scopematch/PickListSelect';
import OnboardingFooter from './components/OnboardingFooter';
import OnboardingContainer from './components/OnboardingContainer';
import { FormContext } from './FormContext';
import BackForwardButtons from './components/BackForwardButtons';

const AdditionDetails = () => {

	const { control, getValues } = useFormContext<OrganizationRequest>();
	const location = useLocation();
	const FormCxt = React.useContext(FormContext);
	const organizationType = getValues('organizationType');

	return (
		<Page
			title="Additional Details"
			className="onboarding-wrapper"
		>
			<OnboardingContainer>
				<OnBoardingProgressBar indexInfo={FormCxt.CurrentIndex(organizationType, location.pathname)} />
				<h2 className="pt-4 mb-2">Additional details</h2>
				<p className="mb-2">This information will help match your company to requests with specific requirements</p>
				<p className="text-gray-700 fst-italic mb-4">You can skip this step and complete this later in the dashboard</p>
				<Controller
					control={control}
					name="insuranceRange"
					render={({ field, fieldState }) =>
						<FormGroup label="Insurance range" field={field} fieldState={fieldState}>
							<PickListSelect
								id="insurance-select"
								name={field.name}
								value={field.value}
								pickListTypeName="Insurance Ranges"
								onChange={field.onChange}
								placeholder=""
								orderBy={{
									order: 'id',
									orderBy: 'asc'
								}}
							/>
						</FormGroup>
					}
				/>
				<Controller
					control={control}
					name="standardsAndCertification"
					render={({ field, fieldState }) =>
						<FormGroup label="Business Standards and Certifications" field={field} fieldState={fieldState}>
							<span className="fw-normal fst-italic text-gray-700">
								(select all that apply)</span>
							<PickListSelect
								id="standards-select"
								isMulti={true}
								name={field.name}
								value={field.value}
								pickListTypeName="Standards and Certifications"
								onChange={field.onChange}
								placeholder=""
							/>
						</FormGroup>
					}
				/>
			</OnboardingContainer>
			<OnboardingFooter>
				<BackForwardButtons />
			</OnboardingFooter>

		</Page>
	);
};

export default AdditionDetails;
