import React from 'react';
import classNames from 'classnames';

interface IProps {
	className?: string;
}

const LoadingPage = ( props: React.PropsWithChildren<IProps> ) => {
	return (
		<div className="d-flex flex-grow-1 align-items-center justify-content-center loading-page">
			<div className={classNames('loader-container ball-chasing', props.className)}>
				<div className="loader">
					<div className="ball-1"></div>
					<div className="ball-2"></div>
				</div>
			</div>
			{props.children}
		</div>
	);
};

export default LoadingPage;