import React from 'react';
import AuthContext from 'context/AuthContext';
import FourOhFourPage from 'screens/404Page';
import { useParams } from 'react-router-dom';
import { usePTD } from 'context/PTDContext';

interface IProps {
	behaviour?: '404' | 'hide';
}

const RequiresCreator = (props: React.PropsWithChildren<IProps>) => {
	const params = useParams();
	const idPTDList = Number(params.idPTDList);
	const listName = params.listName;

	const AuthCxt = React.useContext(AuthContext);
	const { currentUniqueId } = AuthCxt;
	const {getSinglePTDList} = usePTD();
	const PTDList: PremiumTradeDirectory | undefined = getSinglePTDList(idPTDList, listName);

	if (currentUniqueId === PTDList?.creatorId) {
		return <React.Fragment>{props.children}</React.Fragment>;
	}
	else if (props.behaviour === 'hide') {
		return <React.Fragment />;
	}

	return <FourOhFourPage />;
};

export default RequiresCreator;
