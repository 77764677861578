
import ScopeMatchAPI from 'api/ScopeMatchAPI';
import { Button, FormGroup, Page } from 'components/core';
import { OrganizationSelect } from 'components/scopematch/OrganizationSelect';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import TitleBar from 'screens/Components/TitleBar';

interface ILoading {
	nodeLoading: boolean;
	searchLoading: boolean;
	savedLoading: boolean;
	chartLoading: boolean;
}

const ExportVisualization = () => {
	const pageTitle = 'Export data visualization';
	const nodeForm = useForm<any>({ reValidateMode: 'onChange' });
	const searchForm = useForm<any>({ reValidateMode: 'onChange' });
	const savedForm = useForm<any>({ reValidateMode: 'onChange' });
	const [loading, setLoading] = useState<ILoading>(
		{
			nodeLoading: false,
			chartLoading: false,
			searchLoading: false,
			savedLoading: false
		}
	);
	return (
		<Page title={pageTitle}>
			<div className="d-flex">
				<div className="d-flex flex-column w-100">
					<TitleBar
						pageTitle={pageTitle}
					/>
				</div>
			</div>
			<div className="container-fluid mb-5 mx-4">
				<div className="row">
					<div className="col-10">
						<h5>Node connections + Map</h5>
						<p>Export an Organization and the organizations they work with</p>
						<Controller
							control={nodeForm.control}
							name="Organizations"
							rules={{ required: 'Organization is required' }}
							render={({ field, fieldState }) =>
								<FormGroup label="Select Organization" field={field} fieldState={fieldState}>
									<OrganizationSelect
										isMulti={false}
										organizationType={undefined}
										showAddInstitution={false}
										name={field.name}
										setValue={nodeForm.setValue}
										value={field.value}
										onChange={field.onChange}
										placeholder=""
										buttonText="Add"
									/>
								</FormGroup>
							}
						/>
						<Button loading={loading.nodeLoading} className="btn-primary" onClick={nodeForm.handleSubmit(downloadNodeConnections)}>Download Node Connections </Button>
					</div>
				</div>
				<div className="row mt-4">
					<div className="col-10">
						<h5>Data visualization for Dashboard</h5>
						<p>Export a JSON data file for the dashboard visualization</p>
						<Controller
							control={nodeForm.control}
							name="Organizations"
							rules={{ required: 'Organization is required' }}
							render={({ field, fieldState }) =>
								<FormGroup label="Select Organization" field={field} fieldState={fieldState}>
									<OrganizationSelect
										isMulti={false}
										organizationType={undefined}
										showAddInstitution={false}
										name={field.name}
										setValue={nodeForm.setValue}
										value={field.value}
										onChange={field.onChange}
										placeholder=""
										buttonText="Add"
									/>
								</FormGroup>
							}
						/>
						<Button loading={loading.chartLoading} className="btn-primary" onClick={nodeForm.handleSubmit(downloadNodeVisualization)}>Download Node Visualization </Button>
					</div>
				</div>
				<div className="row mt-4">
					<div className="col-10">
						<h5>Search Request</h5>
						<p>List of all search results for selected Organization</p>
						<Controller
							control={searchForm.control}
							name="Organizations"
							rules={{ required: 'Organization is required' }}
							render={({ field, fieldState }) =>
								<FormGroup label="Select Organization" field={field} fieldState={fieldState}>
									<OrganizationSelect
										isMulti={false}
										organizationType={undefined}
										showAddInstitution={false}
										name={field.name}
										setValue={searchForm.setValue}
										value={field.value}
										onChange={field.onChange}
										placeholder=""
										buttonText="Add"
									/>
								</FormGroup>
							}
						/>
						<Button loading={loading.searchLoading} className="btn-primary" onClick={searchForm.handleSubmit(downloadSearchResults)}>Download Search Results </Button>
					</div>
				</div>
				<div className="row mt-4">
					<div className="col-10">
						<h5>Saved Matches</h5>
						<p>A list of saved matches from search results</p>
						<Controller
							control={savedForm.control}
							name="Organizations"
							rules={{ required: 'Organization is required' }}
							render={({ field, fieldState }) =>
								<FormGroup label="Select Organization" field={field} fieldState={fieldState}>
									<OrganizationSelect
										isMulti={false}
										organizationType={undefined}
										showAddInstitution={false}
										name={field.name}
										setValue={searchForm.setValue}
										value={field.value}
										onChange={field.onChange}
										placeholder=""
										buttonText="Add"
									/>
								</FormGroup>
							}
						/>
						<Button loading={loading.savedLoading} className="btn-primary" onClick={savedForm.handleSubmit(downloadSavedMatches)}>Download Saved Matches </Button>
					</div>
				</div>
			</div>
		</Page >
	);
	async function downloadNodeConnections(fields: any) {
		setLoading(prevLoading => ({ ...prevLoading, nodeLoading: true }));
		await ScopeMatchAPI.dataVisualization.GetNodeConnections(fields.Organizations);
		setLoading(prevLoading => ({ ...prevLoading, nodeLoading: false }));
	}
	async function downloadNodeVisualization(fields: any) {
		setLoading(prevLoading => ({ ...prevLoading, chartLoading: true }));
		await ScopeMatchAPI.dataVisualization.GetNodeVisualization(fields.Organizations);
		setLoading(prevLoading => ({ ...prevLoading, chartLoading: false }));
	}
	async function downloadSearchResults(fields: any) {
		setLoading(prevLoading => ({ ...prevLoading, searchLoading: true }));
		await ScopeMatchAPI.dataVisualization.GetSearchResults(fields.Organizations);
		setLoading(prevLoading => ({ ...prevLoading, searchLoading: false }));
	}
	async function downloadSavedMatches(fields: any) {
		setLoading(prevLoading => ({ ...prevLoading, savedLoading: true }));
		await ScopeMatchAPI.dataVisualization.GetSavedSearchMatches(fields.Organizations);
		setLoading(prevLoading => ({ ...prevLoading, savedLoading: false }));
	}
};

export default ExportVisualization;