import scopeMatchAPI from 'api/ScopeMatchAPI';
import { LoadingPage } from 'components/core';
import { useToast } from 'components/customHooks/useToast';
import PickListContext from 'context/PickListContext';
import React from 'react';
import { FormProvider as RhfFormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';


interface IOnboardingFlow {
	organizationType: OrganizationTypeEnum,
	flow: IPageIndex[],
}

const onboardingFlow: IOnboardingFlow[] = [
	{
		organizationType: 'distributor',
		flow: [
			{ pageName: '/what-company-are-you', hasSkipButton: false, hasFinishButton: false, hasBackButton: false },
			{ pageName: '/which-industries-and-sectors', hasSkipButton: false, hasFinishButton: false, hasBackButton: true },
			{ pageName: '/contact-information', hasSkipButton: false, hasFinishButton: false, hasBackButton: true },
			{ pageName: '/location-of-office', hasSkipButton: false, hasFinishButton: false, hasBackButton: true },
			{ pageName: '/locations', hasSkipButton: true, hasFinishButton: false, hasBackButton: true },
			{ pageName: '/additional-details', hasSkipButton: true, hasFinishButton: false, hasBackButton: true },
			{ pageName: '/company-values', hasSkipButton: false, hasFinishButton: true, hasBackButton: true },

		]
	},
	{
		organizationType: 'manufacturer',
		flow: [
			{ pageName: '/what-company-are-you', hasSkipButton: false, hasFinishButton: false, hasBackButton: false },
			{ pageName: '/which-industries-and-sectors', hasSkipButton: false, hasFinishButton: false, hasBackButton: true },
			{ pageName: '/contact-information', hasSkipButton: false, hasFinishButton: false, hasBackButton: true },
			{ pageName: '/location-of-office', hasSkipButton: false, hasFinishButton: false, hasBackButton: true },
			{ pageName: '/locations', hasSkipButton: true, hasFinishButton: false, hasBackButton: true },
			{ pageName: '/shipping-locations', hasSkipButton: true, hasFinishButton: false, hasBackButton: true },
			{ pageName: '/additional-details', hasSkipButton: true, hasFinishButton: false, hasBackButton: true },
			{ pageName: '/company-values', hasSkipButton: false, hasFinishButton: true, hasBackButton: true },

		]
	},
	{
		organizationType: 'owner',
		flow: [
			{ pageName: '/what-company-are-you', hasSkipButton: false, hasFinishButton: false, hasBackButton: false },
			{ pageName: '/which-industries-and-sectors', hasSkipButton: false, hasFinishButton: false, hasBackButton: true },
			{ pageName: '/contact-information', hasSkipButton: false, hasFinishButton: false, hasBackButton: true },
			{ pageName: '/location-of-office', hasSkipButton: false, hasFinishButton: true, hasBackButton: true },
		]
	},
];
interface IProps {

}

interface IFormContext {
	submitForm: (organizationType: OrganizationTypeEnum | undefined, field: LocationsType) => Promise<boolean>;
	NextPage: (organizationType: OrganizationTypeEnum | undefined, pageName: string) => Promise<void>;
	PreviousPage: (organizationType: OrganizationTypeEnum | undefined, pageName: string) => Promise<void>;
	SkipPage: (organizationType: OrganizationTypeEnum | undefined, pageName: string) => Promise<void>;
	CurrentIndex: (organizationType: OrganizationTypeEnum | undefined, pageName: string) => IPageIndex | undefined;
}

const FormProvider = (props: React.PropsWithChildren<IProps>) => {
	const { children } = props;
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const picklistCxt = React.useContext(PickListContext);
	const toast = useToast();
	const navigate = useNavigate();
	const methods = useForm<OrganizationRequest>({
		mode: 'onSubmit',
		reValidateMode: 'onSubmit'
	});

	return (
		<FormContext.Provider value={{ submitForm, NextPage, PreviousPage, SkipPage, CurrentIndex }}>
			<RhfFormProvider {...methods}>
				{isLoading ? <LoadingPage /> : children}
			</RhfFormProvider>
		</FormContext.Provider>
	);
	async function NextPage(orgtype: OrganizationTypeEnum | undefined, pageName: string) {
		navigatePage(orgtype, pageName, +1);
	}
	async function PreviousPage(orgtype: OrganizationTypeEnum | undefined, pageName: string) {
		navigatePage(orgtype, pageName, - 1);
	}
	async function SkipPage(orgtype: OrganizationTypeEnum | undefined, pageName: string) {
		navigatePage(orgtype, pageName, + 1);
	}

	async function navigatePage(organizationType: OrganizationTypeEnum | undefined, pageName: string, movement: number) {
		if (organizationType === undefined) { organizationType = 'manufacturer'; }
		const flowForOrganization = onboardingFlow.find(x => x.organizationType === organizationType);
		if (flowForOrganization) {
			const pageIndex = findFlowIndex(flowForOrganization.flow, pageName);

			if (pageIndex > -1) {
				const page = flowForOrganization!.flow[pageIndex + movement];
				navigate(page.pageName);
			}
		}
	}

	function CurrentIndex(organizationType: OrganizationTypeEnum | undefined, pageName: string) {
		if (organizationType === undefined) { organizationType = 'manufacturer'; }
		const flowForOrganization = onboardingFlow.find(x => x.organizationType === organizationType);
		if (flowForOrganization) {
			const pageIndex = findFlowIndex(flowForOrganization.flow, pageName);
			if (pageIndex > -1) {
				const flow = flowForOrganization.flow[pageIndex];
				const idx: IPageIndex = {
					currentIndex: pageIndex + 1,
					totalIndex: flowForOrganization!.flow.length,
					hasFinishButton: flow.hasFinishButton,
					hasSkipButton: flow.hasSkipButton,
					pageName: pageName,
					hasBackButton: flow.hasBackButton,
				};
				return idx;
			}
		}


	}

	async function submitForm(organizationType: OrganizationTypeEnum | undefined, fields: LocationsType) {
		setIsLoading(true);
		const countryPL = picklistCxt.pickListItems.filter(v => v.pickListType?.code === 'Country');
		const countries: string[] = countryPL.map(v => {
			return v.code;
		}) as string[];
		const countriesInfo: { code: string; description: string }[] = countryPL.map(v => {
			return {
				code: v.code ?? '',
				description: v.description ?? ''
			};
		});

		const { countryDropdownShipping, ...remainingFields } = fields;
		try {
			if (organizationType === 'owner') {

			} else {
				if (remainingFields.manufacturingLocation && remainingFields.manufacturingLocation.length > 0) {

					const manufacturingLocationDTOs: OrganizationLocationsDTO[] = remainingFields.manufacturingLocation.map((loc: string) => {
						const parts = loc.split(',').map(part => part.trim());
						const city = parts[0];
						const region = parts.length >= 3 ? parts[parts.length - 2] : null;
						let country = parts[parts.length - 1];

						return {
							LocationCity: city,
							LocationRegion: region,
							LocationCountry: country,
						} as OrganizationLocationsDTO;
					});


					const locationCountries = remainingFields.manufacturingLocation.map((loc: any) => {
						const country = loc.split(',').pop()?.trim();
						return country;
					}).filter((loc: any): loc is string => loc !== undefined && loc !== '');

					const manufacturingLocations: string[] = Array.from(new Set(
						countriesInfo
							.filter(country =>
								locationCountries.includes(country.code) ||
								locationCountries.includes(country.description)
							)
							.map(country => country.code)
							.filter((code): code is string => !!code)
					));

					remainingFields.manufacturingLocation = manufacturingLocations;
					remainingFields.fullManufacturingLocations = manufacturingLocationDTOs;

				}

				if (countryDropdownShipping === 'except') {
					remainingFields.shippingLocation = countries.filter(v => !fields.shippingLocation?.includes(v));
				}
				if (countryDropdownShipping === 'all') {
					remainingFields.shippingLocation = countries;
				}

			}

			if (fields.headOfficeLocation) {
				const locationParts = fields.headOfficeLocation.split(',').map(part => part.trim());

				remainingFields.headOfficeLocation = locationParts[0] || '';
				remainingFields.headOfficeLocationRegion = locationParts[1] || locationParts[0] || '';
				remainingFields.headOfficeLocationCountry = locationParts[2] || locationParts[1] || locationParts[0] || '';
			}

			await scopeMatchAPI.organization.PostOrganization(remainingFields);
			setIsLoading(false);
			navigate('/onboarding-success');
			return true;
		} catch (error) {
			toast.error('Could not save your user data');
			setIsLoading(false);
		}
		setIsLoading(false);
		return false;
	}

	function findFlowIndex(flow: IPageIndex[], pageName: string): number {

		for (let j = 0; j < flow.length; j++) {
			if (flow[j].pageName === pageName) {
				return j;
			}
		}
		return -1; // Return -1 if the pageName is not found
	}


};

export const FormContext = React.createContext<IFormContext>({} as IFormContext);
export default FormProvider;
export type LocationsType = { countryDropdownShipping: string, countryDropdown: string } & OrganizationRequest;
