import React from 'react';
import { Button, LoadingPage, Page } from 'components/core';
import TitleBar from 'screens/Components/TitleBar';
import { ReactComponent as IconRequest } from 'assets/icons/icon-request.svg';
import { Link } from 'react-router-dom';
import scopeMatchAPI from 'api/ScopeMatchAPI';
import { ReactComponent as IconNoMatches } from 'assets/icons/icon-no-suggested.svg';
import { ReactComponent as IconMarkEmailRead } from 'assets/icons/icon-mark-email-read.svg';
import SendEmailModal from 'screens/Requests/SendEmailModal';
import ScopeMatchAPI from 'api/ScopeMatchAPI';
import { useToast } from 'components/customHooks/useToast';



const MatchHistory = () => {
	const [saveMatches, setSaveMatches] = React.useState<MatchHistoryDTO[]>();
	const [isLoading, setIsLoading] = React.useState<boolean>(true);
	const [isSending, setIsSending] = React.useState<boolean>(false);
	const [showEmailModal, setShowEmailModal] = React.useState<MatchHistoryDTO>();
	const toast = useToast();
	React.useEffect(() => {
		const fetchSaveMatchDate = async () => {
			const saveMatchResults = await scopeMatchAPI.search.GetSavedMatches();
			setSaveMatches(saveMatchResults ?? []);
		};
		fetchSaveMatchDate();
		setIsLoading(false);
	}, []);
	const handleSendEmailConfirmation = async (requestId: number | undefined, organizationId: number | undefined, organizationName: string | undefined) => {
		setShowEmailModal({
			organizationID: organizationId,
			requestID: requestId,
			organizationName: organizationName
		});
	};
	const cancelSendEmailConfirmation = async () => {
		setShowEmailModal(undefined);
	};
	const sendEmailNotification = async () => {
		setIsSending(true);
		setShowEmailModal(undefined);
		ScopeMatchAPI.search.SendMatchNotification(showEmailModal?.requestID, showEmailModal?.organizationID).then(response => {
			if (response !== undefined) {
				toast.success('Email notification sent');
				setSaveMatches((matches) => {
					return matches && matches.map((match) => {
						if (match.requestID === showEmailModal?.requestID && match.organizationID === showEmailModal?.organizationID) {
							return {
								...match,
								sendMatchNotificationDate: response ? response : undefined,
							};
						} else {
							return match;
						}
					});
				});
			} else {
				toast.error('An error occured.  No email notification sent');
			}
			setIsSending(false);
		});

	};

	const orgMatches: { orgName?: string, orgId: number }[] = [];
	saveMatches?.forEach(x => x.organizationID && !orgMatches.find(y => y.orgId === x.organizationID) && orgMatches.push({ orgName: x.organizationName, orgId: x.organizationID }));
	const pageTitle = 'Match History';

	if (isLoading === true) {
		return <LoadingPage />;
	}

	return (
		<Page title={pageTitle}>
			<div className="d-flex">
				<div className="d-flex flex-column w-100">
					<TitleBar
						pageTitle={pageTitle}
					/>
				</div>
			</div>
			<div className="container-fluid mb-5 px-5 py-3">
				<div className="row">
					{saveMatches?.length === 0 ? (
						<div className="container-fluid">
							<div className="text-center bg-light py-5 rounded px-4">
								<IconNoMatches />
								<h5 className="mb-2">You don’t have any matches yet</h5>
								<p className="mb-2 pb-3">Match with companies in your requests to create matches</p>
								<Button
									to="/requests"
									className="btn-primary ms-auto"
								>
									Go To Requests
								</Button>
							</div>
						</div>
					) : (
						<div className="col-6">
							<p className="text-gray-700 mb-1 fs-14 fw-bold">MATCHES SAVED TO YOUR REQUESTS</p>
							<hr className="m-0" />
							{orgMatches.map((savedMatch, i) => {
								const filterSavedMatches = saveMatches?.filter(z => z.organizationID === savedMatch.orgId);
								return (
									<div key={`match-${savedMatch.orgId}-${i}`} className="card shadow rounded mt-3 p-3">
										<div className="card-body">
											<h3 className="mb-3">{savedMatch.orgName}</h3>
											<hr />
											<p className="mb-3 fs-14 fw-bold">MATCHED REQUESTS</p>
											{filterSavedMatches?.sort((a, b) => (b.requestID || 0) - (a.requestID || 0)).map((filteredMatches, index, array) =>
												<React.Fragment key={`matchrow-${savedMatch.orgId}-${i}-${index}`}>
													<div className={`d-flex justify-content-between align-items-center ${index === array.length - 1 ? '' : 'pb-2'}`}>
														<Link
															key={`request-${filteredMatches.requestID}`}
															to={`/requests/searchresults/${filteredMatches.requestID}/matchdetails/${filteredMatches.organizationID}`}
														>
															<IconRequest className="me-2" />
															{filteredMatches.requestTitle}

														</Link>
														{
															filteredMatches.sendMatchNotificationDate === undefined ?
																<Button
																	className="btn-primary btn-sm"
																	onClick={() =>
																		handleSendEmailConfirmation(filteredMatches.requestID, filteredMatches.organizationID, filteredMatches.organizationName)
																	}
																	loading={isSending}
																>
																	Send intro email
																</Button>
																:
																<small className="bg-primary-100 text-primary p-1 ps-2 pe-2 fst-italic rounded">
																	Intro email sent
																	<IconMarkEmailRead className="ms-2 " />
																</small>
														}
													</div>
												</React.Fragment>
											)}
										</div>
									</div>
								);
							})}
						</div>
					)}
				</div>
			</div>
			{showEmailModal && <SendEmailModal
				receiveOrganizationName={showEmailModal.organizationName}
				onRequestClose={cancelSendEmailConfirmation}
				sendEmail={sendEmailNotification}
			/>}
		</Page >
	);
};

export default MatchHistory;

