import React from 'react';
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

interface GaugeChartProps {
	valueMin?: number;
	valueMax?: number;
	value: number;
	title: string;
	text?: string;
	width?: number;
	height?: number;
}

const StyledCard = styled(Box)((_) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center', // On column flex this in revers, it sets the horizontal align
	justifyContent: 'center', // On column flex this in revers, it sets the vertical align
}));

const ChartContainer = styled(Box)(() => ({
	marginTop: '-15px', // Ensure no extra margin is applied here
	padding: '0px', // Reset any potential padding
	fontSize: 12
}));

function GaugeChart({
	value,
	valueMin = 0,
	valueMax = 100,
	title,
	text,
	width = 100,
	height = 100,
}: GaugeChartProps) {
	return (
		<StyledCard>
			<h6 style={{ marginBottom: '0' }}>{title}</h6>
			<ChartContainer>
				<Gauge
					title={title}
					text={({ value }) => text ?? `${value}`}
					value={value}
					valueMin={valueMin}
					valueMax={valueMax}
					startAngle={-120} // Increase the angle to reduce empty space
					endAngle={120} // Increase the angle to reduce empty space
					innerRadius="90%" // Increase innerRadius to fill more space
					outerRadius="100%" // Keep outerRadius at maximum to use full width
					width={width}
					height={height}
					sx={() => ({
						[`& .${gaugeClasses.valueArc}`]: {
							fill: '#006361',
						},
					})}
				/>
			</ChartContainer>
		</StyledCard>
	);
}

export default GaugeChart;
