import axios from 'axios';

const API_KEY = '60382358-353d-4029-ae11-905931cefd45'; // Replace with your actual API key

async function NewsapiGetArticles() {
	const today = new Date();
	const lastWeek = new Date(today);
	lastWeek.setDate(today.getDate() - 7);

	const payload = {
		query: {
			$query: {
				$and: [
					{
						conceptUri: 'http://en.wikipedia.org/wiki/Supply_chain',
					},
					{
						$or: [
							{ categoryUri: 'dmoz/Business/International_Business_and_Trade' },
							{ categoryUri: 'dmoz/Business/Transportation_and_Logistics' },
							{ categoryUri: 'dmoz/Business/Management/Supply_Chain' },
						],
					},
					{
						$or: [
							{ sourceUri: 'zerohedge.com' },
							{ sourceUri: 'oilprice.com' },
							{ sourceUri: 'tradingeconomics.com' },
							// { sourceUri: 'supplychain247.com' },
							{ sourceUri: 'supplychaindive.com' },
							{ sourceUri: 'logisticsmgmt.com' },
							{ sourceUri: 'mmh.com' },
							{ sourceUri: 'bloomberg.com' },
							{ sourceUri: 'reuters.com' },
						],
					},
					{
						dateStart: lastWeek.toISOString().split('T')[0],
						dateEnd: today.toISOString().split('T')[0],
					},
				],
			},
			$filter: {
				hasEvent: 'keepOnlyArticlesWithoutEvent',
				isDuplicate: 'skipDuplicates',
			},
		},
		resultType: 'articles',
		articlesSortBy: 'date',
		includeArticleCategories: true,
		includeArticleLocation: true,
		includeConceptTrendingScore: true,
		apiKey: API_KEY,
	};

	try {
		const response = await axios.post(
			'https://www.newsapi.ai/api/v1/article/getArticles',
			payload
		);

		if (response.status === 200) {
			const data = response.data;
			console.log('totalResults:', data.articles.totalResults);
			console.log('count:', data.articles.count);
			console.log('pages:', data.articles.pages);
			console.log('Keys:', Object.keys(data.articles.results[0]));
			return data.articles.results;
		} else {
			console.error(`Failed to retrieve data. Status code: ${response.status}`);
			return [];
		}
	} catch (error) {
		console.error('Error fetching articles:', error);
		return [];
	}
}

async function GetNews(signal?: AbortSignal) {
	const response = await axios
		.get(`${apiUrl}/news`, {
			signal: signal,
			params: { nDaysBack: 7, apiKey: API_KEY },
		})
		.then((data: { data: News }) => {
			return data.data;
		})
		.catch((err: any) => {
			console.log('err', err);
			console.error({ error: err.response.data.responseStatus.message });
			return null;
		});
	return response;
}

const apiUrl = process.env.REACT_APP_API_URL;
const NewsAPIs = {
	NewsapiGetArticles,
	//GetNews,
};

export default NewsAPIs;
