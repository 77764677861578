import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import PickListStore from 'context/PickListStore';
import AuthStore from 'context/AuthStore';

import SettingsContext from 'settings/SettingsContext';
import { MsalProvider } from '@azure/msal-react';

import 'react-toastify/dist/ReactToastify.css';

import AppNavigator from 'AppNavigator';
import { ToastProvider } from 'components/customHooks/useToast';
import AppHistoryTrackerProvider from 'punchcardcore/utils/useAppHistoryTracker';
import { PublicClientApplication } from '@azure/msal-browser';
import PTDStore from 'context/PTDStore';

const App = () => {
	const { settings } = React.useContext(SettingsContext);
	const msalInstance = new PublicClientApplication({
		auth: {
			clientId: settings.b2CClientId!,
			authority: settings.b2CSignInPolicyURL,
			knownAuthorities: settings.b2CKnownAuthorities,
			redirectUri: window.location.origin,
		},
		cache: {
			cacheLocation: 'sessionStorage',
			storeAuthStateInCookie: false,
		},
	});
	return (
		<MsalProvider instance={msalInstance}>
			<ToastProvider>
				<AuthStore>
					<PickListStore>
						<PTDStore>
							<AppHistoryTrackerProvider>
								<BrowserRouter>
									<React.Fragment>
										<AppNavigator />
									</React.Fragment>
								</BrowserRouter>
							</AppHistoryTrackerProvider>
						</PTDStore>
					</PickListStore>
				</AuthStore>
			</ToastProvider>
		</MsalProvider>
	);
};

export default App;
