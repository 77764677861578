import React from 'react';
import { ReactComponent as IconCompanyValues } from 'assets/icons/icon-company-values.svg';
import Pill from './pills/Pill';


interface IProps {
	orgInsuranceRange?: string;
}

const OrgInsuranceRange = (props: IProps) => {

	if (!props.orgInsuranceRange) {
		return null;
	}

	return (
		<div className="card shadow p-3 mb-2 rounded">
			<div className="d-flex align-items-center">
				<div className="rounded-circle bg-secondary-100 p-2 me-3">
					<IconCompanyValues />
				</div>
				<h5 className="mb-0">Insurance Range</h5>
			</div>
			<hr />
			<div className="d-flex flex-wrap">
				{props?.orgInsuranceRange ? (
					<Pill
						className="text-primary fw-bold">
						{props.orgInsuranceRange}
					</Pill>
				) : (
					<p className="text-gray-600 fst-italic">No insurance range specified</p>
				)}
			</div>
		</div>
	);
};

export default OrgInsuranceRange;