import { ModalOverlay } from 'components/core';
import React from 'react';

interface IProps {
	id: { productId: number, productTypeName: string } | null;
	onRequestClose: () => void;
	deleteRow: (val: { productId: number, productTypeName: string }) => Promise<boolean>;
}

const DeleteModal = (props: IProps) => {
	const { id, onRequestClose, deleteRow } = props;
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	return (
		<ModalOverlay
			isOpen={id !== null}
			modalSize="modal-dialog-centered"
			onRequestClose={onRequestClose}
			confirmButtonChildren="Delete"
			cancelButtonChildren="Cancel"
			confirmButtonAction={deleteAndClose}
			cancelButtonAction={onRequestClose}
			isLoading={isLoading}
			hideCloseButton={true}
		>
			<h3>Are you sure you want to delete this?</h3>
			<p>This action cannot be undone.</p>
		</ModalOverlay>

	);
	async function deleteAndClose() {

		if (id !== null) {
			setIsLoading(true);
			await deleteRow(id);
			setIsLoading(false);
			onRequestClose();
		}
	}

};

export default DeleteModal;