import React from 'react';
import AuthContext from './AuthContext';
import { LoadingPage } from 'components/core';
import { AuthenticatedTemplate, MsalAuthenticationTemplate, useMsal } from '@azure/msal-react';
import axios from 'axios';
import authAPI from 'auth/authApi';
import { InteractionType } from '@azure/msal-browser';
import coreAPI from 'admin/Users/coreAPI';

const AuthStore = (props: any) => {
	const [currentUser, setCurrentUser] = React.useState({} as CurrentUserDTO);
	const [currentUniqueId, setCurrentUniqueId] = React.useState<string>('');
	const [isInitializing, setIsInitializing] = React.useState(true);

	const { accounts, instance } = useMsal();
	const [settings, setSettings] = React.useState({} as any);
	React.useEffect(() => {
		coreAPI.getSettings().then(setting => {
			setSettings(setting);
		});
	}, []);

	React.useEffect(() => {
		const init = async () => {
			if (accounts.length === 1) {
				await getToken();
				await refreshUser();

				setIsInitializing(false);
			}
		};

		init();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accounts, instance]);

	React.useEffect(() => {
		// interceptor to get new token on a 401, then retry the request.
		axios.interceptors.response.use(
			response => response,
			async (error: any) => {
				const originalConfig = error.config;
				if (error.response) {
					if (error.response.status === 401 && !originalConfig._retry) {
						originalConfig._retry = true; // don't retry, avoid infinite loop.
						const token = await getToken(true);
						originalConfig.headers.Authorization = `Bearer ${token}`;
						return axios.request(originalConfig);
					}
					else {
						return Promise.reject(error);
					}
				}
				else {
					console.error(error);
					return error;
				}
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [axios]);

	const refreshUser = async () => {
		// our setup may be a bit off. We are using the IDToken to authenticate,
		// trying to fetch an access token just gives an empty token
		// https://docs.microsoft.com/en-us/azure/active-directory/develop/scenario-spa-acquire-token?tabs=react

		const user = await authAPI.getCurrentUser();
		setCurrentUser(user);
		return true;
	};

	const getToken = async (forceRefresh: boolean = false) => {
		const token = await instance.acquireTokenSilent({ scopes: [], account: accounts[0], forceRefresh: forceRefresh });
		axios.defaults.headers.common.Authorization = `Bearer ${token.idToken}`;
		setCurrentUniqueId(token.uniqueId);
		return token.idToken;
	};

	const initialState = React.useMemo(() => ({
		currentUser,
		currentUniqueId,
		isInitializing,
		settings,
		refreshUser,
	}), [currentUser, currentUniqueId, isInitializing, settings]);

	return (
		<MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
			<AuthenticatedTemplate>
				<AuthContext.Provider value={initialState}>
					{initialState.isInitializing ? <LoadingPage /> : props.children}
				</AuthContext.Provider>
			</AuthenticatedTemplate>
		</MsalAuthenticationTemplate>
	);
};
export default AuthStore;