import React from 'react';
import { Button, FormGroup, Input, LoadingPage, Page, Select, TextArea } from 'components/core';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { NewRequestErrorMsg, preventWindowClose } from 'screens/OnBoarding/utils';
import { ReactComponent as IconAdd } from 'assets/icons/icon-add.svg';
import { PickListSelect } from 'components/scopematch/PickListSelect';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import ProductTypeModal from '../ProductCatalog/components/Forms/ProductTypeModal';
import scopeMatchAPI from 'api/ScopeMatchAPI';
import DeleteModal from 'screens/ProductCatalog/components/Forms/DeleteModal';
import TitleBar from 'screens/Components/TitleBar';
import PickListContext from 'context/PickListContext';
import { RequestContext } from './RequestContext';
import DeleteProductTypeModal from 'screens/ProductCatalog/components/Forms/DeleteProductTypeModal';
import ProductTable from 'components/scopematch/ProductTable';
import { Text, Buttons, DropDown } from 'screens/ProductCatalog/components/columns';
import { useNavigate } from 'react-router-dom';

type RequestType = { countryDropdown?: string, recievingLocationsCountries?: string } & MatchRequestDTO;
const NewRequest = () => {
	const requestCxt = React.useContext(RequestContext);
	const productFormMethods = useForm<any>({ reValidateMode: 'onChange' });
	const methods = useForm<any>({ reValidateMode: 'onChange' });
	const { control, handleSubmit, reset, setValue, formState, watch } = methods;
	const [isLoading, setIsLoading] = React.useState<boolean>(true);
	const [isSaving, setIsSaving] = React.useState<boolean>(false);
	const [showProductTypeModal, setShowProductTypeModal] = React.useState<boolean>(false);
	const [showDeleteRowModal, setShowDeleteRowModal] = React.useState<{ productId: number, productTypeName: string } | null>(null);
	const [showDeleteProductTypeModal, setShowDeleteProductTypeModal] = React.useState<{ productTypeId: number, productTypeName: string } | null>(null);
	const [productTypeTitle, setProductTypeTitle] = React.useState<string | undefined>('');
	const [productSpecsList, setProductSpecsList] = React.useState<ProductTypeDTO[]>([]);
	const [orgProductList, setOrgProductList] = React.useState<MatchRequestDTO>();
	const [productTypes, setProductTypes] = React.useState<string[]>([]);
	const [organization, setOrganization] = React.useState<OrganizationDTO>();
	const countryDropdown = watch('countryDropdown');
	const recievingLocationsCountries = watch('recievingLocationsCountries');
	const picklistCxt = React.useContext(PickListContext);
	const picklistItems = picklistCxt.pickListItems;
	const params = useParams();
	const requestId = Number(params.requestID);
	const countryOptions = [
		{ value: 'all', label: 'All' },
		{ value: 'except', label: 'All countries except for...' },
		{ value: 'specific', label: 'Specific countries' },
	];
	const pageTitle = 'New Request';
	React.useEffect(() => {
		if (requestCxt.isRequestDirty || productFormMethods.formState.isDirty) {
			window.addEventListener('beforeunload', preventWindowClose);
		}
		return () => {
			window.removeEventListener('beforeunload', preventWindowClose);
		};
	}, [requestCxt.isRequestDirty, productFormMethods.formState.isDirty]);

	const navigate = useNavigate();
	const handleNavigate = () => {
		if (requestCxt.isRequestDirty || productFormMethods.formState.isDirty) {
			const confirmed = window.confirm('Your changes to the request are not saved. Are you sure you want to navigate away?');
			if (confirmed) {
				navigate(-1);
			}
		} else {
			navigate(-1);
		}
	};

	React.useEffect(() => {
		requestCxt.setIsRequestDirty(formState.isDirty || productFormMethods.formState.isDirty);
	}, [requestCxt, formState.isDirty, productFormMethods.formState.isDirty]);

	const [editTable, setEditTable] = React.useState<{ productType: string, rowId: number, newLine?: boolean, productId?: number, duplicateRow?: number } | null>(null);
	const newRow: { [key: string]: string | JSX.Element } = {
		id: 'add-id',
		productTypeName: 'add-productTypeName',
	};

	React.useEffect(() => {
		async function fetchSpecs() {
			try {
				const productTypeListResult = await scopeMatchAPI.product.GetProductTypeList();
				const orgProductsResult = await scopeMatchAPI.search.GetSearchParameters(requestId ? requestId : 0);
				const orgResult = await scopeMatchAPI.organization.GetOrganization();
				if (orgResult !== null) {
					setOrganization(orgResult);
				}
				if (productTypeListResult !== null && orgProductsResult !== null) {
					setProductSpecsList(productTypeListResult);
					setOrgProductList(orgProductsResult);
					const { matchRequestProducts, receivingLocation, manufacturingLocation, ...otherFields } = orgProductsResult;
					setProductTypes(v => {
						const newArry = [...v];
						matchRequestProducts?.forEach(v => v.productTypeDescription && !newArry.includes(v.productTypeDescription) && newArry.push(v.productTypeDescription));
						return newArry;
					});
					reset(otherFields);
					const manufacturingValues = getCountryValues(manufacturingLocation);
					if (manufacturingValues !== null) {
						setValue('countryDropdown', manufacturingValues.countries);
						setValue('manufacturingLocation', manufacturingValues.value);
					}
					const receivingLocationValues = getCountryValues(receivingLocation);
					if (receivingLocationValues !== null) {
						setValue('recievingLocationsCountries', receivingLocationValues?.countries);
						setValue('receivingLocation', receivingLocationValues?.value);
					}
				}

			} catch (error) {
				toast.error('Could not save your user data');
			}
			setIsLoading(false);
		}
		fetchSpecs();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	if (isLoading === true || requestCxt.isLoading === true) {
		return <LoadingPage />;
	}

	return (
		<FormProvider {...productFormMethods}>
			<Page title={pageTitle}>
				<div className="d-flex flex-column w-100">
					<TitleBar
						pageTitle={pageTitle}
						backButton={true}
						onClick={handleNavigate}
					/>
				</div>
				<div className="container-fluid mb-5 px-5 py-3">
					<div className="row">
						<div className="col-5">
							<h3 className="mb-2 pb-3">Request Details</h3>
							<Controller
								control={control}
								name="requestName"
								rules={{ required: NewRequestErrorMsg.RequestName }}
								render={({ field, fieldState }) =>
									<FormGroup id="requestName" label="Request Name" required={true} field={field} fieldState={fieldState}>
										<Input
											{...field} error={!!fieldState.error?.message}
										/>
									</FormGroup>
								}
							/>
							<Controller
								control={control}
								name="requestDescription"
								rules={{ required: NewRequestErrorMsg.RequestDescription }}
								render={({ field, fieldState }) =>
									<FormGroup id="requestDesc" label="Request Description" required={true} field={field} fieldState={fieldState}>
										<TextArea
											className="form-control"
											id="requestDesc"
											name={field.name}
											value={field.value}
											onChange={field.onChange}
											placeholder=""
											rows={2}
										/>
									</FormGroup>

								}
							/>
							<p className="text-end text-dark mt-n3 mb-0 fs-16">120 characters</p>

						</div>
						<div className="col-12">
							<h3 className="mt-5 mb-4">Product Criteria</h3>
							<Button
								className="btn btn-primary"
								onClick={openProductTypeModal}>
								<IconAdd />Add Product Type
							</Button>
							<div className="mt-4">
								{productTypes?.map((item, index) => {
									const columns = productSpecsList?.find((v) => v.productTypeCode === item)?.productSpecCodes?.map((code) => ({
										Header: code.productSpecDescription,
										accessor: code.productSpecCode,
										Cell: (row: any) => {
											if (editTable?.productType === item && editTable?.rowId === row.index) {
												return <DropDown code={code} value={row.value || ''} />;
											}
											if (row.value === undefined || row.value.length === 0) {
												return <Text>Any</Text>;
											}
											return <Text>{row.value}</Text>;
										},
									})) as any[];

									columns?.push({
										Header: '',
										accessor: 'delete',
										Cell: (row: any) => {
											const isCurrentTable = !!editTable && editTable.productType === item;
											const isEditingCurrentRow = isCurrentTable && !!editTable && editTable.rowId === row.index || isCurrentTable && !!editTable &&
												editTable?.productType === item &&
												!!editTable?.duplicateRow &&
												row.index === 0;
											const rowIsDisabled = !!editTable && ((isCurrentTable && editTable.rowId !== row.index) || !isCurrentTable);
											return (
												<Buttons
													isEditing={isEditingCurrentRow}
													disabled={rowIsDisabled}
													onClickSave={productFormMethods.handleSubmit(submitProductForm)}
													onClickCancel={closeProductAddRow}
													onClickDelete={() => deleteRow(row)}
													onClickEdit={() => onClickEditOrDuplicate(row, item ?? '', 'edit')}
													onClickDuplicate={() => onClickEditOrDuplicate(row, item ?? '', 'duplicate')}
													isSaving={isSaving}
												/>
											);
										},
									});

									columns.push({
										accessor: 'id',
										show: false,
									});
									columns.push({
										accessor: 'productTypeName',
										show: false,
									});
									const products = orgProductList?.matchRequestProducts?.filter((x) => x.productTypeCode === item);
									const data = products?.map((v) => {
										if (v.productSpecCodes) {
											const isAny = v.productSpecCodes?.map(spec => spec.productSpecValue === 'Any');
											if (isAny?.includes(false) ?? false) {
												const lineItem: any = {};
												v.productSpecCodes?.forEach((x) => {
													lineItem[x.productSpecCode!] = x.productSpecValue;
												});
												lineItem['id'] = v.id;
												lineItem['productTypeName'] = v.productTypeDescription;
												return lineItem;
											}
										}
										return undefined;
									}).filter(Boolean);

									if (!!editTable?.duplicateRow) {
										const type = editTable.productType;
										const productss = orgProductList?.matchRequestProducts?.filter((x) => x.productTypeCode === item);
										const rows = productss?.find(x => x.id === editTable.duplicateRow);
										const lineItem: any = {};
										rows?.productSpecCodes?.forEach(x => {
											lineItem[x.productSpecCode!] = x.productSpecValue;
										});

										lineItem['id'] = 'duplicate';
										lineItem['productTypeName'] = type;

										data?.push(lineItem);
									}

									data?.reverse();
									const tableData = data || [];
									if (editTable?.productType === item && editTable?.newLine === true) {
										tableData?.unshift(newRow);
									}

									return (
										<ProductTable
											key={`table-${index}`}
											productTypeName={item}
											columns={columns}
											data={tableData}
											disabled={!!editTable}
											emptyTableTitle={`You are currently looking for ${item}.  Add specific products if you would like to further refine your search.`}
											onClickAddProduct={() => {
												productFormMethods.reset({}, { keepValues: false, keepDirty: false });
												setProductTypeTitle(item);
												setEditTable({
													productType: item ?? '',
													rowId: 0,
													newLine: true,
												});
											}}
											showDeleteProductTypeModal={() =>
												setShowDeleteProductTypeModal({
													productTypeName: productTypeTitle === undefined ? '' : item,
													productTypeId: productSpecsList?.find(v => v.productTypeCode === item ?? '')?.productTypePickListItemId ?? 0,
												})
											}
										/>
									);
								})}
							</div>

						</div>
						<div className="col-8 mb-5">
							<h3 className="mt-5 mb-3 pb-2">Business Scope Criteria</h3>
							<Controller
								control={control}
								name="countryDropdown"
								render={({ field, fieldState }) =>
									<FormGroup
										label={
											organization?.organizationType?.toString() === 'distributor' || organization?.organizationType?.toString() === 'owner' ?
												('Preferred Manufacturing Location') :
												('Target Distribution Locations')
										}
										field={field}
										fieldState={fieldState}
										className="request-label"
									>
										<p>
											{organization?.organizationType?.toString() === 'distributor' || organization?.organizationType?.toString() === 'owner' ?
												('Do you have any requirements or preferences regarding the manufacturing country of origin?') :
												('In what countries are you seeking to distribute your products?')
											}
										</p>
										<Select
											id="country-select"
											name={field.name}
											value={field.value}
											options={countryOptions}
											onChange={field.onChange}
											error={!!fieldState.error?.message}
											placeholder=""
										/>
									</FormGroup>
								}
							/>

							{countryDropdown !== undefined && countryDropdown !== 'all' && (
								<Controller
									control={control}
									name="manufacturingLocation"
									render={({ field, fieldState }) =>
										<FormGroup id="originsCountry" label={countryDropdown === 'except' ? ('All countries except for...') : ('Specific countries')}
											field={field} fieldState={fieldState}>
											<span className="fw-normal fst-italic text-gray-700">
												(select all that apply)</span>
											<PickListSelect
												id="originsCountry"
												pickListTypeName="Country"
												placeholder=""
												isMulti={true}
												name={field.name}
												value={field.value}
												onChange={field.onChange}
											/>
										</FormGroup>
									}
								/>
							)}
							{organization?.organizationType?.toString() === 'distributor' || organization?.organizationType?.toString() === 'owner' && (
								<Controller
									control={control}
									name="recievingLocationsCountries"
									render={({ field, fieldState }) => (
										<FormGroup
											label="Receiving Locations"
											field={field}
											fieldState={fieldState}
											className="request-label"

										>
											<p>What countries would you like these products shipped to?</p>
											<Select
												id="country-select"
												name={field.name}
												value={field.value}
												options={countryOptions}
												onChange={field.onChange}
												error={!!fieldState.error?.message}
												placeholder=""
											/>
										</FormGroup>
									)}
								/>
							)}
							{recievingLocationsCountries !== undefined && recievingLocationsCountries !== 'all' && (
								<Controller
									control={control}
									name="receivingLocation"
									render={({ field, fieldState }) => (
										<FormGroup
											id="recievingLocations"
											label={recievingLocationsCountries === 'except' ? ('All countries except for...') : ('Specific countries')}
											field={field}
											fieldState={fieldState}
										>
											<span className="fw-normal fst-italic text-gray-700">
												(select all that apply)
											</span>
											<PickListSelect
												id="recievingLocations"
												pickListTypeName="Country"
												placeholder=""
												isMulti={true}
												name={field.name}
												value={field.value}
												onChange={field.onChange}
											/>
										</FormGroup>
									)}
								/>
							)}

							<h5 className="pt-3 mt-2">Insurance Range</h5>
							<Controller
								control={control}
								name="insuranceRange"
								render={({ field, fieldState }) =>
									<FormGroup id="insuranceRange" label=""
										required={true} field={field} fieldState={fieldState}>
										<p>
											{organization?.organizationType?.toString() === 'distributor' || organization?.organizationType?.toString() === 'owner' ?
												('What is the minimum product liability insurance amount you require from the manufacturer') :
												('Are you looking for an insured distributor? If so, what is the minimum insurance level you require?')
											}
										</p>
										<PickListSelect
											id="insuranceRange"
											pickListTypeName="Insurance Ranges"
											placeholder=""
											isMulti={false}
											name={field.name}
											value={field.value}
											onChange={field.onChange}
											filter={['Rather not Disclose']}
											useValue1={true}
											orderBy={{
												order: 'id',
												orderBy: 'asc'
											}}
										/>
									</FormGroup>
								}
							/>
							<h5 className="pt-3 mt-2">Business Standards and Certifications <span className="fw-normal fst-italic text-gray-700">(select all that apply)</span></h5>
							<Controller
								control={control}
								name="standardsAndCertification"
								render={({ field, fieldState }) =>
									<FormGroup id="standardsCertfications" label=""
										required={true} field={field} fieldState={fieldState}>
										<p>What standards and certifications do you need?</p>
										<PickListSelect
											id="standardsCertfications"
											pickListTypeName="Standards and Certifications"
											placeholder=""
											isMulti={true}
											name={field.name}
											value={field.value}
											onChange={field.onChange}
											filter={['None']}
										/>
									</FormGroup>
								}
							/>
							<h5 className="pt-3 mt-2">Corporate Values <span className="fw-normal fst-italic text-gray-700">(select all that apply)</span></h5>
							<Controller
								control={control}
								name="corporateValues"
								render={({ field, fieldState }) =>
									<FormGroup id="companyValues" label=""
										required={true} field={field} fieldState={fieldState}>
										<p>What corporate values do you seek in order to achieve good alignment with yours?</p>
										<PickListSelect
											id="companyValues"
											pickListTypeName="Company Values"
											placeholder=""
											isMulti={true}
											name={field.name}
											value={field.value}
											onChange={field.onChange}
										/>
									</FormGroup>
								}
							/>
						</div>
					</div>
				</div>
				<div className="container-fluid">
					<div className="row">
						<div className="col-12 shadow ps-5 py-3 bg-white py-3 save-profile">
							<Button
								className="btn btn-outline-primary me-2"
								onClick={handleSubmit(fields => submitForm(fields, false))}
							>
								Save Request
							</Button>
							<Button
								className="btn btn-primary"
								onClick={handleSubmit(fields => submitForm(fields, true))}>
								Submit Request
							</Button>
						</div>
					</div>
				</div>


				<ProductTypeModal
					isOpen={showProductTypeModal}
					onRequestClose={closeProductTypeModal}
					addProductType={addProductType}
					productType={productTypes}
				/>
				<DeleteModal
					id={showDeleteRowModal}
					deleteRow={confirmedDeleteItem}
					onRequestClose={closeDeleteRowModal}
				/>
				<DeleteProductTypeModal
					id={showDeleteProductTypeModal}
					deleteRow={confirmedDeleteProductType}
					onRequestClose={closeDeleteProductTypeModal}
				/>
			</Page >
		</FormProvider>
	);

	function onClickEditOrDuplicate(row: any, productTypeName: string, type: 'edit' | 'duplicate') {
		const keys = Object.keys(row.original);
		const nonProductVals = ['id', 'name', 'productTypeName', 'delete'];
		nonProductVals.forEach((val) => {
			const index = keys.indexOf(val);
			if (index > -1) {
				keys.splice(index, 1);
			}
		});
		keys.forEach((key) => {
			productFormMethods.setValue('specs.' + key, row.original[key]);
		});
		if (type === 'edit') {
			setEditTable({
				productType: productTypeName,
				rowId: row.index,
				productId: row.original.id
			});
		} else {
			setEditTable({
				productType: productTypeName,
				rowId: 0,
				productId: 0,
				duplicateRow: row.original.id
			});
		}
	}

	async function submitForm(fields: RequestType, submit: boolean) {
		if (productFormMethods.formState.isDirty == true) {
			alert('Please save your product row before submitting');
			return;
		}
		await requestCxt.postRequest(fields, orgProductList?.matchRequestProducts, submit, requestId);
		requestCxt.setIsRequestDirty(false);
	}

	function closeProductAddRow() {
		setEditTable(null);
	}

	function openProductTypeModal() {
		setShowProductTypeModal(true);
	}
	function closeProductTypeModal() {
		setShowProductTypeModal(false);
	}
	function addProductType(productType: string[]) {
		setProductTypes(v => [...v, ...productType]);
		if (productType) {
			productType.forEach(prodType => {	// add any any row to new product type
				addAnyAnyToProductType(prodType);
			});
			setProductTypeTitle(productType[0]);
		}
	}
	function deleteRow(row: any) {
		setShowDeleteRowModal({ productId: row.original.id, productTypeName: row.original.productTypeName });
	}
	function closeDeleteRowModal() {
		setShowDeleteRowModal(null);
	}
	function closeDeleteProductTypeModal() {
		setShowDeleteProductTypeModal(null);
	}
	async function confirmedDeleteItem(val: { productId: number, productTypeName: string }) {
		setOrgProductList(v => {
			const match = { ...v };
			const productTypeList = orgProductList?.matchRequestProducts?.filter(x => x.productTypeCode === val.productTypeName);	// get list of products in this product type
			if (productTypeList && productTypeList.length === 1) {// last remaining product for this product type, update to any any.
				productTypeList[0].productSpecCodes?.forEach(x => {
					x.productSpecValue = 'Any';
					x.productSpecPickListItemId = 0;
				});
			} else {
				match.matchRequestProducts = match?.matchRequestProducts?.filter(v => v.id !== val.productId);
			}
			return match;
		});
		toast.success('Product was deleted');
		return true;
	}
	function addAnyAnyToProductType(productTypeName: string) {
		const fields: { specs: { [key: string]: string } } = { specs: {} };
		const specs = productSpecsList?.find((v) => v.productTypeCode === productTypeName);
		if (specs && specs.productSpecCodes) {
			specs.productSpecCodes.forEach((specCode) => {
				if (typeof specCode.productSpecCode === 'string') {
					fields.specs[specCode.productSpecCode] = 'Any';
				}
			});
		}
		addProductToTable(0, productTypeName, fields);
	}
	async function submitProductForm(fields: { specs: { [x: string]: string } }) {
		requestCxt.setIsRequestDirty(true);
		setIsSaving(true);
		const keys = Object.keys(fields.specs);
		const isAny = keys.map(v => fields.specs[v].toLowerCase() === 'any');

		if (!isAny.includes(false)) {
			toast.error('At least one specific value is required');
			return;
		}

		if (productTypeTitle === undefined) {
			toast.error('Product type title is missing');
			return;
		}
		const result = await addProductToTable(editTable?.productId, productTypeTitle, fields);
		if (result === 409) {
			toast.error('This Product already exists');
			setIsSaving(false);
			return;
		}
		if (result === true) {
			setIsSaving(false);
			//productFormMethods.formState.isDirty = false;
			productFormMethods.reset({}, { keepDirty: false });
			return;
		}
		if (result === false) {
			toast.error('Something went wrong!');
		}
	}
	function removeAnyAnyFromProductList(productType: string) {
		if (orgProductList) {
			const prods = orgProductList?.matchRequestProducts?.filter(x => x.productTypeCode === productType);
			if (prods) {
				prods.forEach(product => {
					if (product.productSpecCodes) {
						const isAny = product.productSpecCodes.map(v => v.productSpecValue?.toLowerCase() === 'any');
						if (!isAny.includes(false)) {
							orgProductList.matchRequestProducts = orgProductList?.matchRequestProducts?.filter(x => x !== product);
						}
					}
				});
			}
		}
	}
	async function addProductToTable(id: number | undefined, productAdded: string, fields: { specs: { [x: string]: string } }): Promise<boolean | number> {
		removeAnyAnyFromProductList(productAdded);
		const product: ProductSpecDTO[] = [];
		const keys = Object.keys(fields.specs);
		keys.forEach(item => {
			fields.specs[item] && product.push({
				productSpecCode: item,
				productSpecPickListItemId: picklistItems.find(v => v.pickListType?.code === item && v.code === fields.specs[item])?.id,
				productSpecValue: picklistItems.find(v => v.pickListType?.code === item && v.code === fields.specs[item])?.description || 'Any',
			});
		});
		const productType = picklistCxt.pickListItems.find(v => v.code === productAdded);
		if (id && orgProductList?.matchRequestProducts?.findIndex(x => x.id === id) !== -1) {

			const index = orgProductList?.matchRequestProducts?.findIndex(x => x.id === id);
			const matchIndex = orgProductList?.matchRequestProducts?.[index!];

			const newValue = {
				...matchIndex,
				productSpecCodes: product,
			};
			if (notDuplicateProduct(newValue) == false) {
				return 409;
			}
			setOrgProductList(v => {
				const match = { ...v };
				match.matchRequestProducts![index!] = newValue;
				return match;
			});
		}
		else {
			const type: ProductTypeDTO = {
				id: Math.floor(Math.random() * (999999999 - 111111111 + 1) + 111111111),
				productTypePickListItemId: productType?.id,
				productTypeCode: productType?.code,
				productSpecCodes: product,
				productTypeDescription: productType?.description,
			};
			if (notDuplicateProduct(type) == false) {
				return 409;
			}
			setOrgProductList(v => {
				const match = { ...v };
				match.matchRequestProducts = match.matchRequestProducts ? [...match.matchRequestProducts, type] : [type];
				return match;
			});
		}
		setEditTable(null);
		return true;
	}
	function notDuplicateProduct(type: ProductTypeDTO) {
		if (orgProductList && orgProductList.matchRequestProducts && orgProductList.matchRequestProducts.length > 0) {
			const isNotDuplicated = orgProductList.matchRequestProducts.every(item => {
				return (
					item.productTypePickListItemId !== type.productTypePickListItemId ||
					item.productTypeCode !== type.productTypeCode ||
					item.productTypeDescription !== type.productTypeDescription ||
					areProductSpecCodesNotEqual(item.productSpecCodes, type.productSpecCodes)
				);
			});
			return isNotDuplicated;
		}
		return true;
	}
	function areProductSpecCodesNotEqual(savedSpecCodes: ProductSpecDTO[] | undefined, newSpecCodes: ProductSpecDTO[] | undefined) {
		if (savedSpecCodes !== undefined && newSpecCodes !== undefined) {
			if (savedSpecCodes.length !== newSpecCodes.length) {
				return false;
			}
			for (let i = 0; i < savedSpecCodes.length; i++) {
				const obj1 = savedSpecCodes[i];
				const obj2 = newSpecCodes[i];
				if (
					obj1.productSpecCode !== obj2.productSpecCode ||
					obj1.productSpecPickListItemId !== obj2.productSpecPickListItemId ||
					obj1.productSpecValue !== obj2.productSpecValue
				) {
					return true;
				}
			}
		}
		return false;
	}

	function getCountryValues(countryField: string[] | undefined) {
		const countries = picklistItems.filter(v => v.pickListType?.code === 'Country');
		if (countryField === undefined || countryField.length === 0) {
			return null;
		}
		const hasAllCountries = countries.every(v => v.code && countryField?.includes(v.code));
		if (hasAllCountries) {
			return { countries: 'all', value: countryField };
		}
		const specificCountries = countryField.length < (countries.length * 0.5);

		if (specificCountries) {
			const filteredCountries = countries.filter(x => x.code && countryField.includes(x.code)).map(x => x.code ?? '');
			return { countries: 'specific', value: filteredCountries };
		}
		const filteredCountries = countries.filter(x => x.code && !countryField.includes(x.code)).map(x => x.code ?? '');
		return { countries: 'except', value: filteredCountries };
	}
	async function confirmedDeleteProductType(val: { productTypeId: number, productTypeName: string | undefined }) {
		setOrgProductList(v => {
			const newArry = { ...v };
			newArry.matchRequestProducts = newArry.matchRequestProducts?.filter(j => j.productTypePickListItemId !== val.productTypeId);
			return newArry;
		});

		setProductTypes(v => v.filter(x => x !== val.productTypeName));
		return true;
	}
};

export default NewRequest;

