import React from 'react';
import { Button, Page } from 'components/core';
import { ReactComponent as IconRoundedTick } from 'assets/icons/icon-rounded-tick.svg';
import { ReactComponent as IconProfile } from 'assets/icons/icon-profile.svg';
import { ReactComponent as IconArrowRight } from 'assets/icons/icon-arrow-right.svg';
import { ReactComponent as IconTimer } from 'assets/icons/icon-timer.svg';
import OnboardingFooter from './OnBoarding/components/OnboardingFooter';
import OnboardingContainer from './OnBoarding/components/OnboardingContainer';


const LandingPage = () => {
	return (
		<Page title="Welcome to ScopeMatch" className="onboarding-wrapper">
			<OnboardingContainer>
				<h2 className="mb-1">Your account has been created</h2>
				<p className="mb-4">Set up the rest of your company profile to start using ScopeMatch</p>
				<div className="d-flex justify-content-between align-items-center">
					<IconRoundedTick className="progress-icon" />
					<span className="progress-bar-landing"></span>
					<IconProfile className="progress-icon" />
				</div>
				<div className="d-flex justify-content-between pt-1 pb-4 mb-2">
					<p className="fst-italic text-gray-700 mb-0">Create Account</p>
					<p className="fst-italic text-gray-700 mb-0">Set Up Company Profile</p>
				</div>
				<hr className="m-0" />
				<div className="pt-4 mt-2">
					<h2 className="">Setting up your company profile</h2>
					<span className="bg-light p-2 text-primary-400 fst-italic fw-bold mt-2">
						<IconTimer className="me-2" />Approximately 5–8 minutes
					</span>
					<p className="text-gray-800 mt-3">Keep in mind that the more details you provide,
						the higher the chance your company will be matched with a requisition.
						Details can be edited and changed later in your company profile.
					</p>
				</div>
				<div className="card rounded shadow mt-4">
					<div className="card-body p-4">
						<h5 className="mb-3">To start setting up your profile you will need the following information</h5>
						<ul className="mb-0">
							<li>Type of company and your main sectors and industries</li>
							<li>Setting a primary contact for ScopeMatch</li>
							<li>Location of your head office</li>
							<li>Other information such as company values, ect.</li>
						</ul>
					</div>
				</div>
			</OnboardingContainer>
			<OnboardingFooter>
				<Button to="/what-company-are-you" className="btn-primary">Set Up Profile<IconArrowRight className="ms-2" /></Button>
			</OnboardingFooter>
		</Page>
	);
};

export default LandingPage;

