import React from 'react';
import { ReactComponent as IconProductCatalog } from 'assets/icons/icon-product-catalog.svg';
import { ReactComponent as IconArrowRight } from 'assets/icons/icon-arrow-right-primary.svg';
import { ReactComponent as IconEdit } from 'assets/icons/icon-edit.svg';
import { Button } from 'components/core';


interface IProps {
	orgProducts?: ProductSummaryDTO[];
	showButton?: boolean;
}

const OrgProductCatalogCard = (props: IProps) => {
	return (
		<div className="card shadow p-3 mb-2 rounded">
			<div className="d-flex align-items-end mb-4">
				<div className="d-flex align-items-center">
					<div className="rounded-circle bg-secondary-100 p-2 me-3">
						<IconProductCatalog />
					</div>
					<h5 className="mb-0">Product Catalog</h5>
				</div>
				{props.showButton === true &&
					<div className="ms-auto">
						<Button className="text-primary" to="/company-profile/product-catalog">
							See Details<IconArrowRight className="ms-2" />
						</Button>
					</div>
				}
			</div>
			<div className="container-fluid px-0">
				<div className="row">
					{props?.orgProducts && props.orgProducts.length > 0 ? (
						props.orgProducts.map((products) => (
							<div
								className="col-6"
								key={`product-${products.productTypeCode}`}
							>
								<div className="d-flex">
									<p className="mb-0">
										{products.productTypeCode}
									</p>
									<p className="text-gray-600 fst-italic ms-auto mb-0">
										{products.numberOfProductsInType} {products.numberOfProductsInType === 1 ? 'Product' : 'Products'}
									</p>
								</div>
								<hr />
							</div>
						))
					) : (
						<React.Fragment>
							<p className="text-gray-600 fst-italic">No products added yet</p>
							<Button className="btn-primary flex-basis-content ms-3" to="/company-profile/product-catalog">
								Update Product Catalog<IconEdit className="ms-2" />
							</Button>
						</React.Fragment>
					)}
				</div>
			</div>
		</div>
	);
};

export default OrgProductCatalogCard;