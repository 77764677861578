import pickListAPI from 'admin/PickList/pickListAPI';
import { Button, DataTable, Page } from 'components/core';
import React from 'react';
import { Link } from 'react-router-dom';
import TitleBar from 'screens/Components/TitleBar';
import UploadImportFile from './components/uploadImportFile';

const columns = [
	{
		Header: 'Organization Id',
		accessor: 'id',
		show: true
	},
	{
		Header: 'Organization Name',
		accessor: 'organizationName'
	},
	{
		Header: 'Organization Type',
		accessor: 'organizationType'
	},
	{
		Header: 'Added / Updated',
		accessor: 'addedUpdated',
		Cell: (rowInfo: any) => {
			if (rowInfo === true) {
				return 'Added';
			} else {
				return 'Updated';
			}
		},
	},
];


const ImportData = () => {
	const pageTitle = 'Import Company Profile';
	const [importResults, setImportResults] = React.useState<OrganizationDTO[] | null>([]);
	return (
		<Page title={pageTitle}>
			<div className="d-flex">
				<div className="d-flex flex-column w-100">
					<TitleBar
						pageTitle={pageTitle}
					/>
				</div>
			</div>
			<div className="container-fluid py-3 px-5">
				<div>
					<p>
						Use this form to import company profile information, such as name, contacts, institutions, etc.
					</p>
					<p>The format of the importing data is very important.  This page expects a JSON file that contains all the company profile information</p>
					<p>Click the button below to download a template JSON file with sample data.</p>
					<Link className="btn btn-primary" to="/organization.json" target="_blank" download={true}>Download Template</Link>
				</div>
				<div className="mt-4">
					<p>In order to apply the correct Id values to the company profile data, you will need to reference the picklist data table.</p>
					<p>Download the csv file below to identify the correct Id values</p>
					<Button className="btn-primary" onClick={downloadPicklist}>Download PickList csv</Button>
				</div>
				<div className="mt-4">
					<p>Use the Browse button below to upload your new Organization JSON file.  Once the upload is complete, the data will be added to the Organization database.</p>
					<UploadImportFile disabled={false} setImportResults={setImportResults} />
				</div>
				<div className="mt-4">
					{importResults && importResults.length > 0 &&
						<DataTable
							columns={columns}
							data={importResults}
							sortable={true}
							noDataText=""
							showPagination={true}
							resizable={true}
						/>
					}
				</div>
			</div>
		</Page >
	);
	function downloadPicklist() {
		pickListAPI.getPickListCSV();
	}
};

export default ImportData;