import React from 'react';
import { FormGroup } from 'components/core';
import { PickListSelect } from 'components/scopematch/PickListSelect';
import { Controller, useFormContext } from 'react-hook-form';

interface IProps {
	value?: string;
	code: ProductSpecDTO;
}

export const DropDown = React.memo(({ code }: IProps) => {
	const methods = useFormContext();

	return (
		<Controller
			key={`${code.productSpecCode}`}
			control={methods.control}

			rules={{
				required: 'This is a required field.',
			}}
			name={`specs.${code.productSpecCode}`}
			render={({ field, fieldState }) => (
				<FormGroup field={field} fieldState={fieldState}>
					<PickListSelect
						id="select"
						name={field.name}
						value={field.value}
						pickListTypeName={code.productSpecCode ?? ''}
						placeholder=""
						additionalOptions={[{ value: 'any', label: 'Any' }]}
						onChange={field.onChange}
						onMenuOpen={methods.clearErrors}
						className="mb-n3 pb-n2 me-2 mt-2 table-select"
					/>
				</FormGroup>
			)}
		/>
	);
});
