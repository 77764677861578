import { IconSearch } from 'assets/icons';
import React, { useState } from 'react';

interface NewsToolbarProps {
	searchTerm: string;
	setSearchTerm: (value: string) => void;
	filterSentiment: SentimentOptions | null;
	setFilterSentiment: (value: SentimentOptions | null) => void;
	filterRelevance: RelevanceOptions | null;
	setFilterRelevance: (value: RelevanceOptions | null) => void;
	filterSource: string | null;
	setFilterSource: (value: string | null) => void;
	sortOption: string | null;
	setSortOption: (value: string | null) => void;
	sortDirection: 'asc' | 'desc';
	setSortDirection: (value: 'asc' | 'desc') => void;
}

function firstLetterUpperCase(str: string) {
	return str
		.split('-')
		.map((str) => str.charAt(0).toUpperCase() + str.slice(1))
		.join(' ');
}

function NewsToolbar({
	searchTerm,
	setSearchTerm,
	filterSentiment,
	setFilterSentiment,
	filterRelevance,
	setFilterRelevance,
	filterSource,
	setFilterSource,
	sortOption,
	setSortOption,
	sortDirection,
	setSortDirection,
}: NewsToolbarProps) {
	const [isFocused, setIsFocused] = useState(false); // State to track focus

	// Arrays for select input options
	const relevanceOptions: (RelevanceOptions | '')[] = ['', 'low', 'medium', 'high', 'very-high'];
	const sentimentOptions: (SentimentOptions | '')[] = ['', 'positive', 'negative', 'neutral'];
	const sourceOptions = [
		'',
		'zerohedge.com',
		'oilprice.com',
		'tradingeconomics.com',
		'supplychain247.com',
		'supplychaindive.com',
		'logisticsmgmt.com',
		'mmh.com',
		'bloomberg.com',
		'reuters.com',
	];
	const sortOptions = ['', 'date', 'relevance', 'sentiment'];

	return (
		<React.Fragment>
			<div className="row mb-3 overflow-auto news-toolbar">
			 	{/* d-flex flex-wrap justify-content-between align-items-center */}
				<div className="news-toolbar-container col-lg-6 col-md-12">
					{/* Filter by Source */}
					<div className="filter-item d-flex align-items-center mb-3">
						<label htmlFor="filter-by-source" className="label me-2">
      						Filter by source
						</label>
						<select
							className="form-select py-1 w-auto"
							id="filter-by-source"
							value={filterSource || ''}
							onChange={(e) => setFilterSource(e.target.value || null)}
						>
							{sourceOptions.map((source) => (
								<option key={source} value={source}>
									{source || 'All'}
								</option>
							))}
						</select>
					</div>
				</div>

				<div className="news-toolbar-container col-lg-6 col-md-12">
					{/* Search bar */}
					<div
						className={`input-group rounded border w-auto flex-1 ms-1 ${
							isFocused ? 'focused' : ''
						}`}
					>
						<input
							type="text"
							className="form-control py-1 search-input border-0 shadow-none"
							placeholder="Search by title or content"
							value={searchTerm}
							onChange={(e) => setSearchTerm(e.target.value)}
							onFocus={() => setIsFocused(true)} // Set focused state on focus
							onBlur={() => setIsFocused(false)} // Remove focused state on blur
						/>
						<span
							className="input-group-text border-0 bg-white"
							id="search-addon"
						>
							<IconSearch />
						</span>
					</div>
				</div>
			</div>

			<div className="row mb-3 overflow-auto news-toolbar">
				{/* d-flex flex-wrap justify-content-between align-items-center */}
				<div className="news-toolbar-container col-xl-4 col-lg-12">
					{/* Filter by Relevance */}
					<div className="filter-item d-flex align-items-center mb-3">
						<label htmlFor="filter-by-relevance" className="label me-2">
      						Filter by relevance
						</label>
						<select
							className="form-select py-1 w-auto"
							id="filter-by-relevance"
							value={filterRelevance || ''}
							onChange={(e) => setFilterRelevance(e.target.value as RelevanceOptions || null)}
						>
							{relevanceOptions.map((option) => (
								<option key={option} value={option}>
									{firstLetterUpperCase(option) || 'None'}
								</option>
							))}
						</select>
					</div>
				</div>

				<div className="news-toolbar-container col-xl-4 col-lg-12">
					{/* Filter by Sentiment */}
					<div className="filter-item d-flex align-items-center mb-3">
						<label htmlFor="filter-by-sentiment" className="label me-2">
      						Filter by sentiment
						</label>
						<select
							className="form-select py-1 w-auto"
							id="filter-by-sentiment"
							value={filterSentiment || ''}
							onChange={(e) => setFilterSentiment(e.target.value as SentimentOptions || null)}
						>
							{sentimentOptions.map((sentiment) => (
								<option key={sentiment} value={sentiment}>
									{firstLetterUpperCase(sentiment) || 'None'}
								</option>
							))}
						</select>
					</div>
				</div>

				<div className="news-toolbar-container col-xl-4 col-lg-12">
					{/* Sort by */}
					<div className="filter-item d-flex align-items-center mb-3">
						<label htmlFor="sort-by" className="label me-2">
      						Sort by
						</label>
						<select
							className="form-select py-1 w-auto me-2"
							id="sort-by"
							value={sortOption || ''}
							onChange={(e) => setSortOption(e.target.value || null)}
						>
							{sortOptions.map((option) => (
								<option key={option} value={option}>
									{firstLetterUpperCase(option) || 'None'}
								</option>
							))}
						</select>
						<select
							className="form-select py-1 w-auto"
							value={sortDirection}
							onChange={(e) => setSortDirection(e.target.value as 'asc' | 'desc')}
						>
							<option value="asc">ASC</option>
							<option value="desc">DESC</option>
						</select>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}

export default NewsToolbar;