import React, { useEffect, useState } from 'react';
import { Page } from 'components/core';
import TitleBar from 'screens/Components/TitleBar';
import NewsCard from 'screens/News/NewsCard';
import NewsAPIs from 'api/NewsApi';
import NewsToolbar from 'screens/News/NewsToolbar';

function News() {
	// General Page Info
	const pageTitle = 'News';

	const [articles, setArticles] = useState<Article[]>([]);
	const [filteredArticles, setFilteredArticles] = useState<Article[]>([]);
	const [searchTerm, setSearchTerm] = useState('');
	const [filterSentiment, setFilterSentiment] = useState<SentimentOptions | null>(null);
	const [filterRelevance, setFilterRelevance] = useState<RelevanceOptions | null>(null);
	const [filterSource, setFilterSource] = useState<string | null>(null);
	const [sortOption, setSortOption] = useState<string | null>('date');
	const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc'); // New state for sorting direction

	useEffect(() => {
		async function fetchArticles() {
			const response = await NewsAPIs.NewsapiGetArticles();
			//response.articles GetNews
			const fetchedArticles = response ? response : [];
			setArticles(fetchedArticles);
			setFilteredArticles(fetchedArticles); // Initialize with all articles
		}

		fetchArticles();
	}, []);

	useEffect(() => {
		let updatedArticles = [...articles];

		// Apply search filter
		if (searchTerm) {
			updatedArticles = updatedArticles.filter(
				(article) =>
					article.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
					article.body?.toLowerCase().includes(searchTerm.toLowerCase())
			);
		}

		if (filterSource) {
			updatedArticles = updatedArticles.filter((article) => article.source?.uri === filterSource);
		}

		// Apply sentiment filter
		if (filterSentiment) {
			updatedArticles = updatedArticles.filter((article) => {
				const sentiment = article.sentiment?.toFixed(2);
				if (filterSentiment === 'positive') {
					return (
						parseFloat(sentiment) > 0.25 &&
						parseFloat(sentiment) < 1
					);
				} else if (filterSentiment === 'negative') {
					return (
						parseFloat(sentiment) > -1 &&
						parseFloat(sentiment) < -0.25
					);
				} else if (filterSentiment === 'neutral') {
					return (
						parseFloat(sentiment) > -0.25 &&
						parseFloat(sentiment) < 0.25
					);
				} else {
					return sentiment;
				}
			});
		}

		// Apply relevance filter
		if (filterRelevance) {
			updatedArticles = updatedArticles.filter((article) => {
				const relevance = article.relevance;
				if (filterRelevance === 'low') {
					return relevance < 30;
				} else if (filterRelevance === 'medium') {
					return (
						relevance > 30 && relevance < 60
					);
				} else if (filterRelevance === 'high') {
					return (
						relevance > 60 &&
						relevance < 100
					);
				} else if (filterRelevance === 'very-high') {
					return relevance > 100;
				} else {
					return relevance;
				}
			});
		}

		// Apply sorting
		if (sortOption) {
			updatedArticles.sort((a, b) => {
				let comparison = 0;
				if (sortOption === 'date') {
					comparison =
						new Date(a.dateTime).getTime() - new Date(b.dateTime).getTime();
				} else if (sortOption === 'relevance') {
					comparison = a.relevance - b.relevance;
				} else if (sortOption === 'sentiment') {
					comparison = a.sentiment - b.sentiment;
				}
				// Apply ascending or descending direction
				return sortDirection === 'asc' ? comparison : -comparison;
			});
		}

		setFilteredArticles(updatedArticles);
	}, [searchTerm, filterSentiment, filterRelevance, sortOption, sortDirection, articles, filterSource]);

	return (
		<Page title={pageTitle}>
			<div className="d-flex">
				<div className="d-flex flex-column w-100">
					<TitleBar pageTitle={pageTitle} />
				</div>
			</div>
			<div className="container-fluid mb-2 px-5 py-3">
				<NewsToolbar
					searchTerm={searchTerm}
					setSearchTerm={setSearchTerm}
					filterSentiment={filterSentiment}
					setFilterSentiment={setFilterSentiment}
					filterRelevance={filterRelevance}
					setFilterRelevance={setFilterRelevance}
					filterSource={filterSource}
					setFilterSource={setFilterSource}
					sortOption={sortOption}
					setSortOption={setSortOption}
					sortDirection={sortDirection}
					setSortDirection={setSortDirection}
				/>
				<div className="row mt-5">
					{filteredArticles.map((article, index) => (
						<div className="col-lg-6 col-md-12" key={index}>
							<NewsCard
								dateTime={article.dateTime}
								location={article.location || ''}
								source={article.source}
								url={article.url}
								relevance={article.relevance}
								sentiment={article.sentiment}
								title={article.title}
								body={article.body}
							/>
						</div>
					))}
				</div>
			</div>
		</Page>
	);
}

export default News;
