import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import coreAPI from 'admin/Users/coreAPI';
import { TextArea, RadioGroup, Input, Button, Page, PageHero, FormGroup } from 'components/core';
import { BrowserDetails, FeedbackMetaDetails, MobileDetails } from './StaticDetails';
import { feedbackTypeList } from './FeedbackForm';
import { useToast } from 'components/customHooks/useToast';

interface FeedbackForm {
	description: string;
	priority: number;
	severity: string;
	feedbackType: FeedbackTypeEnum;
}

const FeedbackDetails =  () => {
	const params = useParams();
	const navigate = useNavigate();
	const [feedback, setFeedback] = React.useState<FeedbackDTO | null>(null);
	const [loading, setLoading] = React.useState<boolean>(true);
	const { handleSubmit, control, reset, getValues } = useForm<FeedbackForm>({
		mode: 'onSubmit',
		reValidateMode: 'onChange'
	});

	const toast = useToast();

	const submitData = React.useCallback(async () => {
		if (feedback == null) {
			return;
		}
		const result = await coreAPI.feedback.postFeedback({ ...feedback, ...getValues() });
		if (result) {
			toast.success('Feedback Saved');
			navigate('/feedback/feedbacklist');
		} else {
			toast.error('Unable to save Feedback');
		}
	}, [feedback, getValues, navigate, toast]);
	const dismissFeedback = React.useCallback(async () => {
		if (feedback == null) {
			return;
		}
		const response = await coreAPI.feedback.dismissFeedback(feedback.id!);
		if (response) {
			toast.success('Feedback Dismissed');
			navigate('/feedback/feedbacklist');
		} else {
			toast.error('Unable to dismiss Feedback');
		}
	}, [feedback, navigate, toast]);

	const completeFeedback = React.useCallback(async () => {
		if (feedback == null) {
			return;
		}
		const result = await coreAPI.feedback.completeFeedback(feedback.id!);
		if (result) {
			toast.success('Feedback Completed');
			navigate('/feedback/feedbacklist');
		} else {
			toast.error('Unable to complete Feedback');
		}
	}, [feedback, navigate, toast]);

	const elevateFeedback = React.useCallback(async () => {
		if (feedback == null) {
			return;
		}
		const result = await coreAPI.feedback.elevateFeedback(feedback.id!);
		if (result) {
			toast.success('Feedback Elevated');
			navigate('/feedback/feedbacklist');
		} else {
			toast.error('Unable to elevate Feedback');
		}
	}, [feedback, navigate, toast]);

	React.useEffect(() => {
		async function getFeedback(feedbackId: number) {
			const result = await coreAPI.feedback.getFeedback(feedbackId);
			if (result != null) {
				setFeedback(result);
				reset(result);
			} else {
				toast.error('Unable to retrieve Feedback information');
			}
			setLoading(false);
		}
		getFeedback(Number(params.feedbackId));
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (loading || feedback == null) {
		return null;
	}
	return (
		<Page title="Feedback">
			<PageHero title="Feedback Details"/>
			<div className="container-fluid">
				<div className="mb-3">
					<FeedbackMetaDetails {...feedback} />
				</div>
				{feedback.deviceOs != null &&
					<div className="mb-3">
						<MobileDetails {...feedback} />
					</div>
				}
				{feedback.browser != null &&
					<div className="mb-3">
						<BrowserDetails {...feedback} />
					</div>
				}
				<div className="row">
					<div className="col-lg-3">
						<Controller
							control={control}
							name="feedbackType"
							rules={{
								required: 'This field is required',
							}}
							render={({ field, fieldState }) =>
								<FormGroup label="Feedback type" field={field} fieldState={fieldState}>
									<RadioGroup
										options={feedbackTypeList}
										{...field}
									/>
								</FormGroup>
							}
						/>
					</div>
				</div>
				<div className="row">

					<div className="col-lg-3">
						<Controller
							control={control}
							name="priority"
							rules={{
								required: 'This field is required',
							}}
							render={({ field, fieldState }) =>
								<FormGroup label="Feedback Priority" field={field} fieldState={fieldState}>
									<Input
										type="number"
										error={!!fieldState.error?.message}
										{...field}
									/>
								</FormGroup>
							}
						/>
					</div>
					<div className="col-lg-3">
						<Controller
							control={control}
							name="severity"
							rules={{
								required: 'This field is required',
							}}
							render={({ field, fieldState }) =>
								<FormGroup label="Feedback Severity" field={field} fieldState={fieldState}>
									<Input
										error={!!fieldState.error?.message}
										{...field}
									/>
								</FormGroup>
							}
						/>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-6">
						<Controller
							control={control}
							name="description"
							render={({ field, fieldState }) => (
								<FormGroup label="Description" field={field} fieldState={fieldState}>
									<TextArea
										id={field.name}
										error={!!fieldState.error?.message}
										{...field}
									/>
								</FormGroup>
							)
							}
						/>
					</div>
				</div>
			</div>
			<Button
				className="btn-primary ms-3"
				onClick={handleSubmit(submitData)}
			>
				Save
			</Button>
			<Button
				className="btn-outline-primary ms-3"
				onClick={() => {
					navigate('/feedback/feedbacklist');
				}}
			>
				Cancel
			</Button>
			<Button
				className="btn-outline-info ms-3"
				onClick={dismissFeedback}
			>
				Dismiss Feedback
			</Button>
			<Button
				className="btn-outline-info ms-3"
				onClick={completeFeedback}
			>
				Conclude Feedback
			</Button>
			<Button
				className="btn-outline-info ms-3"
				onClick={elevateFeedback}
			>
				Elevate Feedback
			</Button>


		</Page>
	);
};

export default FeedbackDetails;
