// AccordionButton.js

import React from 'react';
import classNames from 'classnames';
import { ReactComponent as IconCircleTick } from 'assets/icons/icon-circle-tick.svg';
import SpecCountPill from '../pills/SpecCountPill';

interface AccordionButtonProps {
	activeIndex: number;
	productkey: number;
	activeIDs: number[];
	requestProducts: ProductMatchTypeGroupDTO;
	resultsPage: boolean | undefined;
	onClick: (index: number, id: number) => void;
}

const containsAny = (group: ProductMatchTypeGroupDTO) => group.productGroups?.every((productGroup) =>
	productGroup.productSpecCodes?.every((product) =>
		product.productSpecValue === 'Any'
	)
);

const AccordionButton: React.FC<AccordionButtonProps> = ({ productkey, activeIndex, activeIDs, requestProducts, resultsPage, onClick }) => (



	containsAny(requestProducts) ? (
		<p className={classNames('mb-0 fw-normal py-3', !resultsPage && !hasSpecMatches(requestProducts) && 'text-gray-600', !resultsPage && hasSpecMatches(requestProducts) && 'text-success-700')}>
			{hasSpecMatches(requestProducts) && !resultsPage && (
				<IconCircleTick className="me-2 icon-sizing" />
			)}
			{requestProducts.productTypeCode}
		</p>
	) : (
		<button
			className={classNames('accordion-button align-items-center px-0', !activeIDs.includes(productkey) && 'collapsed')}
			type="button"
			data-bs-toggle="collapse"
			data-bs-target={`#flush-collapse-${productkey}`}
			aria-expanded="false"
			aria-controls={`flush-collapse-${productkey}`}
			onClick={() => {
				onClick(activeIndex, productkey);
			}}
		>
			<React.Fragment>
				{hasSpecMatches(requestProducts) === true && !resultsPage ? (
					<React.Fragment>
						<IconCircleTick className="me-2 icon-sizing" />
						<p className={classNames('mb-0 text-success-700', !activeIDs.includes(productkey) && 'fw-normal')}>
							{requestProducts.productTypeDescription}
						</p>
					</React.Fragment>
				) : (
					<p className={classNames('mb-0', !resultsPage && 'text-gray-600', !activeIDs.includes(productkey) && 'fw-normal')}>
						{requestProducts.productTypeDescription}
					</p>
				)}

				<span className="ms-auto number-of-products">
					{!resultsPage ?
						(
							<SpecCountPill
								specMatchedCount={requestProducts.productGroups && requestProducts.productGroups
									.map(group => group.productSpecCodes!
										.filter(products => products.matched === true)
										.length)
									.reduce((acc, curr) => acc + curr, 0)}
								specTotalCount={requestProducts.productGroups && requestProducts.productGroups
									.map(group => group.productSpecCodes!.length)
									.reduce((acc, curr) => acc + curr, 0)}
							/>
						) : (
							<p className="text-gray-600 fst-italic mb-0">
								{requestProducts.productGroups && requestProducts.productGroups
									.map(group => group.productSpecCodes!.length)
									.reduce((acc, curr) => acc + curr, 0)} Specs
							</p>
						)
					}
				</span>
			</React.Fragment>
		</button>
	)
);

export default AccordionButton;

function hasSpecMatches(productGroup: any) {
	var match: boolean = false;
	productGroup && productGroup.productGroups.map((group: any) => {
		group.productSpecCodes && group.productSpecCodes.map((products: any) => {
			if (products.matched === true) { match = true; }
		});
	});
	return match;
}