import React from 'react';

const initialState = {
	currentUser: {} as CurrentUserDTO,
	currentUniqueId: '',
	settings: {} as any,
	isInitializing: true,
	refreshUser: async () => true,
};
const AuthContext = React.createContext(initialState);

export default AuthContext;