import { ModalOverlay } from 'components/core';
import React from 'react';

interface IProps {
	id: { productTypeId: number, productTypeName: string } | null;
	onRequestClose: () => void;
	deleteRow: (val: { productTypeId: number, productTypeName: string | undefined }) => Promise<boolean>;
}

const DeleteProductTypeModal = (props: IProps) => {
	const { id, onRequestClose, deleteRow } = props;
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	return (
		<ModalOverlay
			isOpen={id !== null}
			modalSize="modal-dialog-centered"
			onRequestClose={onRequestClose}
			confirmButtonChildren="Delete"
			cancelButtonChildren="Cancel"
			confirmButtonAction={deleteAndClose}
			cancelButtonAction={onRequestClose}
			isLoading={isLoading}
			hideCloseButton={true}
		>
			<h3>Are you sure you want to delete "{id?.productTypeName}"?</h3>
			<p>You will lose all product details under this product. This action cannot be undone.</p>
		</ModalOverlay>

	);
	async function deleteAndClose() {
		if (id !== null) {
			setIsLoading(true);
			await deleteRow(id);
			setIsLoading(false);
			onRequestClose();
		}
	}

};

export default DeleteProductTypeModal;