import React from 'react';
import { FormGroup, Page } from 'components/core';
import OnBoardingProgressBar from './components/ProgressBar';
import { Controller, useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import OnboardingFooter from './components/OnboardingFooter';
import OnboardingContainer from './components/OnboardingContainer';
import { FormContext } from './FormContext';
import BackForwardButtons from './components/BackForwardButtons';
import AsyncSelect from 'react-select/async';
import { debounce } from 'lodash';
import scopeMatchAPI from 'api/ScopeMatchAPI';


type LocationsType = { countryDropdown?: string } & OrganizationRequest;

const Locations =  () => {
	const { control, getValues, setValue } = useFormContext<LocationsType>();
	const FormCxt = React.useContext(FormContext);
	const location = useLocation();
	const organizationType = getValues('organizationType');


	const fetchLocationSuggestions = debounce((inputValue, callback) => {
		if (inputValue.length <= 2) {
			callback([]);
			return;
		}

		scopeMatchAPI.locationSuggestions.GetLocationSuggestions(inputValue)
			.then(response => {
				const suggestions = response.map((suggestion: { location: string; }) => ({
					label: suggestion.location,
					value: suggestion.location
				}));
				callback(suggestions);
			})
			.catch(error => {
				console.error(error);
				callback([]);
			});
	}, 1000);

	return (
		<Page
			title={organizationType?.toString() === 'distributor' ? 'Distribution Centre Locations' : 'Manufacturing Locations'}
			className="onboarding-wrapper"
		>
			<OnboardingContainer>
				<OnBoardingProgressBar indexInfo={FormCxt.CurrentIndex(organizationType, location.pathname)} />
				<h2 className="pt-4 mb-2">{organizationType?.toString() === 'distributor' ? ('Distribution Center') : ('Manufacturing')} Locations</h2>
				<p className="mb-2">This information will help match your company to requests with specific
					{organizationType?.toString() === 'distributor' ? (' distributing') : (' manufacturing')} location requirements
				</p>
				<p className="text-gray-700 fst-italic mb-4">You can skip this step and complete this later in the dashboard</p>

				<Controller
					control={control}
					name="manufacturingLocation"
					render={({ field, fieldState }) =>
						<FormGroup
							label={
								organizationType?.toString() === 'distributor' ?
									('Where do you have distribution warehouses? ') :
									('Where are your manufacturing facilites located? ')
							}
							field={field}
							fieldState={fieldState}
						>
							<span className="fw-normal fst-italic text-gray-700">
								(Enter cities or closest cities, add all locations)</span>
							<AsyncSelect
								id="manufacturing-location-select"
								isMulti={true}
								name={field.name}
								value={field.value ? field.value.map(val => ({ label: val, value: val })) : []}
								loadOptions={fetchLocationSuggestions}
								onChange={(selectedOptions) => {
									const selectedValues = selectedOptions ?
										selectedOptions.map(option => option.value) : [];
									field.onChange(selectedValues);
								}}
								placeholder="Please start typing your locations..."
								isClearable={true}
							/>
						</FormGroup>
					}
				/>

			</OnboardingContainer>
			<OnboardingFooter>
				<BackForwardButtons beforeSkipping={beforeSkipping} />
			</OnboardingFooter>
		</Page>
	);
	function beforeSkipping() {
		setValue('manufacturingLocation', undefined);
	};

};

export default Locations;


