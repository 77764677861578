import React, { useState } from 'react';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import { Spinner } from 'components/core';

interface IImport {
	selectedFiles: ISelectedFiles[],
	progressInfos: IProgressInfos[],
	message: string[],
	fileInfos: any[],
	modalOpen: boolean,
	deleteId: number,
}
interface IProgressInfos {
	fileName: string,
	percentage: number,
}
interface ISelectedFiles {
	name: string,
}
interface IUploadProps {
	disabled: boolean;
	upload(idx: number, file: any): any;
}


const UploadFilesComponentHook = (props: IUploadProps) => {
	const [uploadInfo, setUploadInfo] = React.useState<IImport>({
		selectedFiles: [],
		progressInfos: [],
		message: [],
		fileInfos: [],
		modalOpen: false,
		deleteId: 0,
	}
	);
	const [showSpinner, setShowSpinner] = useState<Boolean>(true);

	function selectFiles(event: any) {
		setUploadInfo({ ...uploadInfo, selectedFiles: event.target.files, });
		uploadFiles(event.target.files);
		event.target.value = null;
	}

	async function uploadFiles(selectedFiles: any[]) {
		let _progressInfos = [];
		if (selectedFiles) {
			for (let i = 0; i < selectedFiles.length; i++) {
				_progressInfos.push({ percentage: 0, fileName: selectedFiles[i].name });
			}
		}
		setUploadInfo(
			{
				...uploadInfo,
				progressInfos: _progressInfos,
				message: [],
			});
		for (let i = 0; i < selectedFiles.length; i++) {
			await props.upload(i, selectedFiles[i]);
		}
		setShowSpinner(false);
	}

	return (
		<div>
			<div className="row my-3">
				<div className="col-12">
					<label className="btn btn-default w-100 p-0">
						<input disabled={props.disabled}
							className={classNames('form-control', uploadInfo.fileInfos.length === 0 && 'is-invalid')} type="file" title="Input" onChange={selectFiles} />
					</label>
				</div>
			</div>
			<div className="card">
				<ul className="list-group list-group-flush">
					{uploadInfo.fileInfos && uploadInfo.fileInfos.map((file: any, index: number) => (
						file.deleted === false &&
						<li className="list-group-item" key={`file-${index}`}>
							<div className="d-flex">
								<a target="_blank" rel="noreferrer" href={file.documentURL} download={true} className="text-wrap align-self-center mw-100">
									{file.documentName}
								</a>
							</div>
							<small className="d-block mt-1 text-gray-600">Uploaded {DateTime.fromISO(file.uploadDate).toLocaleString(DateTime.DATE_MED)}</small>
						</li>
					))}
					{uploadInfo.progressInfos && uploadInfo.progressInfos.map((progress: any, index: number) => (
						<li className="list-group-item" key={`progress-${index}`}>
							<span>{progress.fileName}{showSpinner && <Spinner small={true} className="ms-2 text-secondary" />}</span>
						</li>
					)
					)}
				</ul>
			</div>
		</div>
	);
};

export default UploadFilesComponentHook;