import { ModalOverlay } from 'components/core';
import React from 'react';

interface IProps {
	receiveOrganizationName: string | undefined;
	onRequestClose: () => void;
	sendEmail: () => void;
}

const SendEmailModal = (props: IProps) => {
	const { receiveOrganizationName, onRequestClose, sendEmail } = props;
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	return (
		<ModalOverlay
			isOpen={receiveOrganizationName !== null}
			modalSize="modal-dialog-centered"
			onRequestClose={onRequestClose}
			headerChildren={`Would you like to send an introductory email to ${receiveOrganizationName} with the details of your match?`}
			confirmButtonChildren="Send email"
			cancelButtonChildren="Not right now"
			confirmButtonAction={sendEmailAndClose}
			cancelButtonAction={onRequestClose}
			isLoading={isLoading}
			hideCloseButton={true}
		>
			<p>You can do this any time from the Match Details screen</p>
		</ModalOverlay>

	);
	async function sendEmailAndClose() {

		if (receiveOrganizationName !== null) {
			setIsLoading(true);
			await sendEmail();
			setIsLoading(false);
			onRequestClose();
		}
	}

};

export default SendEmailModal;