import React from 'react';
import classNames from 'classnames';
import { ReactComponent as IconManufacturer } from 'assets/icons/icon-manufacturer.svg';
import { ReactComponent as IconDistributor } from 'assets/icons/icon-distributer.svg';
import { ReactComponent as IconOwner } from 'assets/icons/icon-owner.svg';

interface IProps {
	onChange: (value: string) => void;
	value?: string;
}

const CompanyType = (props: IProps) => {

	return (

		<div className="d-flex">
			<TypeButton
				title="Manufacturer"
				icon={<IconManufacturer />}
				value="manufacturer"
				active={props.value}
				onClick={(v) => props.onChange(v)}
			/>
			<TypeButton
				title="Distributor"
				icon={<IconDistributor />}
				value="distributor"
				active={props.value}
				onClick={(v) => props.onChange(v)}
			/>
			<TypeButton
				title="Owner"
				icon={<IconOwner />}
				value="owner"
				active={props.value}
				onClick={(v) => props.onChange(v)}
			/>
		</div>
	);
};

interface ITypeButtonProps {
	title: string;
	value: string;
	icon: React.ReactNode;
	active?: string;
	onClick: (value: string) => void;
}

const TypeButton = (props: ITypeButtonProps) => {

	const isActive = props.active === props.value;

	return (
		<button
			onClick={() => props.onClick(props.value)}
			className="card shadow rounded onboarding-card d-flex me-3 p-0">
			<div className={classNames('card-body rounded d-flex align-items-center justify-content-center w-100', isActive === true && 'bg-light border border-primary border-2 active-icon')}>
				<div className="form-check position-absolute top-0 end-0 mt-3 me-3">
					<input
						className={classNames('form-check-input rounded bg-primary border border-primary border-2 checked', isActive === false && 'd-none')}
						type="radio" name="companyCheck" id="companyCheck" />
				</div>
				<div className="form-check-label text-center">
					{props.icon}
					<h5 className={classNames('card-title mt-2', isActive ? 'text-primary' : 'text-dark')} >{props.title}</h5>
				</div>
			</div>
		</button >
	);
};

export default CompanyType;
