import classNames from 'classnames';
import React from 'react';


const OnBoardingProgressBar = (props: { indexInfo: IPageIndex | undefined }) => {

	const progressBars = [];
	for (let i = 1; i <= props.indexInfo?.totalIndex!; i++) {
		progressBars.push(
			<div
				key={i}
				className={classNames(
					'progress-bar rounded me-1',
					props.indexInfo?.currentIndex === i && 'progress-active',
					props.indexInfo?.currentIndex! > i && 'progress-completed'
				)}
				role="progressbar"
			></div>
		);
	}

	return (
		<React.Fragment>
			<div className="progress mb-2 mt-3">
				{progressBars}
			</div>
			<p className="mb-0">{props.indexInfo?.currentIndex} of {props.indexInfo?.totalIndex}</p>
		</React.Fragment>
	);
};

export default OnBoardingProgressBar;