import React from 'react';
import { ReactComponent as IconCompanyValues } from 'assets/icons/icon-company-values.svg';
import Pill from './pills/Pill';


interface IProps {
	orgCompanyValues?: string[];
}

const OrgCompanyValuesCard = (props: IProps) => {
	if (props.orgCompanyValues == undefined || props.orgCompanyValues.length == 0) {
		return <React.Fragment />;
	}
	return (
		<div className="card shadow p-3 mb-2 rounded">
			<div className="d-flex align-items-center">
				<div className="rounded-circle bg-secondary-100 p-2 me-3">
					<IconCompanyValues />
				</div>
				<h5 className="mb-0">Sustainability and Corporate Values</h5>
			</div>
			<hr />
			<div className="d-flex flex-wrap">
				{props?.orgCompanyValues && props.orgCompanyValues.length > 0 ? (
					props.orgCompanyValues.map((values, sckey) => (
						<Pill
							className="text-primary fw-bold"
							key={`company-value-${sckey}`}
						>
							{values}
						</Pill>
					))
				) : (
					<p className="text-gray-600 fst-italic">No sustainability and corporate values specified</p>
				)}
			</div>
		</div>
	);
};

export default OrgCompanyValuesCard;