import React from 'react';
import { Page, PageHero, DataTable, Spinner } from 'components/core';
import { Button, IconButton, setStatusCellValue, filterByStatus, StatusFilterMethod } from 'components/core';
import pickListAPI from './pickListAPI';
import AuthorizationWrapper from 'auth/AuthorizationWrapper';
import { Permissions } from 'auth/Permissions';
import { IconChevronRight } from 'assets/icons';

const PickListTypeListPage = () => {
	const [pickListTypes, setPickListTypes] = React.useState<PickListItem[]>([]);
	const [isLoading, setIsLoading] = React.useState<Boolean>(false);
	React.useEffect(() => {
		const fetchPickListTypes = async () => {
			setIsLoading(true);
			const res = await pickListAPI.getPickListTypes();
			setIsLoading(false);
			setPickListTypes(res);
		};

		fetchPickListTypes();
	}, []);

	const [filtered, setFiltered] = React.useState<any[]>([]);
	const columns = [
		{
			Header: 'Code',
			accessor: 'code',
		},
		{
			Header: 'Description',
			accessor: 'description'
		},
		{
			Header: 'Status',
			accessor: 'isActive',
			Cell: setStatusCellValue,
			filterMethod: filterByStatus('isActive'),
			Filter: StatusFilterMethod
		},
		{
			Header: 'Actions',
			accesor: 'id',
			filterable: false,
			Cell: (rowValue: any) => ( // turn into a component
				<IconButton
					className="btn-link"
					to={`/pick-lists/${rowValue.original.id}/pick-list-items`}
					onClick={() => console.log(rowValue)}
				>
					<IconChevronRight />
				</IconButton>
			)
		}
	];

	return (
		<Page title="Pick list types">
			<PageHero title="Picklist Items" />
			<div className="container-fluid">
				<div className="d-flex mb-4">
					<AuthorizationWrapper type="hide" permissions={[Permissions.WritePickList]}>
						<Button className="btn-primary ms-auto" to="/pick-lists/0">
							Add pickList type
						</Button>
					</AuthorizationWrapper>
				</div>
				{isLoading ? <Spinner /> :
					<DataTable
						data={pickListTypes}
						columns={columns}
						filterable={true}
						filtered={filtered}
						onFilteredChange={(f: any[]) => setFiltered(f)}
					/>}
			</div>
		</Page>
	);
};

export default PickListTypeListPage;