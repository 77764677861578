import React from 'react';
import { LoadingPage, Page } from 'components/core';
import TitleBar from 'screens/Components/TitleBar';
import scopeMatchAPI from 'api/ScopeMatchAPI';
import { IconArrowRight } from 'assets/icons';
import { ReactComponent as IconRequest } from 'assets/icons/icon-request-secondary.svg';
import { ReactComponent as IconSavedMatch } from 'assets/icons/icon-bookmark.svg';
import { Link } from 'react-router-dom';
import ForceGraphInit from 'dataVisualization/ForceGraphInit';
import PickListContext from 'context/PickListContext';


const Dashboard = () => {
	const [orgDetails, setOrgDetails] = React.useState<OrganizationDTO | null>();
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [chartData, setChartData] = React.useState<any>(null);
	const pageTitle = 'Dashboard';
	const insightsRef = React.useRef<HTMLDivElement>(null);
	const picklistCxt = React.useContext(PickListContext);
	const [chartDimensions, setChartDimensions] = React.useState<{ height: number; width: number; }>();

	React.useEffect(() => {
		setIsLoading(true);
		const fetchOrgDetails = async () => {
			const orgDetailsResults = await scopeMatchAPI.organization.GetCompanyProfile();
			const orgDashboardData = await scopeMatchAPI.dataVisualization.GetNodeVisualizationForDashboard();
			setChartData(orgDashboardData);
			setOrgDetails(orgDetailsResults);
			setIsLoading(false);
		};
		fetchOrgDetails();

	}, []);
	React.useEffect(() => {
		if (insightsRef.current !== null) {
			setChartDimensions({ height: insightsRef.current.offsetHeight, width: insightsRef.current.offsetWidth });
		}
	}, []);

	if (isLoading === true) {
		return <LoadingPage />;
	}
	const orgType = orgDetails?.organizationType?.toString() ?? '  ';
	const capitalizedOrgType = orgType.charAt(0).toUpperCase() + orgType.slice(1);
	return (
		<Page title={pageTitle}>
			<div className="d-flex">
				<div className="d-flex flex-column w-100">
					<TitleBar
						pageTitle={pageTitle}
					/>
				</div>
			</div>
			<div className="container-fluid d-flex flex-fill flex-column py-3 px-5">
				<div>
					<h3 className="mb-2 pb-3">{orgDetails?.organizationName}</h3>
					<div className="steps-card-container">
						<Link to="/requests" className="card shadow rounded text-decoration-none">
							<div className="card-body d-flex p-4 m-n2 align-items-center">
								<div className="align-self-start">
									<div className="rounded-circle bg-secondary-100 p-2">
										<IconRequest />
									</div>
								</div>
								<div className="d-block mx-3">
									<h6 className="fs-14">REQUEST</h6>
									<h4 className="mb-0">{orgDetails?.numberofRequests}</h4>
								</div>
								<div className="ms-auto">
									<IconArrowRight />
								</div>
							</div>
						</Link>

						<Link to="/match-history" className="card shadow rounded text-decoration-none">
							<div className="card-body d-flex p-4 m-n2 align-items-center">
								<div className="align-self-start">
									<div className="rounded-circle bg-secondary-100 p-2">
										<IconSavedMatch />
									</div>
								</div>
								<div className="d-block mx-3">
									<h6 className="fs-14">SAVED MATCHES</h6>
									<h4 className="mb-0">{orgDetails?.numberofSavedMatches}</h4>
								</div>
								<div className="ms-auto">
									<IconArrowRight />
								</div>
							</div>
						</Link>
					</div>
				</div>
				<div className="d-flex flex-fill flex-column pt-4">
					<h4>{orgDetails?.organizationName} Network Map</h4>
					<div className="d-flex flex-fill">
						<div className="col-9 d-flex flex-fill">
							<div className="shadow card d-flex flex-fill flex-column" ref={insightsRef}>
								{chartDimensions && chartData ? <ForceGraphInit data={chartData} chartheight={chartDimensions.height} chartwidth={chartDimensions.width} />
									:
									<div className="d-flex align-items-center justify-content-center flex-grow-1">Not enough information to create your network map.</div>
								}
							</div>
						</div>
						<div className="col-3 shadow card p-3">
							<div className="card-header">
								<h5>Network Map</h5>
							</div>
							<div className="card-body">
								<p
									className="text-gray-700 mb-1 fs-14 fw-bold letter-spacing"
									dangerouslySetInnerHTML={{
										__html: picklistCxt.pickListItems.find(
											x => x.code === `NetworkMapText${capitalizedOrgType}` &&
												x.pickListType?.code === 'NetworkMapText')?.description ?? ''
									}}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Page >
	);
};

export default Dashboard;

