
import classNames from 'classnames';
import React from 'react';

interface IProps {
	className?: string;
}

const SquarePill = (props: React.PropsWithChildren<IProps>) => {
	const { children } = props;

	return (
		<span
			className={classNames('border rounded bg-success-100 border-success border-2 px-2 py-1 text-success-700 my-1  fs-16 d-inline-block', props.className)}
		>
			{children}
		</span>
	);
};

export default SquarePill;