import React from 'react';
import { Button, LoadingPage, Page } from 'components/core';
import TitleBar from 'screens/Components/TitleBar';
import { useParams } from 'react-router-dom';
import RequestDetails from './components/requestDetails/RequestDetails';
import { RequestContext } from './RequestContext';
import MatchCard from './components/MatchCard';
import { ReactComponent as IconNoMatches } from 'assets/icons/icon-no-suggested.svg';
import classNames from 'classnames';

const SuggestedMatches = () => {
	const { isLoading, request, requestPanel, searchResults } = React.useContext(RequestContext);
	const params = useParams();
	const requestId = Number(params.requestID);
	const searchResultsMap = searchResults && searchResults
		.filter(result => result.percentMatch !== 0 && result.isApprovedMatch === false)
		.sort((a, b) => (b.percentMatch ?? 0) - (a.percentMatch ?? 0));
	const searchResultsApprovedMap = searchResults && searchResults
		.filter(result => result.percentMatch !== 0 && result.isApprovedMatch === true)
		.sort((a, b) => (b.percentMatch ?? 0) - (a.percentMatch ?? 0));

	if (isLoading) {
		return <LoadingPage />;
	}

	return (
		<Page title={request?.requestName}>
			<div className="d-flex matches-scrollable-container h-100">
				<div className="d-flex flex-column w-100">
					<TitleBar
						pageTitle={request?.requestName}
						backButton={true}
					/>
					<div className="container-fluid h-100 gx-0 d-flex ">
						<div className="matches-left-panel pb-7 mt-n3 border-end">
							<RequestDetails
								requestDetails={requestPanel}
								showEdit={true}
								showHighlight={false}
								resultsPage={true}
							/>
						</div>
						<div
							className={classNames('matches-right-panel px-4 mt-n3 pt-3', searchResultsApprovedMap.length === 0 && searchResultsMap.length === 0 ? 'd-flex' : 'pb-7')}
						>
							{searchResultsApprovedMap.length === 0 && searchResultsMap.length === 0 ? (
								<div className="container-fluid d-flex">
									<div className="text-center bg-light py-5 rounded px-4 mb-3 d-flex flex-fill">
										<div className="flex-fill align-self-center">
											<IconNoMatches />
											<h5 className="mb-2">No Match Suggestions Found</h5>
											<p className="mb-2 pb-3">We couldn’t find any companies that match your request. Come back later or edit your request.</p>
											<Button
												to={`/requests/${requestPanel?.id}`}
												className="btn-primary ms-auto"
											>
												Edit Request
											</Button>
										</div>
									</div>
								</div>
							) : (
								<React.Fragment>
									{
										searchResultsApprovedMap.length > 0 &&
										<div className="mb-3">
											<p className="text-gray-700 mb-1 fs-14 fw-bold">SAVED MATCHES</p>
											<hr className="m-0" />
											{searchResultsApprovedMap
												.map((result) => {
													return (
														<MatchCard
															key={`saved-match-${result.id}-for-${result.organizationID}`}
															matchDetails={result}
															requestId={requestId}
															requestDetails={requestPanel} />
													);
												})}
										</div>
									}
									{searchResultsMap.length > 0 &&
										<div className="mb-5">
											<p className="text-gray-700 mb-1 fs-14 fw-bold">SUGGESTED MATCHES</p>
											<hr className="m-0" />
											{searchResultsMap
												.map((result) => {
													return (
														<MatchCard
															key={`match-${result.id}-for-${result.organizationID}`}
															matchDetails={result}
															requestId={requestId}
															requestDetails={requestPanel} />
													);
												})}
										</div>
									}
								</React.Fragment>
							)}
						</div>
					</div>
				</div>
			</div>
		</Page>
	);
};

export default SuggestedMatches;

