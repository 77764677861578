import React from 'react';
import { FormGroup, Page, Select } from 'components/core';
import OnBoardingProgressBar from './components/ProgressBar';
import { Controller, useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { PickListSelect } from 'components/scopematch/PickListSelect';
import { OnboardingErrorMsg } from './utils';
import OnboardingFooter from './components/OnboardingFooter';
import OnboardingContainer from './components/OnboardingContainer';
import { FormContext } from './FormContext';
import BackForwardButtons from './components/BackForwardButtons';

type LocationsType = { countryDropdownShipping?: string } & OrganizationRequest;

const ShippingLocations = () => {

	const { control, watch, setValue, getValues } = useFormContext<LocationsType>();
	const location = useLocation();
	const FormCxt = React.useContext(FormContext);
	const organizationType = getValues('organizationType');
	const countryOptions = [
		{ value: 'all', label: 'All' },
		{ value: 'except', label: 'All countries except for...' },
		{ value: 'specific', label: 'Specific countries' },
	];

	const countryDropdownShipping = watch('countryDropdownShipping');

	return (
		<Page
			title="Export Locations"
			className="onboarding-wrapper"
		>
			<OnboardingContainer>
				<OnBoardingProgressBar indexInfo={FormCxt.CurrentIndex(organizationType, location.pathname)} />
				<h2 className="pt-4 mb-2">Export locations</h2>
				<p className="mb-2">This information will help match your company to requests with specific export requirements</p>
				<p className="text-gray-700 fst-italic mb-4 ">You can skip this step and complete this later in the dashboard</p>
				<Controller
					control={control}
					name="countryDropdownShipping"
					render={({ field, fieldState }) =>
						<FormGroup label="What countries have you exported to in the last 5 years?" field={field} fieldState={fieldState}>
							<Select
								id="country-select"
								name={field.name}
								value={field.value}
								options={countryOptions}
								onChange={field.onChange}
								error={!!fieldState.error?.message}
							/>
						</FormGroup>
					}
				/>
				{countryDropdownShipping !== undefined && countryDropdownShipping !== 'all' && (
					<Controller
						control={control}
						name="shippingLocation"
						rules={{
							required: OnboardingErrorMsg.Countries
						}}
						render={({ field, fieldState }) =>
							<FormGroup label={countryDropdownShipping === 'except' ? ('All countries except for...') : ('Specific countries')} field={field} fieldState={fieldState}>
								<span className="fw-normal fst-italic text-gray-700">
									(select all that apply)</span>
								<PickListSelect
									id="allcountries-select"
									isMulti={true}
									name={field.name}
									value={field.value}
									pickListTypeName="Country"
									onChange={field.onChange}
									error={!!fieldState.error?.message}
								/>
							</FormGroup>
						}
					/>
				)}

			</OnboardingContainer>
			<OnboardingFooter>
				<BackForwardButtons beforeSkipping={beforeSkipping} />
			</OnboardingFooter>

		</Page>
	);

	function beforeSkipping() {
		setValue('countryDropdownShipping', undefined);
		setValue('shippingLocation', undefined);
	};

};

export default ShippingLocations;
