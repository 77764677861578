import React from 'react';
import { Button, LoadingPage, Page } from 'components/core';
import { useNavigate } from 'react-router-dom';
import TitleBar from 'screens/Components/TitleBar';
import { ReactComponent as IconSavedMatches } from 'assets/icons/icon-saved-matches.svg';
import { RequestContext } from './RequestContext';
import { ReactComponent as IconArrowRight } from 'assets/icons/icon-arrow-right.svg';

const Requests = () => {
	const requestCxt = React.useContext(RequestContext);
	const pageTitle = 'Requests';
	const navigate = useNavigate();

	if (requestCxt.isLoading === true) {
		return <LoadingPage />;
	}

	return (
		<Page title={pageTitle}>
			<div className="d-flex">
				<div className="d-flex flex-column w-100">
					<TitleBar
						pageTitle={pageTitle}
					/>
					{requestCxt.requestsList.length === 0 ? (
						<div className="container-fluid px-5 py-3">
							<div className="text-center bg-light py-5 rounded">
								<h5 className="mb-2">You don't have any requests yet</h5>
								<p className="mb-2 pb-3">Create a request to find specific products that you are looking for</p>
								<Button
									onClick={getNewRequest}
									loading={requestCxt.isLoading}
									className="btn-primary ms-auto"
								>
									Create New Request
								</Button>
							</div>
						</div>
					) : (
						<React.Fragment>
							<div className="container-fluid px-5 py-3">
								<div className="d-flex align-items-center bg-light py-3 px-4 mx-n2 rounded">
									<h5 className="mb-0">Your Requests <span className="bg-primary-100 rounded-pill px-3 py-1 ms-3 text-primary">{requestCxt.requestsList?.length}</span></h5>
									<Button
										onClick={getNewRequest}
										loading={requestCxt.isLoading}
										className="btn-primary ms-auto"
									>
										Create New Request
									</Button>
								</div>
							</div>
							<div className="container-fluid mt-2 px-5">
								<div className="row gx-0 mx-n3">
									{requestCxt.requestsList && requestCxt.requestsList
										.sort((a, b) => {
											const dateA = a.requestDate ? Date.parse(a.requestDate) : 0;
											const dateB = b.requestDate ? Date.parse(b.requestDate) : 0;
											return dateB - dateA;
										})
										.map((requests) => (

											<div className="col-6 p-2" key={`product-request-${requests.matchRequestId}`}>
												<div className="card shadow h-100 rounded">
													<div className="card-body px-4 pt-3 mt-2 pb-4">
														<h4 className="mb-1">
															{requests.requestName && requests.requestName !== ''
																? requests.requestName.length > 60
																	? `${requests.requestName.slice(60)}...`
																	: requests.requestName
																: 'Untitled'}
														</h4>

														<p className="mb-2 pb-3">
															{requests.requestDescription && requests.requestDescription !== ''
																? requests.requestDescription.length > 50
																	? `${requests.requestDescription.slice(0, 60)}...`
																	: requests.requestDescription
																: 'Placeholder Description'}
														</p>
														<h5>Product Types:</h5>
														{requests.productTypeNames && (
															<React.Fragment>
																{requests.productTypeNames.map((product, index) =>
																	product !== null ? (
																		<span
																			key={`product-key-for-${requests.matchRequestId}-${index}`}
																			className="bg-primary-100 rounded-pill px-3 py-1 me-2 text-primary fw-bold
																			fs-14 d-inline-block mt-2"
																		>
																			{product}
																		</span>
																	) : (
																		<p key={`product-key-for-${requests.matchRequestId}-${index}`} className="text-gray-700 fst-italic">
																			No product types specified yet
																		</p>
																	)
																)}
															</React.Fragment>
														)}

													</div>

													<div className="card-footer border-top border-gray-200 px-4 py-2 d-flex">
														<div className="d-block me-3">
															<p className="mb-2 d-flex flex-column">
																<span className="text-center"><IconSavedMatches className="me-2" /></span>
																<span>{requests.savedMatches} Saved Matches</span>
															</p>
															<p className="fs-14 text-gray-600 mb-0 text-center">
																{requests.lastSearchDate !== undefined ?
																	'Last Viewed: ' + new Date(requests.lastSearchDate).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })
																	: requests.requestDate && 'Last Viewed: ' +
																	new Date(requests.requestDate).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })
																}
															</p>
														</div>
														<Button className="btn-outline-primary ms-auto fs-14" to={`/requests/searchresults/${requests.matchRequestId}`}>
															View Request
															<IconArrowRight className="ms-2" />
														</Button>
													</div>
												</div>
											</div>
										))}

								</div>
							</div>
						</React.Fragment>
					)}
				</div>
			</div>

		</Page >
	);
	async function getNewRequest() {
		navigate('/requests/new-request');

	}
};

export default Requests;

