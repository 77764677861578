/* eslint-disable react/prop-types */
import React, { useState, useRef } from 'react';
import * as d3 from 'd3';
import Tooltip from './utils/Tooltip';


const ForceGraph = ({ zoomTo, initZoom, nodes, links, chartwidth, chartheight }) => {
	let zoom = null;
	const chartRef = useRef(null);
	const [animatedNodes, setAnimatedNodes] = useState([]);
	const [animatedLinks, setAnimatedLinks] = useState([]);
	const [hoveredBubbleData, setHoveredBubbleData] = useState({});
	const [hoveredBubble, setHoveredBubble] = useState(false);
	const [tipLeft, setTipLeft] = useState(99999);
	const [tipTop, setTipTop] = useState(99999);

	const settings = {
		bubbleTooltipRequired: true,
		lineTooltipRequired: true,
		zoomRequired: true,
		bubbleAnimationRequired: false,
		lineAnimationRequired: false,
		bubbleAnimationDuration: 20,
		lineAnimationDuration: 20,
		zoomButtonsRequired: true,
	};

	React.useEffect(() => {
		setAnimatedLinks(links);
	}, [links]);


	React.useEffect(() => {
		const simulations = d3
			.forceSimulation()
			.force('x', d3.forceX(400))
			.force('y', d3.forceY(300));

		simulations.on('tick', () => {
			setAnimatedNodes([...simulations.nodes()]);
		});

		simulations.nodes([...nodes]);
		simulations.alpha(0.1).restart();

		const adjustDisplay = () => {
			let boundss = d3.select(chartRef.current).node().getBBox();
			let bounds = new Array(2);
			bounds[0] = new Array(2);
			bounds[0][0] = boundss.x;
			bounds[0][1] = boundss.y;
			bounds[1] = new Array(2);
			bounds[1][0] = boundss.x + boundss.width;
			bounds[1][1] = boundss.y + boundss.height;
			let s = 0.95 / Math.max((bounds[1][0] - bounds[0][0]) / chartwidth, (bounds[1][1] - bounds[0][1]) / chartheight);
			let redfactor = 1.35;
			let __restrictmaximumscaleto = 5;
			s = s / redfactor;
			if (s > __restrictmaximumscaleto) {
				s = __restrictmaximumscaleto;
			}
			let t = [(chartwidth - s * (bounds[1][0] + bounds[0][0])) / 2, (chartheight - s * (bounds[1][1] + bounds[0][1])) / 2];

			// var transform = d3.zoomIdentity
			// 	.translate(t[0], t[1])
			// 	.scale(s);

			d3.select(chartRef.current).transition().duration(1000).attr('transform', 'translate(' + t[0] + ',' + t[1] + ')' + ' scale(' + s + ')').on('end', () => {
				if (settings.zoomRequired) {
					// eslint-disable-next-line react-hooks/exhaustive-deps
					zoom = d3.zoom()
						.scaleExtent([.5, 16])
						.on('zoom', Zoomed);
					d3.select('#chartholder').call(zoom);
				}
			});
		};


		setTimeout(() => {
			d3.select(chartRef.current).selectAll('[id^=linkpath_]')['_groups'][0].forEach((link) => {
				let linelength = link.getTotalLength();
				d3.select(link).attr('stroke-dashoffset', `${linelength}`);
				d3.select(link).attr('stroke-dasharray', `${linelength} ${linelength}`);
			});
			let incr = 20;
			let nodecounter = 0;
			d3.select(chartRef.current).selectAll('[id^=node_]')['_groups'][0].forEach((node) => {
				let id = d3.select(node).attr('id').split('node_')[1];
				let targetx = 0;
				let targety = 0;
				nodes.forEach((everynode) => {
					if (everynode.id === id) {
						targetx = everynode.fx;
						targety = everynode.fy;
					}
				});
				if (settings.bubbleAnimationRequired) {
					d3.select(node).transition()
						.delay(nodecounter)
						.duration(2000)
						.ease(d3.easeLinear)
						.attr('transform', ((_d) => {
							return `translate(${targetx},${targety})`;
						}))
						.on('end', () => {
							if (settings.lineAnimationRequired) {
								setTimeout(() => {
									let linkcounter = 0;
									d3.select(chartRef.current).selectAll('[id^=linkpath_]')['_groups'][0].forEach((link) => {
										let totalLength = link.getTotalLength();
										d3.select(link)
											.attr('stroke-dasharray', totalLength + ' ' + totalLength)
											.attr('stroke-dashoffset', totalLength)
											.transition()
											.delay(linkcounter)
											.duration(1000)
											.ease(d3.easeLinear)
											.attr('stroke-dashoffset', 0)
											.on('end', () => {
												adjustDisplay();
											});
										linkcounter += 10 + (i * (incr / 2));
									});
								}, 200);
							} else {
								d3.select(chartRef.current).selectAll('[id^=linkpath_]')['_groups'][0].forEach((link) => {
									d3.select(link)
										.attr('stroke-dasharray', undefined)
										.attr('stroke-dashpffset', undefined);
								});
								adjustDisplay();
							}
						});
					nodecounter += 10 + (i * incr);
				} else {
					d3.select(node)
						.attr('transform', ((_d) => {
							return `translate(${targetx},${targety})`;
						}));
					if (settings.lineAnimationRequired) {
						setTimeout(() => {
							let linkcounter = 0;
							d3.select(chartRef.current).selectAll('[id^=linkpath_]')['_groups'][0].forEach((link) => {
								let totalLength = link.getTotalLength();
								d3.select(link)
									.attr('stroke-dasharray', totalLength + ' ' + totalLength)
									.attr('stroke-dashoffset', totalLength)
									.transition()
									.delay(linkcounter)
									.duration(1000)
									.ease(d3.easeLinear)
									.attr('stroke-dashoffset', 0)
									.on('end', () => {
										adjustDisplay();
									});
								linkcounter += 10 + (i * (incr / 2));
							});
						}, 200);
					} else {
						d3.select(chartRef.current).selectAll('[id^=linkpath_]')['_groups'][0].forEach((link) => {
							d3.select(link)
								.attr('stroke-dasharray', undefined)
								.attr('stroke-dashpffset', undefined);
						});
						adjustDisplay();
					}
				}
			});
		}, 100);

		// stop simulation on unmount
		return () => simulations.stop();

	}, [nodes, settings.bubbleAnimationRequired, settings.lineAnimationRequired, settings.zoomRequired, chartheight, chartwidth]);


	const Zoomed = (e) => {
		d3.select(chartRef.current).transition().duration(200).attr('transform', 'translate(' + e.transform.x + ',' + e.transform.y + ')' + ' scale(' + e.transform.k + ')');
	};


	const getPath = (link) => {
		let source = link.source;
		let target = link.target;
		let sourceX1 = 0;
		let sourceY1 = 0;
		let targetY1 = 0;
		let targetX1 = 0;
		animatedNodes.forEach((node) => {
			if (node.id === source) {
				sourceX1 = node.fx;
				sourceY1 = node.fy;
			}
			if (node.id === target) {
				targetX1 = node.fx;
				targetY1 = node.fy;
			}
		});
		return `M${sourceX1} ${sourceY1}, L${targetX1} ${targetY1}`;
	};

	const showToolTip = (e, id, tid, type) => {

		e.target.style.cursor = 'pointer';
		if (type === 1) {
			nodes.forEach((node) => {
				if (node.id === id) {
					Object.keys(node.sub).forEach((nkey) => {
						node.sub[nkey].forEach((tnode) => {
							if (tnode.id === node.id) {
								setHoveredBubbleData({ 'Name': tnode.value });
								setHoveredBubble(true);
								d3.select(`#node_${node.id}`).select('circle')
									.transition()
									.delay(10)
									.duration(settings.bubbleAnimationDuration)
									.ease(d3.easeLinear)
									.attr('r', node.radius * 2)
									.on('end', () => {
									});
							}
						});
					});
					setTipLeft(node.fx);
					setTipTop(node.fy);
				}
			});
		}
		if (type === 2) {
			let hdata = {};
			nodes.forEach((node) => {
				if (node.id === id) {
					Object.keys(node.sub).forEach((nkey) => {
						node.sub[nkey].forEach((tnode) => {
							if (tnode.id === node.id) {
								hdata.From = tnode.value;
							}
						});
					});
					setTipLeft(node.fx + (node.radius / 2) + 10);
					setTipTop(node.fy + (node.radius / 2) + 20);
				}
				if (node.id === tid) {
					Object.keys(node.sub).forEach((nkey) => {
						node.sub[nkey].forEach((tnode) => {
							if (tnode.id === node.id) {
								hdata.To = tnode.value;
							}
						});
					});
					setTipLeft(node.fx + (node.radius / 2) + 10);
					setTipTop(node.fy + (node.radius / 2) + 20);
				}
			});
			d3.select(`#link_${id}_${tid}`).select('path')
				.transition()
				.delay(10)
				.duration(settings.lineAnimationDuration)
				.ease(d3.easeLinear)
				.attr('stroke-width', '4')
				.on('end', () => {
				});
			setHoveredBubble(true);
			setHoveredBubbleData(hdata);
		}
	};


	const hideToolTip = (e, id, tid, type) => {
		e.target.style.cursor = 'defaukt';
		if (type === 1) {
			nodes.forEach((node) => {
				if (node.id === id) {
					Object.keys(node.sub).forEach((nkey) => {
						node.sub[nkey].forEach((tnode) => {
							if (tnode.id === node.id) {
								d3.select(`#node_${node.id}`).select('circle')
									.transition()
									.delay(10)
									.duration(500)
									.ease(d3.easeLinear)
									.attr('r', node.radius)
									.on('end', () => {
										setHoveredBubble(false);
									});
							}
						});
					});
				}
			});
		}
		if (type === 2) {
			d3.select(`#link_${id}_${tid}`).select('path')
				.transition()
				.delay(10)
				.duration(1000)
				.ease(d3.easeLinear)
				.attr('stroke-width', '1')
				.on('end', () => {
					setHoveredBubble(false);
				});
		}
	};

	React.useEffect(() => {
		if (zoom) {
			if (zoomTo === 1) {
				zoom.scaleBy(d3.select(chartRef.current), 1.3);
			}
			if (zoomTo === 2) {
				d3.select('#chartholder').transition()
					.duration(750)
					.call(zoom.transform, d3.zoomIdentity);
			}
			if (zoomTo === 3) {
				zoom.scaleBy(d3.select(chartRef.current), 1 / 1.3);
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [zoomTo, initZoom]);

	return (
		<div className="toolTipParent">
			<svg id="chartholder" className="chartholder">
				<g ref={chartRef}>
					{animatedLinks &&
						animatedLinks.map((link) => (
							<g key={`${link.source}_${link.target}`} id={`link_${link.source}_${link.target}`} style={{ cursor: 'pointer' }}>
								<path
									d={`${getPath(link)}`}
									stroke="black"
									key={`${link.source}_${link.target}`}
									fill="none"
									strokeOpacity=".2"
									strokeWidth="1"
									id={`linkpath_${link.source}_${link.target}`}
									strokeDasharray={`${link.distance} ${link.distance}`}
									strokeDashoffset={`${link.distance}`}
									onMouseOver={(e) => settings.lineTooltipRequired && showToolTip(e, link.source, link.target, 2)}
									onMouseOut={(e) => settings.lineTooltipRequired && hideToolTip(e, link.source, link.target, 2)}
								/>
							</g>
						))}
					{animatedNodes &&
						animatedNodes.map((node) => (
							<g key={node.id} id={`node_${node.id}`} transform={`translate(${node.ix},${node.iy})`}
								style={{ cursor: 'pointer' }}
								onMouseOver={(e) => {
									settings.bubbleTooltipRequired && showToolTip(e, node.id, '', 1);
								}}
								onMouseOut={(e) => {
									settings.bubbleTooltipRequired && hideToolTip(e, node.id, '', 1);
								}}
							>
								<circle
									cx={'0'}
									cy={'0'}
									r={node.radius}
									key={node.id}
									stroke="black"
									fill="blue"

								/>
							</g>
						))}
				</g>
			</svg>
			{hoveredBubble ? (
				<Tooltip hoveredBubbleData={hoveredBubbleData} tipLeft={tipLeft} tipTop={tipTop} />
			) : null}
		</div>
	);
};

export default ForceGraph;
